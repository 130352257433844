import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { secretKey } from "../utils/reuse";

// Function to encrypt an object
const encryptObject = (obj) => {
    const jsonString = JSON.stringify(obj); // Convert object to JSON string
    return CryptoJS.AES.encrypt(jsonString, `${secretKey}.authitdRE`).toString(); // Encrypt the string
}
// Function to decrypt an object
const decryptObject = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, `${secretKey}.authitdRE`); // Decrypt the string
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8); // Convert bytes to a readable string
    return decryptedString ? JSON.parse(decryptedString): {}; // Parse string back to an object
}
  
const appInfo = secureLocalStorage.getItem('appInfo');
const loginData = secureLocalStorage.getItem('loginData');
const sessionCookie = Cookies.get('session');
const authCookie = Cookies.get('remember_token');

// remove old names
secureLocalStorage.removeItem('sessionData');
secureLocalStorage.removeItem('profileData');
secureLocalStorage.removeItem("userData")
secureLocalStorage.removeItem("newsNavigation")
secureLocalStorage.removeItem('userImage');
secureLocalStorage.removeItem('attachments');
secureLocalStorage.removeItem('deletedAttachments');
secureLocalStorage.removeItem('sportName');
secureLocalStorage.removeItem('reportData');
secureLocalStorage.removeItem('newsData');
secureLocalStorage.removeItem('coverImage');


let sessionData = {}
let profileData = {}
let authInfo = {}
if (sessionCookie) {
     sessionData = decryptObject(sessionCookie);
     profileData = {
        name:sessionData.name,
        email:sessionData.email,
        profilePic: appInfo?.profilePic ? appInfo.profilePic : null
    }
}
if (authCookie) {
    authInfo = decryptObject(authCookie);
}
const isLoggedIn = sessionData?.sessionId ? true : false;
const role = sessionData?.role;
const companyName = sessionData?.companyName;
const alertCount = appInfo?.alertCount;
const conversationCount = appInfo?.conversationCount;
const newsLatest = appInfo?.newsLatest ? appInfo?.newsLatest:  null;
const exportPermission = appInfo?.groupPermission?.includes("export") || false;
const sports = appInfo?.sports ? appInfo?.sports : [];
const tournamentLevels = appInfo?.tournamentLevels ? appInfo?.tournamentLevels : [];

const initialAuthState = {
    isLoggedIn,
    isLogout: false,
    loginData,
    sessionData,
    authInfo,
    profileData,
    role,
    companyName,
    alertCount,
    conversationCount,
    newsLatest,
    alerts:[],
    alertRaisedAnalytics: {},
    exportPermission,
    sports,
    tournamentLevels,
    appInfo,
    userImage: null
}
const setSessionCookie = (key, token, expires = null) => {
    const cookieConfig = {
        secure: true, // Ensures the cookie is only sent over HTTPS
        sameSite: 'Strict', // Protects against CSRF attacks
    }
    if(expires){
        cookieConfig.expires = expires;
    }
    Cookies.set(key, token, cookieConfig);
};


const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        onLogin(state, action) {
            const loginData = {
              userID: action.payload.userID,
              passKey: action.payload.passKey
            }
            secureLocalStorage.setItem('loginData', loginData);
            state.loginData = loginData;
        },
        onRemember(state, action) {
            if (action.payload.remember) {
                state.authInfo = action.payload
                const encryptKey = encryptObject(action.payload)
                setSessionCookie('remember_token', encryptKey, 15);
            } else {
                state.authInfo = {}
                Cookies.remove('remember_token');
            }
        },
        onOTPverify(state, action) {
            const session = {
                email: action.payload.email,
                sessionId: action.payload.sessionId,
                role: action.payload.role,
                companyName: action.payload.groupName,
                groupID: action.payload.groupID,
                userID: action.payload.userID,
                name: action.payload.name,
            }
            const profileData = {
                name:action.payload.name,
                email:action.payload.email,
                profilePic: action.payload?.userImage ? JSON.parse(action.payload?.userImage) : null
            }
            const sessionEncryptKey = encryptObject(session);
            setSessionCookie('session', sessionEncryptKey)
            state.isLoggedIn = true;
            state.sessionData = session
            state.profileData = profileData
            state.name = action.payload.name;
            state.role = action.payload.role;
            state.companyName = action.payload.groupName;
            state.alertCount = action.payload.alertCount;
            state.conversationCount = action.payload.conversationCount;
            state.newsLatest = action.payload?.news ? action.payload?.news[0] : null;
            const exportPermission = action.payload.groupPermission?.includes("export") || false;
            state.exportPermission = exportPermission;
            state.sports = action.payload.sports;
            state.tournamentLevels = action.payload.tournamentLevels;
            state.loginData = {};
            secureLocalStorage.removeItem('loginData');

            const appInfo = {
                alertCount: action.payload.alertCount,
                conversationCount: action.payload.conversationCount,
                newsLatest: action.payload?.news ? action.payload?.news[0] : null,
                groupPermission: action.payload.groupPermission,
                sports: action.payload.sports,
                tournamentLevels: action.payload.tournamentLevels,
                profilePic: action.payload?.userImage ? JSON.parse(action.payload?.userImage) : null
           }
            state.appInfo = appInfo;
            secureLocalStorage.setItem('appInfo', appInfo);
        },
        setProfileData(state, action){
            state.profileData = action.payload
            if(state.sessionData){
                state.sessionData.name = state.profileData.name;
                setSessionCookie('session', encryptObject(state.sessionData))
            }
            if(state.appInfo){
                state.appInfo.profilePic = state.profileData.profilePic;
                secureLocalStorage.setItem('appInfo', state.appInfo);
            }
        },
        updateAlertCount(state, action) {
            state.alertCount = action.payload.alertCount;
            if (state.appInfo) {
                state.appInfo.alertCount = action.payload.alertCount;
                secureLocalStorage.setItem('appInfo', state.appInfo);
            }
        },
        updateConversationCount(state, action) {
            state.conversationCount = action.payload.conversationCount;
            if (state.appInfo) {
                state.appInfo.conversationCount = action.payload.conversationCount;
                secureLocalStorage.setItem('appInfo', state.appInfo);
            }
        },
        updateAlertsList(state, action) {
            state.alerts = action.payload
        },
        updateAlertRaisedAnalytics(state, action) {
            state.alertRaisedAnalytics = action.payload;
        },
        updateResponseRateAnalytics(state, action) {
            state.responseRateAnalytics = action.payload;
        },
        updateTotalAlertsAnalytics(state, action) {
            state.totalAlertsAnalytics = action.payload;
        },
        updateTagsCountAnalytics(state, action) {
            state.tagsCountAnalytics = action.payload;
        },
        updateAlertByLocationAnalytics(state, action) {
            state.alertByLocationAnalytics = action.payload;
        },
        updateResponseTypeAnalytics(state, action) {
            state.responseTypeAnalytics = action.payload;
        },
        updateAnalystAlertRaisedAnalytics(state, action) {
            state.analystAlertRaisedAnalytics = action.payload;
        },
        updateAnalystResponseRateAnalytics(state, action) {
            state.analystResponseRateAnalytics = action.payload;
        },
        storeUserImage(state, action){
            state.userImage = action.payload;
        },
        onLogout(state) {
            state.isLoggedIn = false;
            state.isLogout = true;
            state.loginData = {};
            state.appInfo = {};
            state.sessionData = {};
            state.profileData = {};
            state.role = {};
            // new ones
            secureLocalStorage.removeItem('loginData');
            secureLocalStorage.removeItem('appInfo');
            Cookies.remove('session');
        },
    }
})

export const authActions = authSlice.actions;
export default authSlice.reducer;