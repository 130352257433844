import { useEffect, useRef, useState } from "react";
import * as PR from "../../../prime-react/index";
import ImageDisplay from "../../../shared/ImageDisplay";
import { dompurifyText, pagecount, queryLimit } from "../../../utils/reuse";
import { roleIds } from "../../../shared/common";
import { noGroupImage } from "../../../assets/images";

export const Header = ({query,refetch, setFormPopUp}) => {
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [searchQuery,setSearchQuery] = useState('')
    const [error, setError] = useState(null)
    const trigger = useRef(null);

    const handleInputChange = (key, value) => {
        let shouldTriggerRefetch = false; // Flag to check if query changes
    
        if (key === 'role') {
            setSelectedRoles(value); // Update selectedRoles state
            const newRoleQuery = value?.map(item => item.id)?.join(',') ?? '';
            if (query[key] !== newRoleQuery) {
                query[key] = newRoleQuery; // Set role filter
                shouldTriggerRefetch = true; // Trigger refetch as query changed
            }
        } else {
            // Check if the value has fewer than 4 characters
            if (value.length < 3) {
                setSearchQuery(value);
                if(query[key] !== ''){
                    query[key] = '';
                    shouldTriggerRefetch = true;
                }
                setError('Please enter atleast 3 characters');
                setTimeout(() => {
                    setError(null);
                }, 2500);
            } else if (value.length <= queryLimit) {
                setError(null)
                setSearchQuery(value);
                if (query[key] !== value) {
                    query[key] = value; // Set other query parameters like 'search'
                    shouldTriggerRefetch = true; // Trigger refetch as query changed
                }
            } else {
                setError(null)
                setError(`Keyword can be at most ${queryLimit} characters long`);
                setTimeout(() => {
                    setError(null);
                }, 2500);
            }
        }

        // Only trigger the refetch if the query actually changed
        if (shouldTriggerRefetch) {
            if (trigger.current) clearTimeout(trigger.current);
    
            trigger.current = setTimeout(() => {
                query.offset = 0;
                refetch(); // Trigger the API call after timeout
            }, 1000);
        }
    };
    

    return <>
        <div className="filter-section user-management-filter flex align-items-start gap-2 p-2">
            <div className="p-input-icon-right">
                <span className="p-input-icon-left flex align-items-center search-box">
                    <i className="pi pi-search" />
                    <PR.InputText
                        id="groupNameDescriptionSearch"
                        value={searchQuery}
                        placeholder="Search by Group name / Description" 
                        className="search-field" 
                        onChange={(e) => handleInputChange('search', e.target.value)} 
                    />
                </span>
                <span className="right-clear-icon">
                    {!!searchQuery && <i style={{ cursor: 'pointer', color: "#6c757d" }} onClick={() => handleInputChange('search', '')} className="pi pi-times text-sm mr-2" />}
                </span>
                {error && <p className="error-message mt-1">{error}</p>}
            </div>
            <PR.MultiSelect 
                inputId="roleSearch"
                filter 
                value={selectedRoles} 
                onChange={(e) => handleInputChange('role', e.target.value)}
                options={roleIds} 
                optionLabel="name" 
                placeholder="Search by role" 
                maxSelectedLabels={0} 
                showSelectAll
                className="multi-select-dropdown" 
                multiple
                resetFilterOnHide
                showClear
                pt={{
                    checkboxContainer: {
                        onClick: (e) => {
                            e.stopPropagation();
                            const parent = e.target?.parentNode;
                            if (parent && typeof parent.click === 'function') {
                                parent.click();
                            }
                        },
                    },
                }}                
            />
            <PR.Button 
                label="Create Group"  
                icon="pi pi-plus" 
                className="create-button" 
                onClick={setFormPopUp} 
            />
    </div>

    </>
};
export const PaginationTemplate = ({refetch, query, data}) => {

    const [page, setPage] = useState();

    const clickHandler = async v =>{
        query.offset = (((page+v)-1)*pagecount);
        await refetch();
    }
    useEffect(()=>{
        setPage((Math.round(query.offset/pagecount)+1))
    },[query.offset, setPage])
    

    return <div className="p-paginator p-component p-paginator-bottom">
        <span className="current-page">Page {page}</span>
        <PR.Button className="pagination-buttons" disabled={!(page-1)} onClick={clickHandler?.bind(null,-1)} > Previous </PR.Button>
        <PR.Button className="pagination-buttons" disabled={data?.length<=(pagecount)} onClick={clickHandler?.bind(null,1)} > Next </PR.Button>
    </div>
};

export const groupName = ({image,role,name}) =>  <div className="flex align-items-center gap-2 group-name-block">
    {!!image ? <ImageDisplay profileImage={image} type="group" />:<img src={noGroupImage} alt="groupIcon" width="36"/>}
    <div className="event-column">
        <p>{name}</p>
        <span className="role">{role}</span>
    </div>
</div>


export const status = ({status}) => <div className="flex gap-2 align-items-center">
    <div className={`status ${status}`}> {status ? status?.charAt(0)?.toUpperCase() + status?.slice(1)?.toLowerCase() : ''} </div>
</div>


export const userCount = ({usersCount}) => <div className="flex gap-2 align-items-center">
    <div> {usersCount ?? 0} </div>
</div>
export const userDescription = ({description}) => <div dangerouslySetInnerHTML={{__html: dompurifyText(description)}}>
</div>


export const action = (viewDetailsHandler,editHandler,rowData) => {
    return (
        <div className="flex gap-2 align-items-center">
            <PR.Button label="View Users" severity="secondary" onClick={viewDetailsHandler.bind(null,rowData)} outlined className="view-details-button" />
            <PR.Button icon="pi pi-pencil" outlined onClick={editHandler.bind(null,rowData)} className="view-details-button only-icon"/>
        </div>
    );
};