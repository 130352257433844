import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as PR from "../../../prime-react/index";
import "./Alerts.scss";
import Layout from "../../../layouts/Layout";
import MenuBar from '../../../layouts/MenuBar';
import { exportAlerts, getAlertsCount, setSearchOptions } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { RAW_FILTER_DATA } from '../../../shared/common';
import { authActions } from "../../../store/auth";
import { formatDate, formatDateRange, pagecount } from '../../../utils/reuse';
import AlertsGrid from '../../../shared/Alerts/AlertsGrid';
import { setFilter } from '../../../store/filter.slice';
import { useToast } from '../../../context/ToastContext';
import { handleExportCSV } from '../../../utils/useExportCSV';
import { useCSVExport } from '../../../utils/exportAlerts';
const Alerts = ({activeIndex, setActiveIndex}) => {
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const alertCount = useSelector(state => state.auth.alertCount);
    const sessionData = useSelector(state => state.auth.sessionData);
    const {pathname} = useLocation();
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const pageCount = pagecount
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount);
    const sortOrder = useRef('');
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);

    const [loading, setLoading] = useState(true);
    const [type, setType] = useState('')
    const [processedOptions, setProcessedOptions] = useState({});
    const totalCount = useRef(0);
    const navigate = useNavigate();

    const gotoCreateAlert=()=>{
        navigate("/create-alert");
    }

    const [filteredAlerts, setFilteredAlerts] = useState([])

    const searchFilterHandler = useCallback((type, data) => {
        setLoading(true);
        const obj = {
            offset: offset,
            limit: limit,
            type: type,
            sort: sortOrder.current??''
        };
        setSearchOptions(obj, data, headers, dispatch, response => {
            if(response.result === 'SUCCESS'){
                const responseData = response.data;
                totalCount.current = responseData?.totalCount
                const alertsList = (responseData?.alerts?.length > 0) ? responseData?.alerts.slice(0, limit) : [];
                const result = alertsList;
                
                if (result.length > 0) {
                    setLast(responseData?.alerts?.length <= pageCount);
                    setPaging(true)
                    setFilteredAlerts(result);
                }else if (Object.values(data).some(value => value !== undefined)){ 
                    setFilteredAlerts([]);
                    setLast(true);
                    setPaging(false);
                    showToast("warn", "No records found for selected filters");
                }else {
                    setLast(true);
                    setPaging(false);
                    setFilteredAlerts([]);
                    showToast("warn", "No records found");
                }
                // Set the count on Required actions tab
                if(type === 'notresponded'){
                    const newAlertCount = responseData?.alertsToRespond;
                    dispatch(authActions.updateAlertCount({ alertCount: newAlertCount }));
                }
            }else {
                setLast(true);
                setPaging(false);
                setFilteredAlerts([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, headers, offset, limit, pageCount])
    const handlePageChange = o =>{
        setPaging(false);
        setPage(o.page+1);
        setOffset(o.first);
        setLimit(o.rows);
    }
    const processedAlerts = filteredAlerts?.map(row => ({
        ...row,
        createdOnFormatted: formatDate(row.createdAt),
        createdOnRaw: new Date(row.createdAt)
    }));

    useEffect(() => {
        dispatch(authActions.updateAlertsList(processedAlerts))
    // eslint-disable-next-line
    },[processedAlerts])
    const hasMounted = useRef(false);

    useEffect(() =>{
        if (hasMounted.current) {
            searchFilterHandler(type, processedOptions);
        } else {
            hasMounted.current = true;
        }    // eslint-disable-next-line
    },[offset,limit, type, processedOptions])

    const sortOrderHanlder =  () =>{
        setPage(1);
        setOffset(0)
        sortOrder.current = sortOrder.current==='asc'?'':'asc';
        searchFilterHandler(type,processedOptions)
    }
    const actionTab = (options) => {
        return (
            <div className={`flex align-items-center ${alertCount > 0 ? 'response-action-tab' : 'no-required-action'}`} onClick={options.onClick}>
                <span className="tab-name">Required Action</span>
                {alertCount > 0 && (<PR.Badge value={alertCount} />)}
            </div>
        )
    };

    const formatMaxBets = (data) => {
        return RAW_FILTER_DATA.confirmOptions.filter(item => item.name ===data).map(item => item.id)[0]
    }

    const alertFilterState = useSelector(state => state.alertFilter);
    const [selectedFilters, setSelectedFilters] = useState(pathname === '/alerts' ? alertFilterState : {});
    

    const getSelectedFilters = (data) => {
        setSelectedFilters(data)
        dispatch(setFilter(data))
    }

    const tabChangeHandler = (index, data=selectedFilters) => {
        const alertStatus = (data?.alertStatus?.length !== 0 ) ? data?.alertStatus?.map(item => item.id) : undefined;
        const caseStatus = (data?.caseStatus?.length !== 0 ) ? data?.caseStatus?.map(item => item.id) : undefined;
        const tags = (data?.tags?.length !== 0 ) ? data?.tags?.map(item => item.id): undefined;
        const sports = (data?.sports?.length !== 0 ) ? data?.sports?.map(item => item.id) : undefined;
        const eSportsTitles = (data?.eSportsTitles?.length !== 0 ) ? data?.eSportsTitles?.map(item => item.id) : undefined;
        const tournamentLevels = (data?.tournamentLevels?.length !== 0 ) ? data?.tournamentLevels?.map(item => item.id) : undefined;
        const alertType = (data?.alertType?.length !== 0 ) ? data?.alertType?.map(item => item.id) : undefined;
        const responseType = (data?.responseType?.length !== 0 ) ? data?.responseType?.map(item => item.id) : undefined;
        const groups = (data?.company?.length !== 0 ) ? data?.company?.map(item => item.id) : undefined;

        const startDate = data?.date?.date ? formatDateRange(data?.date?.date[0]): undefined;
        const endDate = data?.date?.date ? formatDateRange(data?.date?.date[1]) : undefined;

        const eventStartDate = data?.eventDate?.date ? formatDateRange(data?.eventDate?.date[0]): undefined;
        const eventEndDate = data?.eventDate?.date ? formatDateRange(data?.eventDate?.date[1]) : undefined;

        const tournaments = (data?.tournament?.length !== 0 ) ? data?.tournament?.map(item => item.id) : undefined;
        const locations = (data?.location?.length !== 0 ) ? data?.location?.map(item => item.id) : undefined;
        const customerLocations = (data?.customerLocations?.length !== 0 ) ? data?.customerLocations?.map(item => item.name) : undefined;
        const gender = (data?.gender?.length !== 0 ) ? data?.gender?.map(item => item.id) : undefined;

        const channel = (data?.channel?.length !== 0 ) ? data?.channel?.map(item => item.id) : undefined;
        const timeOfBet = (data?.timeOfBet?.length !== 0 ) ? data?.timeOfBet?.map(item => item.id) : undefined;
        const stateOfBet = (data?.stateOfBet?.length !== 0 ) ? data?.stateOfBet?.map(item => item.id) : undefined;
        const timeOfAlert = (data?.timeOfAlert?.length !== 0 ) ? data?.timeOfAlert?.map(item => item.id) : undefined;

        const maxBets = (data?.maxBets?.length !== 0 ) ? data?.maxBets?.map(item => item.name) : undefined;
        const maxBetsBoolean = maxBets?.length>0 ? formatMaxBets(maxBets[0]): undefined;
        const keyword = data.keyword;
        const processedOptions = {
            alertStatus,
            caseStatus,
            tags,
            sports,
            eSportsTitles,
            tournamentLevels,
            alertType,
            responseType,
            groups,
            startDate,
            endDate,
            eventStartDate,
            eventEndDate,
            tournaments,
            locations,
            customerLocations,
            gender,
            channel,
            timeOfBet,
            stateOfBet,
            timeOfAlert,
            maxBets:maxBetsBoolean,
            keyword
        }
        setActiveIndex(index)
        setProcessedOptions(processedOptions)
        setOffset(0)
        setPage(1)
        switch (index) {
            case 0:
                setType('')
                return;
            case 1:
                setType('suspicious')
                return;
            case 2:
                setType('notresponded')
                return;
            case 3:
                setType('myalerts')
                return;
            default:
                return;
        }
    }

    const { exportLoading, exportCSV } = useCSVExport(
        processedOptions, 
        sortOrder, 
        headers, 
        dispatch, 
        exportAlerts, 
        handleExportCSV
    );


    const AlertsTemplate = () => {
        return (
            <>
                <AlertsGrid
                    offset={offset}
                    limit={limit}
                    totalCount={totalCount.current}
                    sortValue={sortOrder.current}
                    sortOrder={sortOrderHanlder}
                    tabChangeHandler={tabChangeHandler}
                    activeIndex={activeIndex}
                    getSelectedFilters={getSelectedFilters}
                    selectedFilters={selectedFilters}
                    paging={paging}
                    page={page}
                    last ={last}
                    handlePageChange={handlePageChange}
                    exportCSV={exportCSV}
                    exportLoading={exportLoading}
                    loading={loading}
                />
                {(loading || exportLoading) &&
                    <div className="loading absolute-loading">
                        <i className="pi pi-spin pi-spinner"></i>
                    </div>
                }
            </>
        )
    }

    const getCountHandler = useCallback(() => {
        const obj = {
            type: "rr"
        }; 
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const newAlertCount = responseData?.responseRequired ? responseData?.responseRequired : 0;
                dispatch(authActions.updateAlertCount({ alertCount: newAlertCount }));
            }
        })
    }, [dispatch, headers]);

    useEffect(() => {
        tabChangeHandler(activeIndex);
        getCountHandler();
        // eslint-disable-next-line
    },[])

    const onTabChange = (e) => {
        setSelectedFilters({})
        dispatch(setFilter({}))
        sortOrder.current = ''
        tabChangeHandler(e.index, {});
    }

    return (
        <>
            <Layout>
                <MenuBar/>
                <section className="all-alerts-section">
                    <div className="m-0">
                        <h1>Alerts</h1>
                    </div>
                    <div className="alerts-tab">
                        {!isManagedMember &&
                            <div className="create-alert">
                                <PR.Button label="Create Alert" icon="pi pi-plus" className="create-button" onClick={() => gotoCreateAlert()} />
                            </div>
                        }
                        <PR.TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                            <PR.TabPanel header="All Alerts">
                                <AlertsTemplate/>
                            </PR.TabPanel>
                            <PR.TabPanel header="Suspicious Alerts">
                                <AlertsTemplate/>
                            </PR.TabPanel>
                            {!isManagedMember &&
                            <PR.TabPanel header="Required Action" headerTemplate={actionTab} className={`${alertCount > 0 && 'action-tab'}`}>
                                <AlertsTemplate/>
                            </PR.TabPanel> }
                            <PR.TabPanel header="My Alerts">
                                <AlertsTemplate/>
                            </PR.TabPanel>
                        </PR.TabView>
                    </div>
                </section>
            </Layout>
        </>
    );
    
};

export default Alerts;