import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as PR from "../../prime-react/index";
import "./AlertDetails.scss";
import Layout from "../../layouts/Layout";
import {
  eventIcon,
  editIcon,
  chatIcon,
  msgIcon,
} from "../../assets/images/index";
import AlertForm from "../../shared/CreateAlert/AlertForm";
import {
  addComment,
  createResponse,
  getAlertDetails,
  getAlertConversation,
  getFileDetails,
  updateAlertConversation,
  createAlertConversation,
  updateAlertDescription,
  getAlertResponses,
  updateAlertStatus,
  updateAlertLasttRead,
  updateAlertAdvice,
  createAlert,
  getAssigneeList,
  updateAssignee,
  deleteComment,
  updateComment,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getSportIcon,
  chatCount,
  descriptionCount,
  getStatusLabel,
  capitalizeFirstLetter,
  fileUploadLimit,
  dateTimeFormat,
  maxNumberOfFiles,
  getPlainText,
  textEditorHeader,
  cleanData,
  remainingCharacters,
  chatFileTypesConfig,
  textSanitize,
  dompurifyText,
  decodeHtml
} from "../../utils/reuse";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RAW_FILTER_DATA } from "../common";
import ImageDisplay from "../ImageDisplay";
import EditAlertForm from "../EditAlert/EditAlertForm";
import { ErrorMessage, useToast } from "../../context/ToastContext";
import EventDetails from "./EventDetails";
import { useFormik } from 'formik';
import * as Yup from 'yup';
const alertDetailsError = 'alertDetailsError'

const AlertDetails = () => {
  const { showToast, clearError } = useToast();
  const dispatch = useDispatch();
  const params = useParams();
  const [getAlertId, setAlertId] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [alertDesc, setAlertDesc] = useState("");
  const [alertDescError, setAlertDescError] = useState("");
  const [alertData, setData] = useState({});
  const [alertResponses, setAlertResponses] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [ibiaAdvice, setIbiaAdvice] = useState("");
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [commentError, setCommentError] = useState("");
  const [editAdviceVisible, setEditAdviceVisible] = useState(false);
  const [editedIbiaAdvice, setEditedIbiaAdvice] = useState("");
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [editedFiles, setEditedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ibiaAttachments, setIbiaAttachments] = useState([]);
  const [nonIbiaAttachments, setNonIbiaAttachments] = useState([]);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const isMember = useSelector((state) => state.auth.role) === "member";
  const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
  const isManagedMember =
    useSelector((state) => state.auth.role) === "managedmember";
  const [statusDropdown, setStatusDropdown] = useState(null);
  const [showError, setshowError] = useState("");
  const role = sessionData?.role;
  const headers = useMemo(() => {
    return { sessionid: sessionData.sessionId};
  }, [sessionData.sessionId]);
  const multipartheaders = useMemo(() => {
    return { sessionid: sessionData.sessionId, "Content-Type": "multipart/form-data" };
  }, [sessionData.sessionId]);
  const companyUser = sessionData.companyName;
  const usersGroupId = sessionData.groupID;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterResponseType] = useState([
    "all",
    "offered and suspicious",
    "offered and not suspicious",
    "not offered",
  ]);

  const [currentModal, setCurrentModal] = useState(0);
  const [offer, setOffer] = useState(null);
  const [suspicious, setSuspicious] = useState(null);
  const [openCommentbox, setOpenCommentbox] = useState(false);
  const [comment, setComment] = useState("");
  const [chatData, setChatData] = useState([]);
  const [chatParticipants, setChatParticipants] = useState([]);
  const [chatInfo, setChatInfo] = useState({
    alertID: "",
    alertName: "",
    groupID: "",
    conversationID: "",
    legacyID: ""
  });

  const userName = sessionData.companyName;
  const groupID = isAnalyst ? alertData?.createdByGroupID : sessionData.groupID;
  const [chatLoading, setChatLoading] = useState(false);
  const [isChatPopupOpen, setChatPopupOpen] = useState(false);

  const [chatVisible, setChatVisible] = useState(false);
  const [conversationClicked, setConversationClicked] = useState(false);
  const [isAlertsUpdated, setIsAlertsUpdated] = useState(false);

  const location = useLocation();
  const responseID = location?.state?.responseID;

  const searchParams = new URLSearchParams(location.search);
  const group = searchParams.get('group');
  const [responsesActiveIndex, setResponsesActiveIndex] = useState();

  const [loadingScroll, setLoadingScroll] = useState(true);
  const [alertStatusLoading, setAlertStatusLoading] = useState(false);
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentValue, seteditCommentValue] = useState("");

  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const commentMenuRefs = useRef([]);
  const [deleteCommentVisible, setDeleteCommentVisible] = useState(false);
  const [editCommentError, setEditCommentError] = useState("");
  const [newCommentAdded, setNewCommentAdded] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);
  const [editResponseId, setEditResponseId] = useState(null);
  const [editResponseData, setEditResponseData] = useState({});
  const [notRespondedSearchQuery, setNotRespondedSearchQuery] = useState("");
  const [respondedSearchQuery, setRespondedSearchQuery] = useState("");
  const [memberResponseId, setMemberResponseId] = useState();
 
  const additionalStyle =
    RAW_FILTER_DATA.statusFilter?.find(
      (item) => item.id === alertData.alertStatus
    )?.style || "";

  const clearDialogData = () => {
    setIbiaAdvice("");
    setSelectedFiles([]);
    setStatusDropdown(null);
    setUploadedFiles([]);
    setDeletedAttachments([]);
    setEmailOption("none");
    setNotes("");
  };
  const openEditAdviceDialog = () => {
    setEditedIbiaAdvice(alertData.ibiaAdvice || "");
    const uniqueFiles = ibiaAttachments
      ? [...new Map(ibiaAttachments?.map((file) => [file.key, file])).values()]
      : [];

    setEditedFiles(uniqueFiles);
    setEditAdviceVisible(true);
  };

  const setAdviceAttachments = () => {
    setIbiaAdvice(alertData.ibiaAdvice || "");
    const uniqueFiles = ibiaAttachments
      ? [...new Map(ibiaAttachments?.map((file) => [file.key, file])).values()]
      : [];

    setEditedFiles(uniqueFiles);
  };
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (alertData?.fileAttachment) {
      const ibiaFiles = alertData.fileAttachment.filter(
        (file) => file.owner === "IBIA"
      );
      const nonIbiaFiles = alertData.fileAttachment.filter(
        (file) => file.owner !== "IBIA"
      );
      setIbiaAttachments(ibiaFiles);
      setNonIbiaAttachments(nonIbiaFiles);
    } else {
      setIbiaAttachments([]);
      setNonIbiaAttachments([]);
    }
  }, [alertData]);

  const handleDeleteFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const handleDeleteSelectedFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileUpload = (event) => {
    const totalFiles =
      editedFiles.length + uploadedFiles.length + event.files.length;

    if (totalFiles > maxNumberOfFiles) {
      showToast("warn", `Cannot upload more than ${maxNumberOfFiles} files.`);
      return;
    }

    const newFiles = event.files;
    const duplicateFiles = newFiles.filter((newFile) =>
      [...uploadedFiles, ...editedFiles].some(
        (existingFile) => existingFile.name === newFile.name
      )
    );

    if (duplicateFiles.length > 0) {
      showToast(
        "warn",
        "File names must be unique. Please rename your file(s) before uploading."
      );
      fileInputRef.current.clear();
      return;
    }
    const validFiles = newFiles.filter((file) => {
      if (file.size > fileUploadLimit.value) {
        showToast("warn", `${file.name} is larger than ${fileUploadLimit.label}.`);
        return false;
      }
      return true;
    });

    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    if (fileInputRef.current) {
      fileInputRef.current.clear();
    }
  };

  const handleEditFileUpload = (event) => {
    const totalFiles =
      editedFiles?.length + selectedFiles?.length + event.files?.length;

    if (totalFiles > maxNumberOfFiles) {
      showToast("warn", `Cannot upload more than ${maxNumberOfFiles} files.`);
      return;
    }

    const newFiles = event.files;
    const duplicateFiles = newFiles.filter((newFile) =>
      [...selectedFiles, ...editedFiles].some(
        (existingFile) => existingFile.name === newFile.name
      )
    );

    if (duplicateFiles?.length > 0) {
      showToast(
        "warn",
        "File names must be unique. Please rename your file(s) before uploading."
      );
      fileInputRef.current.clear();
      return;
    }

    const validFiles = newFiles.filter((file) => {
      if (file.size > fileUploadLimit.value) {
        showToast("warn", `${file.name} is larger than ${fileUploadLimit.label}.`);
        return false;
      }
      return true;
    });

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    if (fileInputRef.current) {
      fileInputRef.current.clear();
    }
  };

  const handleDeleteExistingFile = (index) => {
    const updatedAttachments = [...editedFiles];
    const deletedAttachment = updatedAttachments?.splice(index, 1)[0];
    setEditedFiles(updatedAttachments);
    setDeletedAttachments([
      ...deletedAttachments,
      {
        type: "application/pdf",
        key: deletedAttachment.key,
      },
    ]);
  };

  const handleEditAdviceSubmit = () => {
    const id = getAlertId;
    const formData = new FormData();

    const totalFiles = editedFiles.length + selectedFiles.length;
    if (totalFiles > maxNumberOfFiles) {
      showToast("warn", `Cannot upload more than ${maxNumberOfFiles} files.`);
      return;
    }

    const data = {
      alertStatus:
        alertData?.alertStatus === "not reported"
          ? "notreported"
          : alertData?.alertStatus,
      ibiaAdvice: textSanitize(editedIbiaAdvice),
      deletedAttachments,
    };
    formData.append("data", JSON.stringify(data));
    selectedFiles?.forEach((file) => {
      formData.append("attachments", file);
    });

    updateAlertAdvice(id, formData, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        showToast(
          "success",
          "IBIA advice and attachments updated successfully"
        );
        getDetails();
        setEditAdviceVisible(false);
        clearDialogData();
      } else {
        const error = response?.error;
        showToast('warn', error?.errorMsg || error?.summary);
      }
    });
  };

  const handleChangeAlertStatus = () => {
    const id = getAlertId;
    let data = {};
    const formData = new FormData();

    uploadedFiles.forEach((file, index) => {
      formData.append(`attachments`, file);
    });
    setAlertStatusLoading(true);

    if (alertData.alertStatus === "open") {
      data.alertStatus = "evaluating";
    } else if (alertData.alertStatus === "evaluating") {
      if (statusDropdown === "notreported") {
        data = {
          alertStatus: "notreported",
          caseStatus: "nofurtheraction",
          ibiaAdvice : textSanitize(ibiaAdvice),
        };
      } else if (statusDropdown === "suspicious") {
        data = {
          alertStatus: "suspicious",
          caseStatus: "underinvestigation",
          ibiaAdvice : textSanitize(ibiaAdvice),
        };
      } else if (statusDropdown === "open") {
        data = {
          alertStatus: "open",
          ibiaAdvice: textSanitize(ibiaAdvice),
        };
      }
      if (deletedAttachments.length > 0) {
        data.deletedAttachments = deletedAttachments;
      }
    } else if (alertData.alertStatus === "suspicious") {
      if (alertData.caseStatus === "under investigation") {
        if (statusDropdown === "evaluating") {
          data = { alertStatus: "evaluating", notes: textSanitize(notes) };
        } else if (statusDropdown === "closed") {
          data = {
            alertStatus: "suspicious",
            caseStatus: "closed",
            notes: textSanitize(notes),
          };
          if (emailOption !== "none") {
            data.emailNotify = emailOption;
          }
        } else if (statusDropdown === "sanctioned") {
          data = {
            alertStatus: "suspicious",
            caseStatus: "sanctioned",
            notes: textSanitize(notes),
          };
          if (emailOption !== "none") {
            data.emailNotify = emailOption;
          }
        }
      } else if (alertData.caseStatus === 'sanctioned' || alertData.caseStatus === 'closed') {
        data = { 
          alertStatus: "suspicious", 
          caseStatus: "underinvestigation",
          notes: textSanitize(notes) };
      }
    } else if (
      alertData.alertStatus === "not reported" &&
      alertData.caseStatus === "no further action"
    ) {
      data = { alertStatus: "evaluating", notes: textSanitize(notes) };
    }

    formData.append("data", JSON.stringify(data));

    updateAlertStatus(id, formData, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setAlertStatusLoading(false);
        showToast("success", "Alert status has been changed successfully");
        getDetails();
        clearDialogData();
      } else {
        const error = response?.error;
        showToast('warn', error?.errorMsg || error?.summary);
        setAlertStatusLoading(false);
      }
      setAlertStatusVisible(false);
    });
  };

  const emailOptions = [
    { label: "None", value: "none" },
    { label: "All members", value: "allmembers" },
    {
      label: "Alert raiser / Offered & Suspicious respondant",
      value: "alertraiser",
    },
  ];

  const [emailOption, setEmailOption] = useState("none");
  const statusValues = useMemo(() => {
    if (
      alertData.caseStatus === "under investigation"
    ) {
      return [
        { label: "Evaluating", value: "evaluating" },
        { label: "Sanctioned", value: "sanctioned" },
        { label: "Closed", value: "closed" },
      ];
    } else if (
      alertData.caseStatus === "sanctioned" ||
      alertData.caseStatus === "closed"
    ) {
      return [
        { label: "Under Investigation", value: "under investigation" }
      ];
    } else {
      return [
        { label: "Open", value: "open" },
        { label: "Suspicious", value: "suspicious" },
        { label: "Not Reported", value: "notreported" },
      ];
    }
  }, [alertData.caseStatus]);

  const handleViewCaseStatus = () => {
    navigate(`/case-updates?showResultOnly=true&id=${alertData?.legacyID}`);
  };

  // Change alert status - Dialog
  const [alertStatusVisible, setAlertStatusVisible] = useState(false);
  const [assigneeVisible, setAssigneeVisible] = useState(false);
  const [assigneeDropdown, setAssigneeDropdown] = useState(null);

  const alertStatusFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          clearDialogData();
          setAlertStatusVisible(false);
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Change Status"
        onClick={handleChangeAlertStatus}
        loading={alertStatusLoading}
        outlined
        autoFocus
        className="logout-btns click-btn"
        disabled={
          !(
            statusDropdown ||
            ((alertData.alertStatus === "open" || alertData.caseStatus === "sanctioned" || alertData.caseStatus === 'closed' || 
              alertData.alertStatus === "not reported") &&
              !statusDropdown)
          ) || errorMessage || alertStatusLoading
        }
      />
    </div>
  );
  const changeAssigneeFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setAssigneeVisible(false);
          setAssigneeDropdown(null);
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Change Assignee"
        onClick={() => {
          setAssigneeVisible(false);
          setAssigneeDropdown(null);
          setAssignee();
        }}
        outlined
        autoFocus
        disabled={!assigneeDropdown}
        className="logout-btns click-btn"
      />
    </div>
  );

  const [assigneeNames, setAssigneeNames] = useState(null);

  const getAssigneeNames = () => {
    getAssigneeList(sessionData.groupID, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const group = response.data[0];
        const sortedUsers =
          group.users?.length > 0
            ? group.users
                .filter((user) => user.userStatus === "active")
                ?.sort((a, b) => a.name.localeCompare(b.name))
            : [];
        setAssigneeNames(sortedUsers);
      } else {
        const error = response?.error;
        showToast('warn', error?.errorMsg || error?.summary);
      }
    });
  };

  const setAssignee = () => {
    const obj = {
      alertID: getAlertId,
      assigneeID: assigneeDropdown?.id,
    };
    updateAssignee(obj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setData((prev) => ({
          ...prev,
          assignee: assigneeDropdown.name,
        }));
        showToast("success", "Assignee updated successfully");
        getDetails();
      } else {
        const error = response?.error;
        showToast('warn', error?.errorMsg || error?.summary);
      }
    });
  };

  useEffect(() => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [newCommentAdded]);

  // function to call add comment API
  const createComment = () => {
    const alertId = getAlertId;
    if (editCommentId) {
      // Update existing comment
      handleCommentAction('update', alertId, editCommentId, editCommentValue);
      setEditCommentId(null);
    } else if (value.trim() !== "") {
      // Add new comment
      handleCommentAction('add', alertId, null, value);
    }
  };

  const handleCommentAction = (action, alertId, commentId, desc) => {
    const content = textSanitize(desc);
    const payload = action === 'update'
      ? { commentID: commentId, content }
      : { content };

    const apiFunction = action === 'update' ? updateComment : addComment;

    apiFunction(alertId, payload, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const successMessage = action === 'update'
          ? "Comment updated successfully"
          : "Comment created successfully";

        showToast("success", successMessage);

        if (action === 'add') {
          setNewCommentAdded(true);
          setValue("");
          setCommentError("");
          setData((prevData) => ({
            ...prevData,
            comments: [...(prevData.comments || []), response.data],
          }));
        } else {
          seteditCommentValue("");
          setEditCommentError("");
        }
        getDetails();
      } else {
        const error = response?.error;
        showToast("warn", error?.errorMsg || error?.summary);
      }
    });
  };
  
  //edit ibia advice
  const editAdviceFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setEditAdviceVisible(false);
          clearDialogData();
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Save Changes"
        onClick={handleEditAdviceSubmit}
        disabled={errorMessage}
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );

  // function to edit alert description API
  const editAlertDesc = () => {
    const id = getAlertId;
    const alertDescFinal = textSanitize(alertDesc)?.trim().replace(/[ \t]{2,}/g, " ");
    
    if (alertDesc.trim() !== "") {
      updateAlertDescription(
        id,
        alertDescFinal,
        headers,
        dispatch,
        (response) => {
          if (response.result === "SUCCESS") {
            setData((prevData) => ({
              ...prevData,
              description: alertDescFinal,
            }));
            setAlertDesc(decodeHtml(alertDescFinal));
            showToast("success", "Alert description updated successfully");
            getDetails();
          } else {
            const error = response?.error;
            showToast('warn', error?.errorMsg || error?.summary);
          }
        }
      );
    }
    setVisible(false);
  };

  const updateChat = (chatID, content) => {
    setChatLoading(true);
    updateAlertConversation(chatID, content, multipartheaders, dispatch, (response) => {
      getChatConversation(chatInfo.alertID, chatInfo.groupID);
      setChatLoading(false);
      formik.resetForm();
    });
  };

  const createChat = (alertID, groupID, content) => {
    setChatLoading(true);
    createAlertConversation( alertID, groupID, content, multipartheaders, dispatch, (response) => {
        getChatConversation(chatInfo.alertID, chatInfo.groupID);
        setChatLoading(false);
        formik.resetForm();
      }
    );
  };

  const getChatConversation = useCallback((id, groupID) => {
    setChatLoading(true);
    getAlertConversation(id, groupID, headers, dispatch, (response) => {
      setChatPopupOpen(true);
      if (response.data?.messages) {
        setChatData(
          response.data.messages.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        );
        setChatInfo((prev) => ({
          ...prev,
          conversationID: response.data.conversationID,
        }));
        setChatParticipants(response.data.participants);

        // to update chatRead only when readAt is null for last sender's message
        const lastSenderMessage = response.data.messages
          .filter((message) => message.senderID !== sessionData.groupID)
          .pop();
        if (lastSenderMessage && !lastSenderMessage.readAt) {
          setIsAlertsUpdated(true);
        }
      } else {
        setChatData([]);
      }
      setChatLoading(false);
    });
  }, [dispatch, headers, sessionData.groupID]);
  

  const currentConversationID = useMemo(
    () => chatInfo?.conversationID,
    [chatInfo]
  );

  useEffect(() => {
    if (currentConversationID && isAlertsUpdated) {
      const payload = {
        isRead: true,
        includeActiveConversation:  conversationClicked ? true : false,
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      updateChatRead(currentConversationID, formData);
    }
    // eslint-disable-next-line
  }, [isAlertsUpdated, currentConversationID]);

  const updateChatRead = (chatID, isRead) => {
    if (chatID) {
      updateAlertLasttRead(chatID, isRead, multipartheaders, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          setIsAlertsUpdated(false);
          setChatLoading(false);
          if (conversationClicked) {
            // this is to update the new message dot when clicked on conversation chat
            setData((prevData) => ({
              ...prevData,
              activeConversation: responseData?.activeConversation,
            }));
          } else {
            // this is to update the new message dot when clicked on responses chat
            const newData = filteredData.map((item) =>
              item?.respondingGroupID === chatInfo?.groupID ? { ...item, activeConversation: responseData?.activeConversation, } : item
            );
            setFilteredData(newData);

            // Check if all items in filteredData have activeConversation as false
            const allInactive = newData.every(
              (item) => item.activeConversation === false
            );
            if (allInactive) {
              setData((prevData) => ({
                ...prevData,
                activeConversation: false,
              }));
            }
          }
          getDetails();
        }
        setConversationClicked(false);
      });
    }
  };

  const getChatUserName = (senderId) => {
    const userName = chatParticipants
      .filter((user) => user.ID === senderId)
      .map((user) => user?.name);
    return userName[0];
  };

  const getChatUserImage = (senderId) => {
    const userImage = chatParticipants
      .filter((user) => user.ID === senderId)
      ?.map((user) => user.image);
    return userImage[0];
  };
  const messagesEndRef = useRef(null);
  const commentsEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (chatVisible) {
      setTimeout(scrollToBottom, 10);
    }
  }, [chatVisible, chatData]);

  const handleSubmit = (formValues) => {
    let obj = {};
    obj.content = textSanitize(formValues.content);
    const refinedObj = cleanData(obj);
    const formData = new FormData();
    if (refinedObj && Object.keys(refinedObj).length > 0) {
        formData.append('data', JSON.stringify(refinedObj)); 
    }
    if (formValues.attachments && formValues.attachments.length > 0) {
        formValues.attachments.forEach((file) => {
            formData.append('attachments', file);
        });
    }

    if(chatData?.length === 0) {
        createChat(chatInfo.alertID, chatInfo.groupID, formData)
    } else {
        updateChat(chatInfo.conversationID, formData)
    }
    setErrorMessage('');
};

const initialValues = {
    content: '',
    attachments: [],
};

const validationSchema = () => {
    return Yup.object().shape({
        attachments: Yup.array()
            .nullable()
            .test("fileSize", `Attachment must be less than ${fileUploadLimit.label}`, (value) => {
                return !value || value.every(file => file.size <= fileUploadLimit.value);
            })
            .test("fileType", "Only PDF, DOC, DOCX, XLS, XLSX, CSV, PNG, JPG or JPEG files are allowed", (value) => {
                return !value || value.every(file => chatFileTypesConfig.includes(file.type));
            }),
    });
};       

const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
});
useEffect(() => {
  if (group && alertData?.alertName) {
    setChatVisible(true);
    setConversationClicked(true);
    getChatConversation(alertData?.alertID, group);
    setChatInfo((prev) => ({ ...prev, groupID: group, alertID:  alertData?.alertID, alertName: alertData?.alertName, legacyID: alertData?.legacyID }));
  }
}, [alertData?.alertName, alertData?.legacyID, getChatConversation, group, alertData?.alertID])


const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue('attachments', updatedAttachments);
};

/* ----  START Edit alert and response functionality ----- */
const handleBack = () => {
  if (currentModal === 2) {
    setSuspicious(null);
  } 
  resetForm(currentModal !== 1 ? 'back': '')
  setCurrentModal((prev) => prev - 1);
};
const handleNext = (formResponse = null) => {
  if (openCommentbox) {
    const formData = new FormData();
    let resData = {};
    if (offer === "No") {
      resData = {
        responseType: "not offered",
        comment: comment.trim() !== "" ? textSanitize(comment) : "",
      };
    } else if (offer === "Yes" && suspicious === "No") {
      resData = {
        responseType: "offered and not suspicious",
        comment: comment.trim() !== "" ? textSanitize(comment) : "",
      };
    }
    if(editResponseId){
      resData.responseID = editResponseId;
    }
    formData.append("data", JSON.stringify(resData));
    createResponseHandler(formData);
    return;
  }
  if (currentModal === 1  && offer === "No") {
    setOpenCommentbox(true);
    if(editResponseData?.responseType === "not offered"){
      setComment(editResponseData?.comment)
    }
    setCurrentModal((prev) => prev + 1);
    return;
  }
  if (currentModal === 2  && suspicious === "No") {
    setOpenCommentbox(true);
    if(editResponseData?.responseType === "offered and not suspicious"){
      setComment(editResponseData?.comment)
    }
    setCurrentModal((prev) => prev + 1);
    return;
  }
  if ((currentModal === 1 && offer) || (currentModal === 2 && suspicious) || currentModal === 3 ) {
    if (currentModal < 3) {
      setCurrentModal((prev) => prev + 1);
    } else {
      createResponseHandler(formResponse);
    }
  }
};
const resetForm = (type = '') =>  {
  if(!type){
    setCurrentModal(0);
    setSuspicious(null);
    setOffer(null);
    setEditResponseId(null)
  }
  setOpenCommentbox(false);
  setComment("");
  setshowError("");
  setErrorMessage('')

}
const backLabel = () => {
  if (currentModal > 1) {
    return "Back";
  } else {
    return "Cancel";
  }
};

const nextLabel = () => {
  if (currentModal === 3 || openCommentbox) {
    return "Send response";
  } else {
    return "Continue";
  }
};
const responseFooter = (
  <div>
    <PR.Button
      label={backLabel()}
      onClick={handleBack}
      disabled={alertLoading}
      outlined
      className="logout-btns"
    />
    <PR.Button
      label={nextLabel()}
      onClick={handleNext}
      disabled={( currentModal === 1 && !offer ? true : currentModal === 2 && !openCommentbox && !suspicious ? true : false) || alertLoading || errorMessage}
      icon={currentModal === 3 || openCommentbox ? null : "pi pi-arrow-right"}
      iconPos={currentModal === 3 || openCommentbox ? null : "right"}
      loading={alertLoading}
      outlined
      autoFocus
      className="logout-btns click-btn"
    />
  </div>
);

const renderFirstModal = () => {
  return (
    <PR.Dialog
      visible={currentModal === 1}
      footer={responseFooter}
      className="logout-dialog response-alert-dialog"
      blockScroll="true"
      closeOnEscape={false}
    >
      <div className="logout-section">
        <p>Respond to the alert</p>
        <h2>Did you offer betting markets on the event?</h2>
        <div className="card grid mt-1">
          <div className="col-6">
            <div className="box">
              <div className="flex align-items-center">
                <PR.RadioButton
                  inputId="offeryes"
                  name="offer"
                  value="Yes"
                  onChange={(e) => setOffer(e.value)}
                  checked={offer === "Yes"}
                />
                <label htmlFor="offeryes" className="ml-2">
                  Yes
                </label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="box">
              <div className="flex align-items-center">
                <PR.RadioButton
                  inputId="offerno"
                  name="offer"
                  value="No"
                  onChange={(e) => setOffer(e.value)}
                  checked={offer === "No"}
                />
                <label htmlFor="offerno" className="ml-2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PR.Dialog>
  );
};

const renderSecondModal = () => {
  return (
    <PR.Dialog
      visible={currentModal === 2 && !openCommentbox}
      footer={responseFooter}
      className="logout-dialog response-alert-dialog"
      blockScroll="true"
      closeOnEscape={false}
    >
      <div className="logout-section">
        <p>Respond to the alert</p>
        <h2>Did you see any suspicious activity?</h2>
        <div className="card grid mt-1">
          <div className="col-6">
            <div className="box">
              <div className="flex align-items-center">
                <PR.RadioButton
                  inputId="suspiciousyes"
                  name="suspicious"
                  value="Yes"
                  onChange={(e) => setSuspicious(e.value)}
                  checked={suspicious === "Yes"}
                />
                <label htmlFor="suspiciousyes" className="ml-2">
                  Yes
                </label>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="box">
              <div className="flex align-items-center">
                <PR.RadioButton
                  inputId="suspiciousno"
                  name="suspicious"
                  value="No"
                  onChange={(e) => setSuspicious(e.value)}
                  checked={suspicious === "No"}
                />
                <label htmlFor="suspiciousno" className="ml-2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PR.Dialog>
  );
};

const renderThirdModal = () => {
  return (
    <PR.Dialog
      visible={currentModal === 3 && !openCommentbox}
      className="logout-dialog edit-alert-dialog"
      blockScroll="true"
      closeOnEscape={false}
    >
      <section className="create-alert-section">
        <div className="create-alert-wrapper">
          <div className="m-0">
            <p>Respond to the alert</p>
            <h1>Tell us more about it</h1>
          </div>
          {editResponseData?.responseID && editResponseData.responseType === 'offered and suspicious' ? (
            <EditAlertForm
              handleBack={handleBack}
              handleNext={handleNext}
              initialData={editResponseData}
              nextLabel="Send response"
              backLabel="Back"
              showError={showError}
              setshowError={setshowError}
              responseID={editResponseId}
              alertLoading={alertLoading}
              errorId={alertDetailsError}
            />
          ) : (
            <AlertForm
              initialData={alertData}
              editForm={false}
              handleBack={handleBack}
              handleNext={handleNext}
              nextLabel="Send response"
              backLabel="Back"
              showError={showError}
              setshowError={setshowError}
              responseID={editResponseId}
              alertLoading={alertLoading}
              errorId={alertDetailsError}
            />
          )}
        </div>
      </section>
    </PR.Dialog>
  );
};

const renderCommentBox = () => {
  return (
    <PR.Dialog
      visible={openCommentbox}
      footer={responseFooter}
      className="logout-dialog response-alert-dialog"
      blockScroll="true"
      closeOnEscape={false}
    >
      <div className="logout-section">
        <p>Respond to the alert</p>
        <h2>Add comment</h2>
        <PR.Editor
          placeholder="Explain in detail.."
          value={comment}
          onTextChange={(e) => {
              const htmlValue = e.htmlValue;
              if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                setErrorMessage('Enter valid text');
              } else if (getPlainText(htmlValue) > chatCount) {
                setComment(htmlValue);
                setErrorMessage(`Comment should be ${chatCount} characters or less.`);
              } else {
                setComment(htmlValue);
                setErrorMessage("");
              }
          }}
          headerTemplate={textEditorHeader}
          style={{ height: "200px" }}
        />  
        <div className="descriptionGrid descriptioncharLimit mt-2">
          <span style={{textAlign:"left"}}>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <ErrorMessage id={alertDetailsError}></ErrorMessage>
          </span>
          {remainingCharacters(chatCount, comment)}
        </div>
      </div>
    </PR.Dialog>
  );
};
const editResponseHandler = (modalId, data) => {
  const alertResponse = { ...data};
    const alertResponseData = { ...data};
    alertResponseData.events = alertResponse.eventInfo ? JSON.parse(alertResponse.eventInfo) : [];
    alertResponseData.customerInfo = alertResponse.customerInfo ? JSON.parse(alertResponse.customerInfo) : {};
    alertResponseData.fileAttachment = alertResponse.fileAttachment ? JSON.parse(alertResponse.fileAttachment) : [];
    setEditResponseData(alertResponseData);
    setEditResponseId(data.responseID);
  if(data.responseType === 'offered and suspicious'){
    setOffer('Yes');
    setSuspicious('Yes');
    setCurrentModal(modalId)
  } else if(data.responseType === 'not offered') {
    setOffer('No');
    setCurrentModal(modalId)
  } else if(data.responseType === 'offered and not suspicious'){
    setOffer('Yes');
    setSuspicious('No');
    setCurrentModal(modalId);
  }
}
// function to call create response API
const createResponseHandler = (data) => {
  setAlertLoading(true);
  const key = getAlertId;
  createResponse(key, data, headers, dispatch, (response) => {
    setAlertLoading(false);
    if (response.result === "SUCCESS") {
      showToast("success", "Response has been submitted successfully");
      navigate(location.pathname, { replace: true, state: null });
      getDetails();
      if(openCommentbox || currentModal >= 3){
        resetForm();
      }
    } else {
      const error = response?.error;
      if(error?.errorMsg === 'Alert already responded') {
        navigate(location.pathname, { replace: true, state: null });
        getDetails();
        showToast("warn", 'Alert has already been responded to.');
        resetForm();
      } else {
        showToast(error.severity, error?.errorMsg || error?.summary, alertDetailsError);
        setTimeout(() => {
          clearError(alertDetailsError)
        }, 3000)   
      }
    }
  });
};
/* ----- END Edit alert and response functionality --- */


  const chatFooterContent = (
    <div className='align-items-center justify-content-between'>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <span className="ask-field">
          <PR.Editor
            placeholder="Message to send..."
            name="content"
            value={formik.values.content}
            onTextChange={(e) => {
              const htmlValue = e.htmlValue;
              if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                setErrorMessage('Enter valid text');
              } else if (getPlainText(htmlValue) > chatCount) {
                formik.setFieldValue('content', htmlValue);
                setErrorMessage(`Message should be ${chatCount} characters or less.`);
              } else {
                formik.setFieldValue('content', htmlValue);
                setErrorMessage("");
              }
            }}
            headerTemplate={textEditorHeader}
            style={{ minHeight: "60px", maxHeight: "450px", overflowY: "auto" }}
          />
          <div className="align-items-start justify-content-between mt-2">
            <div className="descriptionGrid descriptioncharLimit">
              <span style={{ textAlign: "left" }}>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
              </span>
              {remainingCharacters( chatCount, formik.values.content)}
            </div>
          </div>
          <div className="flex flex-column gap-2 mt-3">
            <div className="card file-upload flex align-items-center">
              <PR.FileUpload
                auto
                mode="advanced"
                name="attachments"
                accept=".pdf,.csv,.docx,.xlsx,.doc,.xls,.png,.jpeg,.jpg"
                chooseLabel="Choose File"
                uploadLabel="Upload"
                emptyTemplate={null}
                itemTemplate={null}
                customUpload
                onSelect={(event) => {
                  const selectedFiles = event.files; // Get the selected files
                  formik.setFieldTouched("attachments", true); // Mark field as touched
                  formik.setFieldValue("attachments", selectedFiles); // Update Formik's state
                }}
              />
              <span className="ml-2">
                <span className="block">(PDF, DOC, DOCX, XLS, XLSX, CSV, PNG, JPG or JPEG)</span>
                File should be less than {fileUploadLimit.label}.
              </span>

            </div>
            {formik.values.attachments && (
              <div className="uploaded-file-container flex flex-column gap-2">
                {formik.values.attachments?.map((attachment, index) => (
                  <div className="uploaded-files" key={index}>
                    {attachment.name}
                    <i className="pi pi-trash" onClick={() => handleDeleteAttachment(index)} />
                  </div>
                ))}
              </div>
            )}
            {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
              <div className="error-message">
                {Array.isArray(formik.errors.attachments) ? (
                  Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                    <div key={index}>{error}</div>
                  ))
                ) : (
                  <div>{formik.errors.attachments}</div>
                )}
              </div>
            )}
            <div className='text-right'>
              <PR.Button label="Send Message"
                outlined className="dialog-logout-btns send-message"
                loading={chatLoading}
                disabled={
                  !(formik.values.content?.trim() || formik.values.attachments?.length > 0) || errorMessage || chatLoading
                }
                type='submit'
              />
            </div>
          </div>
        </span>
      </form>
    </div>
);

  const [responsesPopupLoading, setResponsesPopupLoading] = useState(false);
  const [responsesData, setResponsesData] = useState({});

  const [respondedVisible, setRespondedVisible] = useState(false);

  const [notRespondedVisible, setNotRespondedVisible] = useState(false);
  const getReference = (count) => {
    return count > 1 ? "members" : "member";
  };

  const showReceived = () => {
    setRespondedVisible(true);
    getAlertResponsesHandler(getAlertId);
  };

  const showPending = () => {
    setNotRespondedVisible(true);
    getAlertResponsesHandler(getAlertId);
  };
  const getAlertResponsesHandler = (id) => {
    setResponsesPopupLoading(true);
    setRespondedSearchQuery('');
    setNotRespondedSearchQuery('');
    getAlertResponses(id, headers, dispatch, (response) => {
      if (response.result === "SUCCESS" && response.data) {
        let data = {};
        const alertResponses = response.data;

        const respondedInfo = alertResponses?.filter(
          (response) => response.responseID
        );
        const notRespondedInfo = alertResponses?.filter(
          (response) => !response.responseID
        );

        data["respondedInfo"] = respondedInfo;
        data["respondedCount"] = respondedInfo?.length;

        data["notRespondedInfo"] = notRespondedInfo;
        data["notRespondedCount"] = notRespondedInfo?.length;

        setResponsesData(data);
        setResponsesPopupLoading(false);
      } else {
        setResponsesPopupLoading(false);
      }
    });
  };
  const getRespondedMemberData =  (data, searchQuery) => {
    if(data?.length){
      const filterData = searchQuery && searchQuery.length ? data.filter(d => d.respondingGroup.toLowerCase().includes(searchQuery.toLowerCase())) : data;
      return filterData.sort((a, b) => a.respondingGroup.localeCompare(b.respondingGroup));
    }
    return []
  }
  const scrollHandler = useCallback((id) => {
    const index = alertResponses?.findIndex(res => res.responseID === id);
    const findAlertResponse = alertResponses?.find(res => res.responseID === id);
    const doesExpandEnable = findAlertResponse.responseType !== "offered and suspicious" ? !findAlertResponse?.comment : false;
    setMemberResponseId(id);
    if (index !== -1 && !doesExpandEnable) {
      setResponsesActiveIndex(index);
    } else {
      setResponsesActiveIndex();
    }
    const target = document.getElementById(`expand-accordian-${id}`);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }, [alertResponses])
  const getResponsesId = (responseId) => {
    setRespondedVisible(false)
    handleResponseChange({target: {type: 'checkbox',  value: 'all', checked: true}})
    scrollHandler(responseId);
  }
  useEffect(() => {
    if (responseID && alertResponses.length) {
      setLoadingScroll(false);
      setTimeout(() => {
        scrollHandler(responseID);
      }, 1000);
    }
  }, [alertResponses, responseID, scrollHandler])


  const respondedFooterContent = (
    <div>
      <PR.Button
        label="Close"
        onClick={() => {
          setRespondedVisible(false);
          setResponsesData({});
        }}
        outlined
        className="logout-btns"
      />
    </div>
  );

  const notRespondedFooterContent = (
    <div>
      <PR.Button
        label="Close"
        onClick={() => {
          setNotRespondedVisible(false);
          setResponsesData({});
        }}
        outlined
        className="logout-btns"
      />
    </div>
  );

  const descriptionFooter = (
    <div>
      <PR.Button
        label="Cancel"
        onClick={() => {
          setAlertDesc(decodeHtml(alertData?.description));
          setVisible(false);
        }}
        outlined
        className="logout-btns"
      />
      <PR.Button
        label="Save Changes"
        onClick={editAlertDesc}
        disabled={(!alertDesc?.trim().length || (alertDesc === decodeHtml(alertData?.description)) || alertDescError)}
        outlined
        autoFocus
        className="logout-btns click-btn"
      />
    </div>
  );
  const [visibleEditDetails, setVisibleEditDetails] = useState(false);
  const onBackClick = () => {
    setVisibleEditDetails(false);
  };

  const handleUpdateAlert = (obj) => {
    setAlertLoading(true);
    createAlert(obj, headers, dispatch, (response) => {
      setAlertLoading(false);
      if (response.result === "SUCCESS") {
        setVisibleEditDetails(false);
        showToast("success", "Updated Alert Successfully");
        getDetails();
      } else {
          const error = response.error;
          showToast(error.severity, error?.errorMsg || error?.summary, alertDetailsError);
          setTimeout(() => {
            clearError(alertDetailsError)
          }, 3000)        
      }
    });
  };

  const renderAlertTemplate = (unparsedResponse) => {
    let response = {
      ...unparsedResponse,
      events:
        unparsedResponse?.eventInfo && JSON.parse(unparsedResponse?.eventInfo),
      customerInfo:
        unparsedResponse?.customerInfo &&
        JSON.parse(unparsedResponse?.customerInfo),
      fileAttachment:
        unparsedResponse?.fileAttachment &&
        JSON.parse(unparsedResponse?.fileAttachment),
    };
    return (
      <>
        <div className="details-section">
          {response.respondingGroup === companyUser && (
            <div className="flex align-items-center justify-content-between response-editorheader">
              <h3>Response details</h3>
            </div>
          )}
            <EventDetails data={response} isResponse={true} nonIbiaAttachments={nonIbiaAttachments} alertType={alertData?.alertType}/>

        </div>
        <p>Description:</p>
        <p>{response.description}</p>
      </>
    );
  };

  const renderCommentTemplate = (response) => {
    return (
      <>
        <div className="details-section">
          {response.respondingGroup === companyUser && (
            <div className="flex align-items-center justify-content-between response-editorheader">
              <h3>Response details</h3>
            </div>
          )}
          <p>Comment</p>
          { response.comment && <div className="content" dangerouslySetInnerHTML={{ __html: dompurifyText(response.comment) }} /> }
          
        </div>
      </>
    );
  };

  const parseAndSetData = useCallback((responseData) => {
    if (responseData === null) {
      isAnalyst ? navigate("/home") : navigate("/alerts");
    } else {
      let data = {
        ...responseData,
        customerInfo:
          responseData?.customerInfo && JSON.parse(responseData?.customerInfo),
        comments:
          responseData?.comments && JSON.parse(responseData?.comments),
        fileAttachment:
          responseData?.fileAttachment && JSON.parse(responseData?.fileAttachment),
      };
      const alertDetails = responseData ? data : {};
      const responses = alertDetails?.responses?.length ? alertDetails?.responses : [];
      const alertResponses = responses?.sort((a, b) => a.respondingGroup.localeCompare(b.respondingGroup));
      setAlertResponses(alertResponses);
      setFilteredData(alertResponses);
      setData(alertDetails);
      setAlertId(alertDetails?.alertID)
      setAlertDesc(decodeHtml(alertDetails?.description))
  
      const initialFilters = filterResponseType?.reduce((acc, filter) => {
        acc[filter] = filter === "all";
        return acc;
      }, {});
  
      setFilters(initialFilters);
    }
  }, [filterResponseType, isAnalyst, navigate]);
  
  
  const getDetails = useCallback(() => {
    const id = params?.id;
    if(!id?.toLowerCase()?.includes('alert-') && !Number(id)){
      isAnalyst ? navigate("/home") : navigate("/alerts");
    } else if (id.length > 11) {
      isAnalyst ? navigate("/home") : navigate("/alerts");
    } else {
      getAlertDetails(id, headers, dispatch, (response) => {
        if(!responseID){
          setLoadingScroll(false);
        }
        if (response.result === "SUCCESS") {
          parseAndSetData(response.data);
        } else {
          setData({});
          setAlertDesc("");
          const error = response?.error;
          showToast(error.severity, error?.errorMsg || error?.summary);
        }
      });
    }
  }, [dispatch, headers, isAnalyst, navigate, params?.id, parseAndSetData, responseID, showToast]);

  // Filter the responses section based on checkbox selection
  const handleResponseChange = (event) => {
    const { value, checked } = event.target;
    setFilters((prevFilters) => {
      let newFilters = { ...prevFilters, [value]: checked };
      if (value === "all") {
        // If "all" is checked, uncheck all other filters
        newFilters = filterResponseType?.reduce((acc, filter) => {
          acc[filter] = filter === "all" ? checked : false;
          return acc;
        }, {});
      } else if (checked) {
        // If any filter other than "all" is checked, uncheck "all"
        newFilters["all"] = false;
      }

      // Check if all checkboxes (except "all") are unchecked
      const allUnchecked = filterResponseType
        .slice(1)
        .every((filter) => !newFilters[filter]);
      if (allUnchecked) {
        newFilters["all"] = true;
      }
      // Filter data based on updated filters
      const isAllSelected = newFilters["all"];
      const newFilteredData = isAllSelected
        ? alertResponses
        : alertResponses?.filter((item) => newFilters[item.responseType]);

      setFilteredData(newFilteredData);
      return newFilters;
    });
  };
  const displayAlertDetails = useCallback(() => {
    const alertDetailsData = location?.state?.alertDetailsData;
    if(!responseID){
      setLoadingScroll(false);
    }
    if (alertDetailsData.result === "SUCCESS") {
    
      parseAndSetData(alertDetailsData.data);
    } else {
      setData({});
      const error = alertDetailsData?.error;
      showToast(error.severity, error?.errorMsg || error?.summary);
    }
  }, [location?.state?.alertDetailsData, parseAndSetData, responseID, showToast]); 
  useEffect(() => {
    const passedAlertDetailsData = location?.state?.alertDetailsData;
    const isPageReloaded = localStorage.getItem('pageRefreshed');
    if(location?.state){
      localStorage.setItem('pageRefreshed', true);
    }
    if(isPageReloaded === 'true' && !group){
      localStorage.setItem('pageRefreshed', false);
      navigate(location.pathname, { replace: true, state: null });
      if(!location?.state){
        getDetails();
      }
    } else if (passedAlertDetailsData) {
      displayAlertDetails();
    } else {
      getDetails();
    }
  }, [displayAlertDetails, getDetails, location.pathname, location?.state, navigate, group]); 

  const fetchFileDetails = (key, fileName) => {
    const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
      try {
        const url = URL.createObjectURL(pdfData);

        const link = document.createElement("a"); //download link
        link.href = url;
        link.setAttribute("download", fileName || "file.pdf");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    };
    getFileDetails(key, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const fileData = response.data;
        convertBlobToPDFAndDownload(fileData, fileName);
      } else {
        const error = response?.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });
  };

 

  const backBtn = () => {
    if (role === "analyst") {
      return "home";
    }
    if (role === "member" || role === "managedmember") {
      return "alerts";
    }
  };

  const responseTypeFilter = RAW_FILTER_DATA?.responseTypeFilter;
 
  // Edit a comment
  const handleEditComment = (commentId, commentContent) => {
    setEditCommentId(commentId);
    seteditCommentValue(decodeHtml(commentContent)); // Populate the InputTextarea with the comment content
    setValue("");
    setCommentError("");
    setEditCommentError("");
  };

  // Delete a comment
  const handleDeleteComment = (commentId) => {
    setDeleteCommentId(commentId);
    setDeleteCommentVisible(true);
  };

  const confirmDeleteComment = () => {
    setDeleteCommentVisible(true);
    const alertId = getAlertId
    deleteComment(alertId, deleteCommentId, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        showToast("success", "Comment deleted successfully");
        const updatedComments = alertData?.comments.filter(
          (comment) => comment.commentID !== deleteCommentId
        );
        setData({ ...alertData, comments: updatedComments });
        setDeleteCommentVisible(false);
        setDeleteCommentId(null);
      } else {
        const error = response?.error;
        showToast('warn', error?.errorMsg || error?.summary);
      }
    });
    setValue("");
    seteditCommentValue("");
    setCommentError("");
    setEditCommentError("");
  }
  const onChangeCommentHandler = (e, setErrorFn, setValueFn, charactersCount = chatCount) => {
    setValueFn(e.target.value.substring(0, charactersCount));
    if (getPlainText(e.target.value) === 0 && e.target.value) {
      setErrorFn("Enter valid text");
    } else if (e.target.value?.length <= charactersCount) {
      setErrorFn("");
    } else {
      setErrorFn(`Message should be ${charactersCount} characters or less.`);
    }
  }
  const onPastCommentHandler = (e, setErrorFn, setValueFn, charactersCount = chatCount) => {
    const pasteText = e.clipboardData.getData("text");
    const input = e.target;
    const value = e.target.value;
    const cursorPosition = input.selectionStart;
    const end = input.selectionEnd;
    const maxCharsToAdd = charactersCount - value.length + (end - cursorPosition);
    const newMessage = value.substring(0, cursorPosition) + pasteText.substring(0, maxCharsToAdd) + value.substring(end);
    setValueFn(newMessage);
    // Move the cursor to the end of the pasted text
    setTimeout(() => {
      input.setSelectionRange(cursorPosition + pasteText.length, cursorPosition + pasteText.length);
    }, 0);
    if (getPlainText(newMessage) === 0 && newMessage) {
      setErrorFn("Enter valid text");
    } else if (newMessage.length > charactersCount) {
      setErrorFn(`Message should be ${charactersCount} characters or less.`);
    } else {
      setErrorFn("");
    }
    e.preventDefault();

  }

  const footerDelete = (
    <div>
        <PR.Button label="Cancel" onClick={() =>{setDeleteCommentVisible(false); setDeleteCommentId(null)}} outlined className="logout-btns" />
        <PR.Button label="Delete" onClick={() => confirmDeleteComment()} outlined autoFocus className="logout-btns click-btn" />
    </div>
  );

  const canEditAlert = (alertData) => {
    const isCreatedByUserGroup = alertData.createdByGroupID === usersGroupId;
    const isAlertOpenOrEvaluating = alertData.alertStatus === "open";
    const isMemberWithEditAccess = isMember && isCreatedByUserGroup && isAlertOpenOrEvaluating;
    return isMemberWithEditAccess || isAnalyst;
  };
  return (
    <>
      <Layout
        backBtn={backBtn()}
        backBtnLabel={
          role === "analyst"
            ? "Home"
            : role === "member" || role === "managedmember"
            ? "Alerts"
            : ""
        }
      >
        <section className="alert-details-section">
          <div className="m-0">
            <h1>Alert Details</h1>
          </div>

          <div className="details-wrapper">
            {loadingScroll ? (
              <div className="loading">
                <i className="pi pi-spin pi-spinner"></i>
              </div>
            ) : (
              <>
                <div className="header-section">
                  <div className="card flex align-items-center justify-content-between">
                    <div className="left-section">
                      <div className="flex align-items-center gap-3">
                        {alertData?.events?.length > 0 ? (
                          <div className="sports-icon">
                            {getSportIcon(alertData?.events[0]?.sportID + "b")}
                          </div>
                        ) : (
                          <PR.Image
                            src={eventIcon}
                            alt="Sport"
                            className="sports-icon"
                          />
                        )}
                        <h2>{alertData?.alertName}</h2>
                        {alertData?.alertStatus && (
                          <div
                            className={`capitalize status ${additionalStyle}`}
                          >
                            {alertData?.alertStatus}
                          </div>
                        )}
                        {alertData?.caseStatus && (
                          <div className="capitalize status">
                            {getStatusLabel(alertData?.caseStatus)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="right-section flex align-items-center text-right align-items-center gap-3">
                      {(isMember &&
                        alertData?.conversationExists) ||
                      (isAnalyst && sessionData?.groupID !== groupID) ? (
                        <div
                          className={`response chat ${
                            alertData?.activeConversation ? "active" : ""
                          }`}
                        >
                          <PR.Image
                            src={chatIcon}
                            alt="Chat ID"
                            onClick={() => {
                              setChatVisible(true);
                              formik.resetForm();
                              setConversationClicked(true);
                              getChatConversation(getAlertId, groupID);
                              setChatInfo((prev) => ({
                                ...prev,
                                groupID: groupID,
                                alertID: getAlertId,
                                alertName: alertData?.alertName,
                                legacyID: alertData?.legacyID
                              }));
                            }}
                          />
                        </div>
                      ) : null}

                      {/* Change alert status - Buttons */}
                      {isAnalyst &&
                      alertData.alertStatus === "suspicious" ? (
                        <PR.Button
                          label="Change Case Status"
                          className="respond-button status-button"
                          onClick={() => setAlertStatusVisible(true)}
                        />
                      ) : alertData.alertStatus === "suspicious" ? (
                        <PR.Button
                          label="View Case Status"
                          className={` ${
                            isAnalyst ? "analyst" : "member"
                          } respond-button status-button`}
                          onClick={handleViewCaseStatus}
                        />
                      ) : (
                        isAnalyst && (
                          <PR.Button
                            label="Change Alert Status"
                            className="respond-button status-button"
                            onClick={() => {
                              setAdviceAttachments();
                              setAlertStatusVisible(true);
                            }}
                          />
                        )
                      )}
                      {isAnalyst && (
                        <PR.Button
                          label="Assign"
                          className="respond-button assign-button"
                          onClick={() => {
                            setAssigneeVisible(true);
                            getAssigneeNames();
                          }}
                        />
                      )}

                      {/* Change alert status - Buttons */}

                      {alertData.createdByGroup !== sessionData.companyName &&
                        alertData?.responseRequired &&
                        (alertData?.alertStatus === "open") &&
                        role !== "analyst" && (
                          <>
                            <PR.Button
                              label="Respond to Alert"
                              className="respond-button"
                              onClick={() => {
                                setAlertLoading(false);
                                setCurrentModal(1);
                                setshowError("");
                              }}
                            />
                            <div className="status error">
                              <i className="pi pi-info-circle mr-2"></i> This
                              alert requires your response immediately!
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className="details-section">
                  <div className="flex align-items-center justify-content-between">
                    <h3>Alert details</h3>
                      {canEditAlert(alertData) && (
                          <PR.Button
                            className="edit-button"
                            iconPos="left"
                            onClick={() => { setVisibleEditDetails(true); setAlertLoading(false); }}
                          >
                            <PR.Image
                              src={editIcon}
                              alt="Filter Icon"
                              className="icon"
                            />
                            <span className="label">Edit Details</span>
                          </PR.Button>
                        )}
                  </div>
                  <div className="hr-line"></div>
                    <EventDetails data={alertData} isResponse={false} nonIbiaAttachments={nonIbiaAttachments} alertType={alertData?.alertType}/>
                </div>

                {alertData.ibiaAdvice &&
                  (!["open", "evaluating"].includes(alertData.alertStatus) ||
                    alertData.ibiaAdvice) && (
                    <div className="details-section advice-report-section">
                      <div className="flex align-items-center justify-content-between">
                        <h3>
                          Advice Issued:{" "}
                          {alertData.caseStatus === "closed" ||
                          alertData.caseStatus === "sanctioned"
                            ? `${capitalizeFirstLetter(
                                alertData?.alertStatus
                              )} & ${capitalizeFirstLetter(
                                alertData?.caseStatus
                              )}`
                            : capitalizeFirstLetter(alertData?.alertStatus)}
                        </h3>{" "}
                        {isAnalyst && (
                          <PR.Button
                            className="edit-button"
                            iconPos="left"
                            onClick={openEditAdviceDialog}
                          >
                            <PR.Image
                              src={editIcon}
                              alt="Filter Icon"
                              className="icon"
                            />
                            <span className="label">Edit</span>
                          </PR.Button>
                        )}
                      </div>
                      <div className="hr-line"></div>
                      <div className="grid justify-content-between mt-4">
                        <div className="col-8 border-right">
                          <div className="advice-report-block">
                          {alertData?.ibiaAdvice ? <div className="content" dangerouslySetInnerHTML={{ __html: dompurifyText(alertData?.ibiaAdvice) }} /> : <p>No Advice issued </p>}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="attached-files-block">
                            <p>Attached Files:</p>
                            {ibiaAttachments?.length > 0 ? (
                              ibiaAttachments?.map((file) => (
                                <PR.Button
                                  key={file.key}
                                  label={file.name}
                                  icon="pi pi-download"
                                  className="download-button"
                                  onClick={() =>
                                    fetchFileDetails(file.key, file.name)
                                  }
                                />
                              ))
                            ) : (
                              <p>No files attached</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <PR.Dialog
                        visible={editAdviceVisible}
                        footer={editAdviceFooter}
                        className="logout-dialog description-dialog evaluating-status-dialog"
                      >
                        <div className="logout-section">
                          <h2>Edit Advice and Attachments</h2>
                          <label htmlFor="description">Advice issued</label>
                          <div className="card1">
                            <PR.Editor
                                placeholder="Explain in detail.."
                                value={editedIbiaAdvice}
                                onTextChange={(e) => {
                                    const htmlValue = e.htmlValue;
                                    if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                                      setErrorMessage('Enter valid text');
                                    } else if (getPlainText(htmlValue) > descriptionCount) {
                                      setEditedIbiaAdvice(htmlValue);
                                      setErrorMessage(`Advice should be ${descriptionCount} characters or less.`);
                                    } else {
                                      setEditedIbiaAdvice(htmlValue);
                                      setErrorMessage("");
                                    }
                                }}
                                headerTemplate={textEditorHeader}
                                style={{ height: "200px" }}
                              />  
                              <div className="descriptionGrid descriptioncharLimit mt-2">
                                <span style={{textAlign:"left"}}>
                                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                                </span>
                                {remainingCharacters(descriptionCount, editedIbiaAdvice)}
                              </div>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div className="flex flex-column gap-2">
                            <label htmlFor="attachments">Attach file</label>
                            <div className="card file-upload attach-fileupload flex align-items-center">
                              <PR.FileUpload
                                auto
                                mode="advanced"
                                name="attachments"
                                accept=".pdf,.docx,.xlsx"
                                chooseLabel="Choose Files"
                                multiple
                                ref={fileInputRef}
                                onSelect={handleEditFileUpload}
                                customUpload
                              />
                              <span className="ml-2">
                                (Add file less than {fileUploadLimit.label})
                              </span>
                            </div>
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                              {selectedFiles?.map((file, index) => (
                                <div className="uploaded-file-container flex flex-column gap-2">
                                  <div className="uploaded-files" key={index}>
                                    {file.name}
                                    <i
                                      className="pi pi-trash"
                                      onClick={() =>
                                        handleDeleteSelectedFile(index)
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                              {editedFiles?.map((file, index) => (
                                <div className="uploaded-file-container flex flex-column gap-2">
                                  <div className="uploaded-files" key={index}>
                                    {file.name}
                                    <i
                                      className="pi pi-trash"
                                      onClick={() =>
                                        handleDeleteExistingFile(index)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </PR.Dialog>
                    </div>
                  )}

                <div className="description-comment-section">
                  <div className="grid">
                    <div className="col-6">
                      <PR.Accordion activeIndex={0}>
                        <PR.AccordionTab
                          header={
                            <div className="flex align-items-center justify-content-between">
                              <p>Alert description</p>
                              { canEditAlert(alertData) && (
                                <PR.Button
                                  className="edit-button"
                                  iconPos="left"
                                  onClick={(e) => {
                                    setVisible(true);  
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                >
                                  <PR.Image
                                    src={editIcon}
                                    alt="Filter Icon"
                                    className="icon"
                                  />
                                  <span className="label">Edit</span>
                                </PR.Button>
                              )}
                            </div>
                          }
                        >
                          <p className="m-0 desc-ws" dangerouslySetInnerHTML={{__html: dompurifyText(alertData?.description)}}>
                          </p>
                        </PR.AccordionTab>
                      </PR.Accordion>
                    </div>
                    <div className="col-6">
                      <PR.Accordion
                        activeIndex={alertData?.comments?.length ? 0 : -1}
                      >
                        <PR.AccordionTab header="Comment panel">
                          <div className="comment-panel">
                              <div className="chat-messages">
                                {alertData?.comments?.map((chat, index) => {
                                  if (!commentMenuRefs.current[index]) {
                                    commentMenuRefs.current[index] = React.createRef();
                                  }
                                  return (<div className="message-box" key={index}>
                                    <div className="sender-name">
                                      <p>
                                        <span>{chat?.groupName}</span>
                                      </p>
                                    </div>
                                    <div className="mb-3">
                                      <div className="flex align-items-center justify-content-between flex-column">
                                        {(editCommentId === chat?.commentID) ?
                                          <>
                                            <div className="w-12">
                                              <PR.InputTextarea
                                                className="custom-textarea"
                                                placeholder="Edit your comment..."
                                                name="comment"
                                                value={editCommentValue}
                                                onChange={(e) => {
                                                  onChangeCommentHandler(e, setEditCommentError, seteditCommentValue);
                                                }}
                                                onPaste={(e) => {
                                                  onPastCommentHandler(e, setEditCommentError, seteditCommentValue);
                                                }}
                                                autoResize={true}
                                              />
                                              <div className="descriptionGrid descriptioncharLimit">
                                              <span style={{ textAlign: "left" }}> {editCommentError && ( <div className="error-message"> {editCommentError} </div> )} </span>
                                                {remainingCharacters(chatCount, editCommentValue)}
                                              </div>
                                             
                                            </div>
                                            <div className="comment-box text-right w-12 mt-1">
                                              {(editCommentId === chat?.commentID) &&
                                                <>
                                                  <PR.Button
                                                    label="Cancel"
                                                    className="send-button cancel-button"
                                                    onClick={() => {
                                                      setEditCommentId(null);
                                                      seteditCommentValue("");
                                                      setValue("");
                                                      seteditCommentValue("");
                                                      setCommentError("");
                                                      setEditCommentError("");
                                                    }}
                                                    disabled={!editCommentId}
                                                  />
                                                  <PR.Button
                                                    label="Update"
                                                    className="send-button"
                                                    onClick={createComment}
                                                    disabled={!editCommentValue || editCommentError}
                                                  />
                                                </>
                                              }
                                            </div>
                                          </>
                                          :
                                          <div className="message">
                                            <p dangerouslySetInnerHTML={{ __html: dompurifyText(chat?.content) }}></p>
                                          </div>
                                        }
                                        {(isAnalyst && (editCommentId !== chat?.commentID)) && (
                                          <div className="dropdown-popup">
                                            <div className="edit-delete-popup">
                                              <i className="pi pi-pencil" onClick={() => handleEditComment(chat?.commentID, chat?.content)}></i>
                                              <i className="pi pi-trash" onClick={() => handleDeleteComment(chat?.commentID)}></i>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="time text-right mt-1">
                                        <span>
                                          {chat?.createdAt
                                            ? dateTimeFormat(chat.createdAt)
                                            : " "}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  )
                                }
                                )}
                                <div ref={commentsEndRef} />
                              </div>

                            {!isManagedMember && (
                              <>
                                <div className="card comment-box">
                                  <PR.InputTextarea
                                    className="custom-textarea"
                                    placeholder="Type your comment..."
                                    name="comment"
                                    value={value}
                                    onChange={(e) => {
                                      onChangeCommentHandler(e, setCommentError, setValue);
                                    }}
                                    onPaste={(e) => {
                                      onPastCommentHandler(e, setCommentError, setValue);
                                    }}
                                    autoResize={true}
                                  />
                                  <div className="descriptionGrid descriptioncharLimit">
                                    <span style={{ textAlign: "left" }}> {commentError && ( <div className="error-message"> {commentError} </div> )} </span>
                                    {remainingCharacters(chatCount, value)}
                                  </div>
                                  
                                </div>
                                <div className="comment-box text-right">
                                  <PR.Button
                                    label="Send"
                                    className="send-button"
                                    onClick={createComment}
                                    disabled={commentError || !value}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </PR.AccordionTab>
                      </PR.Accordion>
                    </div>
                  </div>
                </div>
                <div className="responses-section">
                  <div className="grid">
                    <div className="col-9">
                      <div className="left-section">
                        <div className="flex align-items-center justify-content-between">
                          <h3>Responses</h3>
                          {alertData?.receivedResponseCount > 0 && (
                            <div className="flex gap-3">
                              <PR.Button
                                label={`Received: ${alertData?.receivedResponseCount}`}
                                className="response-button"
                                onClick={showReceived}
                              />
                              <PR.Button
                                label={`Pending: ${alertData?.pendingResponseCount}`}
                                className="response-button"
                                onClick={showPending}
                              />
                            </div>
                          )}
                        </div>
                        <div className="hr-line"></div>
                        {alertData?.receivedResponseCount > 0 ? (
                          <div className="filters-list">
                            <div className="flex flex-wrap gap-3">
                              {filterResponseType?.map((key) => (
                                <div
                                  className="flex align-items-center status-box"
                                  key={key}
                                >
                                  <PR.Checkbox
                                    inputId={key}
                                    value={key}
                                    onChange={handleResponseChange}
                                    checked={filters[key]}
                                  ></PR.Checkbox>
                                  <label
                                    htmlFor={key}
                                    className="ml-2 font-medium"
                                  >
                                    {
                                      responseTypeFilter?.find(
                                        (option) => option.id === key
                                      )?.name
                                    }
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <>
                            <p className="no-response-p-tag">
                              No responses received yet
                            </p>
                          </>
                        )}
                        <PR.Accordion
                          activeIndex={responsesActiveIndex}
                          className="response-accordion"
                          onTabChange={(e) => { 
                            setResponsesActiveIndex(e.index);
                            if(e.index !== null){
                              if(filteredData?.length){
                               setMemberResponseId(filteredData[e.index]?.responseID)
                              }
                             } else {
                              setMemberResponseId();
                             }
                            }
                          }
                        >
                          {filteredData?.map((response) => {
                            return (
                              <PR.AccordionTab
                                className={(response.responseID === memberResponseId && response.responseType !== "offered and suspicious" && !response?.comment) && `responseTabBorder`}
                                id={`expand-accordian-${response.responseID}`}
                                key={response.respondingGroup}
                                disabled={ response.responseType !== "offered and suspicious" ? !response?.comment : false }
                                header={
                                  <div className="flex align-items-center justify-content-between ">
                                    <ul className="flex list-none align-items-center gap-2">
                                      <li className="flex align-items-center gap-2">
                                        <ImageDisplay
                                          profileImage={response?.groupImage}
                                          type="group"
                                        />
                                        <span className="capitalize">
                                          {response.respondingGroup}
                                        </span>
                                      </li>
                                      <li>|</li>
                                      <li>
                                        {response?.createdAt
                                          ? dateTimeFormat(response?.createdAt)
                                          : ""}
                                      </li>
                                    </ul>
                                    <div className="flex align-items-center response-options gap-4">
                                      <div
                                        className={`status ${
                                          RAW_FILTER_DATA.responseTypeFilter?.find(
                                            (item) =>
                                              item.id === response.responseType
                                          )?.style || ""
                                        }`}
                                      >
                                        {
                                          responseTypeFilter?.find(
                                            (option) =>
                                              option.id ===
                                              response?.responseType
                                          )?.name
                                        }
                                      </div>
                                      <div
                                        className={`response-chat ${
                                          response?.activeConversation
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {(isMember &&
                                          response.respondingGroupID ===
                                            sessionData.groupID &&
                                          response?.conversationExists) ||
                                        isAnalyst ? (
                                          <>
                                          <PR.Image
                                            src={msgIcon}
                                            alt="Chat ID"
                                            className="msg-icon"
                                            onClick={(e) => {
                                              setChatVisible(true);
                                              formik.resetForm();
                                              getChatConversation(
                                                getAlertId,
                                                response?.respondingGroupID
                                              );
                                              setChatInfo((prev) => ({
                                                ...prev,
                                                groupID:
                                                  response?.respondingGroupID,
                                                alertID: getAlertId,
                                                alertName: alertData?.alertName,
                                                legacyID: alertData?.legacyID
                                              }));
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}
                                          />
                                          </>
                                        ) : null}
                                      </div>
                                      <div>
                                        {((sessionData.groupID === response.respondingGroupID && isMember && (alertData?.alertStatus === "open")) || (isAnalyst)) &&
                                          (
                                            <PR.Button className="edit-button" iconPos="left" onClick={(e) => {
                                              editResponseHandler(1, response);
                                              e.stopPropagation();
                                              e.preventDefault();
                                            }}>
                                              <PR.Image src={editIcon} alt="Filter Icon" className="icon" />
                                              <span className="label">Edit</span>
                                            </PR.Button>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                }
                              >
                                <div> 
                                  {response.responseType === "offered and suspicious" ? renderAlertTemplate(response) : renderCommentTemplate(response)}
                                </div>
                              </PR.AccordionTab>
                            );
                          })}
                        </PR.Accordion>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="right-section">
                        <div className="reporter-profile">
                          <ImageDisplay
                            profileImage={alertData?.userImage}
                            type="user"
                          />
                          <p>Reporter</p>
                          <h4>{alertData?.createdByGroup}</h4>
                        </div>
                        <div className="hr-line"></div>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Created</li>
                          <li>{dateTimeFormat(alertData?.createdAt)}</li>
                        </ul>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Updated</li>
                          <li>{dateTimeFormat(alertData?.updatedAt)}</li>
                        </ul>
                        <ul className="list-none flex align-items-center justify-content-between">
                          <li>Resolved</li>
                          <li>
                            {alertData?.resolvedAt &&
                              dateTimeFormat(alertData?.resolvedAt)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <PR.Dialog
          visible={visible}
          footer={descriptionFooter}
          className="logout-dialog description-dialog"
          blockScroll="true"
        >
          <div className="logout-section">
            <h2>Edit alert description</h2>
            <PR.InputTextarea
              id="editAlertDesc"
              value={alertDesc}
              onChange={(e) =>
                onChangeCommentHandler(e, setAlertDescError, setAlertDesc, descriptionCount)
              }
              onPaste={(e) =>
                onPastCommentHandler(e, setAlertDescError, setAlertDesc, descriptionCount)
              }
            />
            <div className="descriptionGrid descriptioncharLimit">
              <span style={{ textAlign: "left" }}> {alertDescError && ( <div className="error-message"> {alertDescError} </div> )} </span>
              {remainingCharacters(descriptionCount,  alertDesc)}
            </div>
          </div>
        </PR.Dialog>
        {renderFirstModal()}
        {renderSecondModal()}
        {renderCommentBox()}
        {renderThirdModal()}

        {/* Edit details dialog */}
        <PR.Dialog
          visible={visibleEditDetails}
          className="logout-dialog edit-alert-dialog"
          blockScroll="true"
          onHide={() => setVisibleEditDetails(false)}
          closeOnEscape="true"
        >
          <section className="create-alert-section">
            <div className="create-alert-wrapper">
              <div className="m-0">
                <h1>Edit Details</h1>
              </div>
              <EditAlertForm
                handleBack={onBackClick}
                handleNext={handleUpdateAlert}
                initialData={alertData}
                nextLabel="Update Alert"
                backLabel="Cancel"
                showError={showError}
                setshowError={setshowError}
                responseID={null}
                alertLoading={alertLoading}
                errorId={alertDetailsError}
              />
            </div>
          </section>
        </PR.Dialog>
        {/* Chat Dialog  */}
        {chatLoading && !isChatPopupOpen ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            blockScroll
            visible={chatVisible}
            footer={chatFooterContent}
            onHide={() => {
              setErrorMessage("");
              setChatVisible(false);
              setChatPopupOpen(false);
              setChatInfo({
                alertID: "",
                alertName: "",
                groupID: "",
                conversationID: "",
                legacyID: ""
              });
              formik.resetForm();
            }}
            className="logout-dialog response-dialog chat-dialog"
          >
            <div className="dialog-header">
              <div className="flex align-items-center gap-2">
                <ImageDisplay
                  profileImage={sessionData?.groupImage}
                  type="group"
                />
                <div className="chat-header">
                  <div className="">
                    Chat about Alert ID:{" "}
                    <span className="capitalize">{chatInfo?.legacyID}</span>
                  </div>
                  <span className="alert-name">{chatInfo.alertName}</span>
                </div>
              </div>
            </div>
            {chatData?.length === 0 ? (
              <p className="no-message-p">No messages yet.</p>
            ) : (
              <div className="dialog-body-section">
                {chatData?.map((item, index) => {
                  const rendermessage =
                    getChatUserName(item.senderID) !== userName ? (
                      <div
                        className="chat-block-received"
                        key={index + item.senderID}
                      >
                        <div className=" flex align-items-start">
                          <div className="mr-3">
                            <ImageDisplay
                              profileImage={getChatUserImage(item.senderID)}
                              type="user"
                            />
                          </div>
                          <div className="message-box">
                            <div className="message">
                              {item?.content && <div className="content" dangerouslySetInnerHTML={{ __html: dompurifyText(item?.content) }} />}
                              <div className="text-right">
                                {item?.attachment && (() => {
                                    const attachment = JSON.parse(item.attachment); 
                                    return (
                                        <PR.Button
                                            key={attachment.key}
                                            icon="pi pi-download"
                                            label={attachment.name}
                                            className="file-download-button"
                                            onClick={() => fetchFileDetails(attachment.key, attachment.name)}
                                        />
                                    );
                                })()}
                              </div>
                            </div>
                            <div className="time">
                              <span>{dateTimeFormat(item.createdAt)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="chat-block-sent flex align-items-start"
                        key={index}
                      >
                        <div className="message-box">
                          <div className="message">
                          {item?.content && <div className="content" dangerouslySetInnerHTML={{ __html: dompurifyText(item?.content) }} />}
                          <div className="text-right">
                            {item?.attachment && (() => {
                              const attachment = JSON.parse(item.attachment); 
                              return (
                                  <PR.Button
                                      key={attachment.key}
                                      icon="pi pi-download"
                                      label={attachment.name}
                                      className="file-download-button text-right"
                                      onClick={() => fetchFileDetails(attachment.key, attachment.name)}
                                  />
                              );
                            })()}
                          </div>
                          </div>
                          <div className="time text-right">
                            <span>{dateTimeFormat(item.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    );
                  return <div key={index}>{rendermessage}</div>;
                })}
                <div ref={messagesEndRef} />
              </div>
            )}
          </PR.Dialog>
        )}

        {/* Members Responded Dialog */}
        {responsesPopupLoading && respondedVisible ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            visible={respondedVisible}
            footer={respondedFooterContent}
            className="logout-dialog response-dialog"
            onHide={() => setRespondedVisible(false)}
            closeOnEscape="true"
            blockScroll="true"
          >
            {/****  Members Responded ****/}
            <div className="dialog-header">
              <div className="flex align-items-center justify-content-between mb-5">
                <h2>
                  {responsesData?.respondedCount}{" "}
                  {getReference(responsesData?.respondedCount)} responded
                </h2>
              </div>
              <span className="p-input-icon-left search-field">
                <i className="pi pi-search" />
                <PR.InputText
                    placeholder="Search"
                    id="alertsearch"
                    value={respondedSearchQuery}
                    onChange={(e) =>setRespondedSearchQuery(e.target.value)}
                  />
              </span>
            </div>
            <div className="dialog-body-section">
              {getRespondedMemberData(responsesData?.respondedInfo, respondedSearchQuery).map((company, index) => {
                return (
                  <div
                    key={index}
                    className="flex align-items-center member-response-list justify-content-between"
                  >
                    <div className="left">
                      <ul className="flex list-none align-items-center gap-2">
                        <li className="flex align-items-center gap-2">
                          <ImageDisplay
                            profileImage={company?.groupImage}
                            type="group"
                            key={company?.respondingGroupID}
                          />{" "}
                          <span title={company.respondingGroup}>{company.respondingGroup?.length > 20 ? company.respondingGroup?.slice(0, 20)+'..': company.respondingGroup}</span>
                        </li>
                        <li>|</li>
                        <li>{dateTimeFormat(company.createdAt)}</li>
                      </ul>
                    </div>
                    <div className="right flex align-items-center">
                      <span
                        className={
                          company.responseType === "offered and suspicious"
                            ? "suspicious"
                            : null
                        }
                      >
                        {company.responseType}
                      </span>{" "}
                      <i className="pi pi-chevron-right" onClick={() => getResponsesId(company.responseID)}></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </PR.Dialog>
        )}

        {/* Members Not Responded Dialog */}
        {responsesPopupLoading && notRespondedVisible ? (
          <div className="loading chat-loading">
            <i className="pi pi-spin pi-spinner"></i>
          </div>
        ) : (
          <PR.Dialog
            visible={notRespondedVisible}
            footer={notRespondedFooterContent}
            className="logout-dialog response-dialog"
            onHide={() => setNotRespondedVisible(false)}
            closeOnEscape="true"
            blockScroll="true"
          >
            {/* Members Not Responded  */}
            <div className="dialog-header">
              <div className="flex align-items-center justify-content-between mb-5">
                <h2>
                  {responsesData?.notRespondedCount}{" "}
                  {getReference(responsesData?.notRespondedCount)} not responded
                </h2>
              </div>
              <span className="p-input-icon-left search-field">
                <i className="pi pi-search" />
                  <PR.InputText
                    placeholder="Search"
                    value={notRespondedSearchQuery}
                    onChange={(e) =>setNotRespondedSearchQuery(e.target.value)}
                  />
              </span>
            </div>
            <div className="dialog-body-section">
              {getRespondedMemberData(responsesData?.notRespondedInfo, notRespondedSearchQuery).map((company, index) => {
                return (
                  <div key={index} className="not-responded-list">
                    <ImageDisplay
                      profileImage={company?.groupImage}
                      type="group"
                      key={company?.respondingGroupID}

                    />
                    <span>{company.respondingGroup}</span>
                  </div>
                );
              })}
            </div>
          </PR.Dialog>
        )}

        {/* Change alert status : open - Dialog */}
        {alertData?.alertStatus === "open" && (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog alert-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change alert status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="flex align-items-center gap-4">
                <div className="status capitalize">
                  {alertData.alertStatus}
                </div>

                <span className="pi pi-arrow-right"></span>
                <PR.InputText
                  id="status"
                  type="text"
                  aria-describedby="status"
                  className="status-input"
                  value="Evaluating"
                />
              </div>
            </div>
          </PR.Dialog>
        )}

        {alertData?.alertStatus === "evaluating" && (
          <PR.Dialog
            visible={alertStatusVisible}
            footer={alertStatusFooter}
            className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
          >
            <div className="dialog-header">
              <div className="mb-5">
                <h2>Change alert status</h2>
              </div>
            </div>
            <div className="dialog-body-section">
              <div className="role-grid align-items-center gap-4">
                <div className="status capitalize">
                  {alertData?.alertStatus}
                </div>
                <span className="pi pi-arrow-right"></span>
                <PR.Dropdown
                  value={statusDropdown}
                  onChange={(e) => setStatusDropdown(e.value)}
                  options={statusValues}
                  optionLabel="label"
                  className="multi-select-dropdown"
                  placeholder={"Change Alert Status"}
                />
              </div>
              {(["notreported", "suspicious", null].includes(statusDropdown) ||
                alertData.ibiaAdvice) && (
                <>
                  <div className="flex flex-column gap-2 mt-4">
                    <label htmlFor="description">Advice issued</label>
                    <div className="card1">
                      <PR.Editor
                        placeholder="Explain in detail.."
                        value={ibiaAdvice}
                        onTextChange={(e) => {
                            const htmlValue = e.htmlValue;
                            if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                              setErrorMessage('Enter valid text');
                            } else if (getPlainText(htmlValue) > descriptionCount) {
                                 setIbiaAdvice(htmlValue);
                                setErrorMessage(`Advice should be ${descriptionCount} characters or less.`);
                            } else {
                              setIbiaAdvice(htmlValue);
                                setErrorMessage("");
                            }
                        }}
                        headerTemplate={textEditorHeader}
                        style={{ height: "200px" }}
                      />  
                      <div className="descriptioncharLimit descriptionGrid mt-2">
                        <span style={{ textAlign: "left" }}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                        {remainingCharacters(descriptionCount, ibiaAdvice)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column gap-2 mt-4">
                    <label htmlFor="attachments">Attach file</label>
                    <div className="card create-file-upload flex align-items-center">
                      <PR.FileUpload
                        auto
                        mode="advanced"
                        name="attachments"
                        accept=".pdf,.docx,.xlsx"
                        chooseLabel="Choose Files"
                        multiple
                        customUpload
                        ref={fileInputRef}
                        uploadHandler={handleFileUpload}
                      />
                      <span className="ml-2">
                        (Add file less than {fileUploadLimit.label})
                      </span>
                    </div>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {uploadedFiles?.map((file, index) => (
                        <div className="uploaded-file-container">
                          <li key={index} className="uploaded-files new-file">
                            {file.name}
                            <i
                              className="pi pi-trash"
                              onClick={() => handleDeleteFile(index)}
                            />
                          </li>
                        </div>
                      ))}
                      {editedFiles?.map((file, index) => (
                        <div className="uploaded-file-container flex flex-column gap-2">
                          <div className="uploaded-files" key={index}>
                            {file.name}
                            <i
                              className="pi pi-trash"
                              onClick={() => handleDeleteExistingFile(index)}
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                            />
                          </div>
                        </div>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </PR.Dialog>
        )}

        {alertData.caseStatus === "under investigation" && (
            <PR.Dialog
              visible={alertStatusVisible}
              footer={alertStatusFooter}
              className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
            >
              <div className="dialog-header">
                <div className="mb-5">
                  <h2>Change case status</h2>
                </div>
              </div>
              <div className="dialog-body-section">
                <div className="role-grid align-items-center gap-4">
                  <div className="status capitalize">
                    { alertData?.caseStatus }
                  </div>
                  <span className="pi pi-arrow-right"></span>
                  <PR.Dropdown
                    value={statusDropdown}
                    onChange={(e) => setStatusDropdown(e.value)}
                    options={statusValues}
                    optionLabel="label"
                    className="multi-select-dropdown"
                    placeholder={"Change Case Status"}
                  />
                </div>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="description">Notes</label>
                  <div className="card1">
                    <PR.Editor
                        placeholder="Explain in detail.."
                        value={notes}
                        onTextChange={(e) => {
                            const htmlValue = e.htmlValue;
                            if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                              setErrorMessage('Enter valid text');
                            } else if (getPlainText(htmlValue) > descriptionCount) {
                              setNotes(htmlValue);
                              setErrorMessage(`Notes should be ${descriptionCount} characters or less.`);
                            } else {
                              setNotes(htmlValue);
                              setErrorMessage("");
                            }
                        }}
                        headerTemplate={textEditorHeader}
                        style={{ height: "200px" }}
                      />  
                      <div className="descriptionGrid descriptioncharLimit mt-2">
                        <span style={{ textAlign: "left" }}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                        {remainingCharacters(descriptionCount, notes)}
                      </div>
                  </div>
                </div>
                {["sanctioned", "closed"].includes(statusDropdown) && (
                  <>
                    <label className="mt-2 mb-2 block">
                      Send email notification to
                    </label>
                    <PR.Dropdown
                      value={emailOption}
                      onChange={(e) => setEmailOption(e.value)}
                      options={emailOptions}
                      optionLabel="label"
                      className="multi-select-dropdown"
                    />
                  </>
                )}
              </div>
            </PR.Dialog>
          )}

        {(alertData.caseStatus === "sanctioned" || alertData.caseStatus === "closed") && (
            <PR.Dialog
              visible={alertStatusVisible}
              footer={alertStatusFooter}
              className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
            >
              <div className="dialog-header">
                <div className="mb-5">
                  <h2>Change case status</h2>
                </div>
              </div>
              <div className="dialog-body-section">
                <div className="role-grid align-items-center gap-4">
                  <div className="status capitalize">
                    {alertData?.caseStatus}
                  </div>
                  <span className="pi pi-arrow-right"></span>
                  <PR.InputText
                    id="suspicious"
                    type="text"
                    aria-describedby="status"
                    className="status-input"
                    value="Under Investigation"
                  />
                </div>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="description">Notes</label>
                  <div className="card1">
                    <PR.Editor
                        placeholder="Explain in detail.."
                        value={notes}
                        onTextChange={(e) => {
                            const htmlValue = e.htmlValue;
                            if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                              setErrorMessage('Enter valid text');
                            } else if (getPlainText(htmlValue) > descriptionCount) {
                              setNotes(htmlValue);
                              setErrorMessage(`Notes should be ${descriptionCount} characters or less.`);
                            } else {
                              setNotes(htmlValue);
                              setErrorMessage("");
                            }
                        }}
                        headerTemplate={textEditorHeader}
                        style={{ height: "200px" }}
                      />  
                      <div className="descriptionGrid descriptioncharLimit mt-2">
                        <span style={{ textAlign: "left" }}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                        {remainingCharacters(descriptionCount, notes)}
                      </div>
                  </div>
                </div>
                {["sanctioned", "closed"].includes(statusDropdown) && (
                  <>
                    <label className="mt-2 mb-2 block">
                      Send email notification to
                    </label>
                    <PR.Dropdown
                      value={emailOption}
                      onChange={(e) => setEmailOption(e.value)}
                      options={emailOptions}
                      optionLabel="label"
                      className="multi-select-dropdown"
                    />
                  </>
                )}
              </div>
            </PR.Dialog>
          )}  

        {alertData.alertStatus === "not reported" &&
          alertData.caseStatus === "no further action" && (
            <PR.Dialog
              visible={alertStatusVisible}
              footer={alertStatusFooter}
              className="logout-dialog response-dialog tags-dialog evaluating-status-dialog"
            >
              <div className="dialog-header">
                <div className="mb-5">
                  <h2>Change case status</h2>
                </div>
              </div>
              <div className="dialog-body-section">
                <div className="role-grid align-items-center gap-4">
                  <div className="status capitalize">
                    {alertData?.alertStatus}
                  </div>
                  <span className="pi pi-arrow-right"></span>
                  <PR.InputText
                    id="status"
                    type="text"
                    aria-describedby="status"
                    className="status-input"
                    value=" Back to Evaluating"
                  />
                </div>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="description">Notes</label>
                  <div className="card1">
                      <PR.Editor
                        placeholder="Explain in detail.."
                        value={notes}
                        onTextChange={(e) => {
                            const htmlValue = e.htmlValue;
                            if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                              setErrorMessage('Enter valid text');
                            } else if (getPlainText(htmlValue) > descriptionCount) {
                              setNotes(htmlValue);
                              setErrorMessage(`Notes should be ${descriptionCount} characters or less.`);
                            } else {
                              setNotes(htmlValue);
                              setErrorMessage("");
                            }
                        }}
                        headerTemplate={textEditorHeader}
                        style={{ height: "200px" }}
                      />  
                      <div className="descriptionGrid descriptioncharLimit mt-2">
                        <span style={{ textAlign: "left" }}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                        {remainingCharacters(descriptionCount, notes)}
                      </div>
                  </div>
                </div>
              </div>
            </PR.Dialog>
          )}
        {/* Change assignee - Dialog */}
        <PR.Dialog
          visible={assigneeVisible}
          footer={changeAssigneeFooter}
          className="logout-dialog response-dialog tags-dialog alert-status-dialog"
          blockScroll="true"
        >
          <div className="dialog-header">
            <div className="mb-5">
              <h2>Change assignee</h2>
            </div>
          </div>
          <div className="dialog-body-section">
            <div className="assignee-flex align-items-center gap-4">
              {alertData?.assignee && (
                <>
                  <div className="status ">{alertData?.assignee}</div>
                  <span className="pi pi-arrow-right"></span>
                </>
              )}
              <PR.Dropdown
                value={assigneeDropdown}
                onChange={(e) => setAssigneeDropdown(e.value)}
                options={assigneeNames}
                optionLabel="name"
                className="multi-select-dropdown"
                placeholder="Select Assignee"
              />
            </div>
          </div>
        </PR.Dialog>

        {/* Delete Comment Dialog */}
        <PR.Dialog visible={deleteCommentVisible} footer={footerDelete} onHide={() => setDeleteCommentVisible(false)} className="logout-dialog response-dialog tags-dialog">
          <div className="dialog-header">
            <div className='gap-2'>
              <h2>Delete Comment</h2>
              <p>Are you sure you want to delete this comment?</p>
            </div>
          </div>
        </PR.Dialog>
      </Layout>
    </>
  );
};

export default AlertDetails;
