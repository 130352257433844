import { useState, useEffect, useMemo } from "react";
import * as PR from "../prime-react/index";
import { getFileDetails } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { profileIcon } from "../assets/images";
import { authActions } from "../store/auth";

const ProfileImageDisplay = ({ profileImage }) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const userImage = useSelector(state => state.auth.userImage);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [displayImage, setDisplayImage] = useState(null);

    const blobKey = userImage;

    useEffect(() => {
        if(blobKey && blobKey !== null){
            setDisplayImage(URL.createObjectURL(blobKey));
        }else if (!blobKey && profileImage) {
            if (profileImage?.key && (profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" || profileImage.type === "image/png")) {
                getFileDetails(profileImage.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setDisplayImage(URL.createObjectURL(response?.data));
                        dispatch(authActions.storeUserImage(response?.data))
                        
                    }
                });
            } else {
                setDisplayImage(`${profileIcon}?v=${new Date().getTime()}`)
            }
        } else {
            setDisplayImage(`${profileIcon}?v=${new Date().getTime()}`)
        }
    }, [blobKey, dispatch, headers, profileImage]);

    return (
        displayImage && (<PR.Image
            src={displayImage}
            className="group-name-icon"
        />)
    );
};

export default ProfileImageDisplay;
