import { createSlice } from "@reduxjs/toolkit";

const initialNewsState = {
   activeIndex: 0,
}
const newsSlice = createSlice({
    name: 'news',
    initialState: initialNewsState,
    reducers: {
        updateTab(state, action){
            state.activeIndex = action.payload.activeIndex
        }
    }
})

export const newsActions = newsSlice.actions;
export default newsSlice.reducer;