import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from "../../store/auth";
import * as PR from "../../prime-react/index";
import "./Login.scss";
import { logo } from "../../assets/images";
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../services/api';
import Footer from "../../layouts/Footer";

const Login = () => {
    const dispatch = useDispatch();
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();
    const authInfo = useSelector(state => state?.auth?.authInfo);

    const initialValues = {
        email: authInfo?.email ? authInfo.email : '',
        password: authInfo?.password ?  authInfo.password : '',
        remember: authInfo?.remember ? true : false,
    }

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().required('Username is required'),
            password: Yup.string().required('Password is required')
        })
    }

    const handleSubmit = formData => {
        const getLoginResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data?.passKey) {
                    dispatch(authActions.onLogin(response.data));         
                    dispatch(authActions.onRemember(formData));         
                    navigate('/otp');
                } else {
                    setErrMsg('Reference key is missing');
                }
            } else {
                const error = response.data
                setErrMsg(error);            
                setTimeout(() => {
                    setErrMsg('')
                }, 4000)
            }
        }
        login(formData, getLoginResponse)
    }

    // Initializing useFormik hook
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
    <>
        <section className="login-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="login-wrapper flex align-items-center justify-content-center">
                        <div className="login-info">
                            <PR.Image src={logo} alt="IBIA Logo" className="logo-img" />
                            <h1>Sign In</h1>
                            <p>Welcome back! Please enter your details.</p>
                            {/* Form section starts here */}
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                <div className='grid'>
                                    <div className="col-12 md:col-12">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="email">Username</label>
                                            <PR.InputText id="email" type="text" aria-describedby="email" className="w-12" placeholder="Enter username" autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                            {formik.touched.email && formik.errors.email ? (
                                                    <div className="error-message">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="password">Password</label>
                                            <PR.Password feedback={false} inputId="password" aria-describedby="password" name="password" placeholder="Enter password" className="w-12" toggleMask autoComplete="off" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="error-message">{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {errMsg && errMsg !== '' &&
                                        <div className="card pl-2">
                                            <div className='error'>{errMsg}</div>
                                        </div>
                                    }
                                    <div className="col-12 md:col-12 mt-2">
                                        <div className="flex align-items-center">
                                            <PR.Checkbox name="remember" value={formik.values.remember} inputId="remember" checked={formik.values.remember} onChange={formik.handleChange}></PR.Checkbox>
                                            <label htmlFor="remember" className="ml-2 font-medium">Remember me</label>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <PR.Button label="Send" type='submit' className="signin-button w-12"/>
                                    </div>
                                    <div className="col-12">
                                        <p className="text-center link-p"><Link to="/forgot-password">Can't access your account?</Link></p>
                                    </div>
                                </div>
                            </form>
                             {/* Form section ends here */}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </section>
    </>
  );
};

export default Login;
