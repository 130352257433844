import React, { useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import * as PR from '../../prime-react/index';
import { DateFormat, dompurifyText, formatTime } from '../../utils/reuse';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReport, getFileDetails, getReportById } from '../../services/api';
import "./Reports.scss";
import { ErrorMessage, useToast } from '../../context/ToastContext';
import { getImageForReport } from '../../utils/reportImages';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { generateScreenContentPDF } from '../../utils/downloadPageContent';

const ReportDetail = () => {
    const { showToast, clearError } = useToast();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(search);
    const reportID = queryParams.get('id');
    const [report, setReport] = useState(null);
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const isAnalyst = useSelector(state => state.auth.role) === 'analyst';
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';
    const deleteReportError = 'delete_report_error';
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        getReportById(reportID, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data;
                if (responseData && responseData?.length > 0) {
                    setReport(responseData[0]);
                } else {
                    navigate('/reports');
                }
            } else {
                navigate('/reports');
            }
        });
    }, [reportID, headers, dispatch, showToast, navigate]);

    if (!report) return <div className="loading"><i className="pi pi-spin pi-spinner"></i></div>;

    const fetchFileDetails = (key, fileName) => {
        const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
            try {
                const url = URL.createObjectURL(pdfData);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName || 'file.pdf');

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error converting base64 to PDF:', error);
            }
        };

        getFileDetails(key, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const fileData = response.data;
                convertBlobToPDFAndDownload(fileData, fileName);
            } else {
                const error = response?.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        });
    };

    const deleteReportHandler = (id) => {
        clearError(deleteReportError)
        deleteReport(id, headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                navigate("/reports");
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary, deleteReportError);
            }
        });
    };

    // Function to safely parse JSON
    const parseAttachments = (fileAttachment) => {
        try {
            return JSON.parse(fileAttachment || "[]");
        } catch (error) {
            showToast("error", "Attachments data is corrupted or missing.");
            return null;
        }
    };

    // Utility function to fetch and add files to the ZIP
    const addFileToZip = async (zip, key, fileName) => {
        return new Promise((resolve, reject) => {
            getFileDetails(key, headers, dispatch, (response) => {
                if (response.result === "SUCCESS") {
                    zip.file(fileName, response.data);
                    resolve();
                } else {
                    const error = response?.error;
                    showToast(error.severity, error?.errorMsg || error?.summary);
                    reject(new Error(`Failed to fetch file: ${fileName}`));
                }
            });
        });
    };

    const handleDownload = async () => {
        setIsDownloading(true);
        const zip = new JSZip();
        // Parse file attachments
        const attachments = parseAttachments(report?.fileAttachment);
        if (!attachments) {
            setIsDownloading(false);
            return;
        }
        try {
            // Add all attachments to the ZIP
            for (const { key, name } of attachments) { 
                await addFileToZip(zip, key, name);
            }
            // Add the generated PDF to the ZIP
            const pdfBlob = await generateScreenContentPDF(report);
            zip.file(`${report.title.replace(/ /g, "_")}.pdf`, pdfBlob);
            // Generate the ZIP file and trigger download
            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, `${report.title.replace(/ /g, "_")}_attachments.zip`);
        } catch (error) {
            showToast("error", "Error during ZIP creation or file fetching:", error);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <Layout backBtn={'reports'} backBtnLabel="Reports">
            <section className="news-tab news-details-section general-news-section reports-section">
                <div className="flex align-items-center justify-content-between mb-4">
                    <div className="left">
                        <h1 className='capitalize'>{report?.title}</h1>
                    </div>
                    {isAnalyst &&
                        <div className="right flex gap-2">
                            <PR.Button
                                label="Edit"
                                icon="pi pi-pencil"
                                className="action-buttons"
                                onClick={() =>
                                    navigate(`/edit-report?id=${reportID}`)
                                }
                            />
                            <PR.Button
                                label="Delete"
                                icon="pi pi-trash"
                                className="action-buttons"
                                onClick={() => setShowDeleteDialog(true)}
                            />
                        </div>
                    }
                </div>
                <div className="grid" id="pdfContent">
                    <div className="col-12">
                        <div className="new-list-section">
                            <div className="list-wrapper">
                                <div className="news-list preview-report" id="report-content">
                                    <div className="relative">
                                        <PR.Image src={getImageForReport(report)} alt={report?.title} className="report-img" />
                                        <div className="overlay">
                                            <h2>{report?.type}</h2>
                                        </div>
                                    </div>
                                    <div className="attachments report-attachments flex align-items-start justify-content-between mt-3">
                                        <div className="flex flex-wrap gap-2">
                                            {report?.fileAttachment && (
                                                JSON.parse(report?.fileAttachment)?.map((file) => (
                                                    <PR.Button
                                                        key={file.key}
                                                        icon="pi pi-book"
                                                        label={file.name}
                                                        className="download-button hide-on-download"
                                                        onClick={() => fetchFileDetails(file.key, file.name)}
                                                    />
                                                ))
                                            )}
                                        </div>
                                        <div className="flex">
                                            <PR.Button
                                                icon={isDownloading ? "pi pi-spin pi-spinner" : "pi pi-download"}
                                                label={isDownloading ? "Downloading..." : "Download Report"}
                                                className={`${isRegulator && 'regulator'} save-button hide-on-download`}
                                                onClick={handleDownload}
                                                disabled={isDownloading}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 p-0 mt-3">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: dompurifyText(report?.description) }} />
                                        <div className="attachments flex gap-3 mt-3">
                                            <span className="type-info">Report Type: {report?.reportType}</span>
                                            {report?.sportName && <span className="type-info">Sport: {report?.sportName}</span>}
                                        </div>
                                        <div className="card mt-5">
                                            <ul className="list-none gap-4 flex align-items-center justify-content-between mb-0">
                                                <li><i className="pi pi-calendar"></i> <span className="author">{DateFormat(report?.createdAt)} {formatTime(report?.createdAt)}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PR.Dialog
                visible={showDeleteDialog}
                header="Delete report"
                modal
                closable={false}
                footer={
                    <>
                        <ErrorMessage id={deleteReportError} />
                        <PR.Button
                            label="Cancel"
                            className="p-button-text custom-cancel-button"
                            onClick={() => { clearError(deleteReportError); setShowDeleteDialog(false) }}
                        />
                        <PR.Button
                            label="Delete"
                            className="p-button-danger custom-delete-button"
                            onClick={() => deleteReportHandler(reportID)}
                        />
                    </>
                }
            >
                <div className="confirmation-content">
                    <span>Are you sure you want to delete this report?</span>
                </div>
            </PR.Dialog>

        </Layout>
    );
};

export default ReportDetail;
