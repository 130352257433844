import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as PR from "../../prime-react/index";
import {
  getGroups,
  getPlayers,
  getSports,
  getLocations,
  getTournament,
  getGroupsbyRole,
  getTournamentLevels,
  geteSportsTitles
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { formatShortDate, getRoleIds, queryLimit } from "../../utils/reuse";
import { useLocation } from "react-router-dom";
import { filterOptionsActions } from "../../store/FiltersOptions";
const SearchFilter = ({
  alertsActiveIndex,
  setFilters,
  staticOptionData,
  setFilterDialogVisible,
  selectedFilters
}) => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const location = useLocation();

  const [checkedReportTypes, setCheckedReportTypes] = useState({});
  const [checkedReceivers, setCheckedReceivers] = useState({});
  const [receivers, setReceivers] = useState([]);
  const [receiverSearch, setReceiverSearch] = useState("");
  const [filteredReceivers, setFilteredReceivers] = useState([]);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const filterOptions = useSelector((state) => state.filterOptions);
  const role = sessionData?.role;
  const doesReportScreen = location.pathname?.includes('reports');
  const isCompanyTabDisabled = ((role === "member" || role === "managedmember")&& pathname === "/alerts" && alertsActiveIndex === 3)

  const [error, setError] = useState(null)
  const [disableeSportsTitles, setDisableeSportsTitles] = useState(true);
  const [disableTournamentLevel, setDisableTournamentLevel] = useState(true);
  const [keywords, setKeywords] = useState(selectedFilters?.keyword??'');

    /** Sports */
  const [sports, setSports] = useState([]);
  const [filteredSports, setFilteredSports] = useState([]);
  const [checkedSports, setCheckedSports] = useState({});
  const [sportIds, setSportIds] = useState([]);

   /** eSports Titles */
   const [eSportsTitleList, seteSportsTitleList] = useState([]);
   const [eSportTitleSearch, setESportTitleSearch] = useState("");
   const [filteredeSportsTitles, setFilteredeSportsTitles] = useState([]);
   const [checkedeSportsTitles, setCheckedeSportsTitles] = useState({});

  /** Date */
  const [date, setDate] = useState([new Date(), new Date()]);
  const [dateRange, setDateRange] = useState("");
  /** Event Date */
  const [eventDate, setEventDate] = useState([new Date(), new Date()]);
  const [eventDateRange, setEventDateRange] = useState("");

   /** Location */
  const [locations, setLocations] = useState([]);
  const [searchSports, setSearchSports] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredCustomerLocations, setFilteredCustomerLocations] = useState([]);
  const [locatinSearch, setLocationSearch] = useState("");
  const [customerLocationSearch, setCustomerLocationSearch] = useState("");
  const [checkedLocations, setCheckedLocations] = useState({});
  const [checkedCustomerLocations, setCheckedCustomerLocations] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const headers = useMemo(() => {
    return { sessionid: sessionData.sessionId };
  }, [sessionData.sessionId]);

  useEffect(() => {
    const selectedSportIds = selectedFilters?.sports?.map((sport) => sport.id);
    setSportIds(selectedSportIds);
    selectedFilters?.combinedStatus?.forEach(({ id }) => {
      setCheckedStatus((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tags?.forEach(({ id }) => {
      setCheckedPlayers((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    selectedFilters?.sports?.forEach(({ id }) => {
      setCheckedSports((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.eSportsTitles?.forEach(({ id }) => {
      setCheckedeSportsTitles((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tournamentLevels?.forEach(({ id }) => {
      setCheckedTournamentLevels((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.alertType?.forEach(({ id }) => {
      setCheckedAlerts((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.company?.forEach(({ id }) => {
      setCheckedCompanies((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.responseType?.forEach(({ id }) => {
      setCheckedResponses((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    if(selectedFilters?.date?.date?.length){
      setDate(selectedFilters?.date?.date);
    }
    if(selectedFilters?.date?.dateRange){
      setDateRange(selectedFilters?.date?.dateRange);
    }
    if(selectedFilters?.eventDate?.date?.length){
      setEventDate(selectedFilters?.eventDate?.date);
    }
    if(selectedFilters?.eventDate?.dateRange){
      setEventDateRange(selectedFilters?.eventDate?.dateRange);
    }

    selectedFilters?.gender?.forEach(({ id }) => {
      setCheckedGenders((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tournament?.forEach(({ id }) => {
      setCheckedTournaments((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.location?.forEach(({ id }) => {
      setCheckedLocations((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    selectedFilters?.customerLocations?.forEach(({ id }) => {
      setCheckedCustomerLocations((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    selectedFilters?.channel?.forEach(({ id }) => {
      setCheckedChannel((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.timeOfBet?.forEach(({ id }) => {
      setCheckedTimeOfBet((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.stateOfBet?.forEach(({ id }) => {
      setCheckedStateOfBet((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.timeOfAlert?.forEach(({ id }) => {
      setCheckedAlertsRaised((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    if (selectedFilters?.maxBets?.length > 0) {
      setCheckedMaxBet(selectedFilters?.maxBets[0].id);
    }

    selectedFilters?.reportType?.forEach(({ id }) => {
      setCheckedReportTypes((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.receiver?.forEach(({ id }) => {
      setCheckedReceivers((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
  }, [selectedFilters]);

  // Listing all the selected report type values
  const getSelectedReportTypes = () => {
    return staticOptionData?.reportTypeFilter?.filter(
      (item) => checkedReportTypes[item.id]
    );
  };

  /** End Report Type */

  // Listing all the selected receiver values
  const getSelectedReceivers = () => {
    return receivers?.filter((item) => checkedReceivers[item.id]);
  };


  /** End Receiver */

  /** Status */
  const getStatusOptions = () => {
    if ((role === "analyst" && pathname === "/suspicious-alerts") ||
      // in allalerts screen when navigated to Suspicious alerts tab : alertsActiveIndex === 1
      ((role === "member" || role === "managedmember") && pathname === "/alerts" && alertsActiveIndex === 1)) {
      return staticOptionData.combinedStatus.filter((status) => {
        return (status.id.includes("underinvestigation") || status.id.includes("closed") || status.id.includes("sanctioned"))
      })
      // in allalerts screen when navigated to Required-action tab : alertsActiveIndex === 2
    } else if (role === "member" && pathname === "/alerts" && alertsActiveIndex === 2) {
      return staticOptionData.combinedStatus.filter((status) => {
        return !(status.id.includes("-"))
      })
    } else {
      return staticOptionData.combinedStatus
    }
  }
  const [status] = useState(getStatusOptions());
  const [checkedStatus, setCheckedStatus] = useState({});

  // Listing all the selected status values
  const getSelectedStatus = () => {
    return status?.filter((item) => checkedStatus[item.id]);
  };

  /** End Status */

  /** Player/ Team */
  const [playerNames, setPlayerNames] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerSearch, setPlayerSearch] = useState("");
  const [checkedPlayers, setCheckedPlayers] = useState({});

  // Get list of all the Players
  const getPlayerOrTeam = useCallback(() => {
    if(filterOptions.playerOrTermNameOptions?.length === 0){
      setLoading(true);
      getPlayers('', headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const Players = responseData;
          const result = Players?.length > 0 ? Players?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          dispatch(filterOptionsActions.storeFilterOptions({key: 'playerOrTermNameOptions', options: result}));
          setPlayerNames(result);
          setFilteredPlayers(result);
        } else {
          setPlayerNames([]);
        }
        setLoading(false);
      });
    } else {
      setPlayerNames(filterOptions.playerOrTermNameOptions);
      setFilteredPlayers(filterOptions.playerOrTermNameOptions);
    }
  }, [dispatch, filterOptions.playerOrTermNameOptions, headers]);

  // Listing all the selected status values
  const getSelectedPlayers = () => {
    return playerNames.filter((item) => checkedPlayers[item.id]);
  };

  /** End Player/ Team */

  /** Sports */

  const getSportsHandler = useCallback(() => {
    if(filterOptions.sportFilterOptions?.length === 0 || (filterOptions.sportActiveIndex !== alertsActiveIndex)){
    setLoading(true);
    getSports(alertsActiveIndex, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const sports = responseData;
        const result = sports?.length > 0 ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setSports(result);
        setFilteredSports(result);
        dispatch(filterOptionsActions.storeFilterOptions({ key: 'sportFilterOptions', options: result}))
        dispatch(filterOptionsActions.storeFilterOptions({ key: 'sportActiveIndex', options: alertsActiveIndex }));
      } else {
        setSports([]);
        setFilteredSports([])
      }
      setLoading(false);
    });
    } else {
      setSports(filterOptions.sportFilterOptions);
      setFilteredSports(filterOptions.sportFilterOptions);
    }
  }, [alertsActiveIndex, dispatch, filterOptions.sportActiveIndex, filterOptions.sportFilterOptions, headers]);

  // Checkbox handler to list all the selected values
  const sportFilterHandler = ({ id, checked }) => {
    const selectedSportIds = sports
      .filter((item) => {
        if (id === item.id) {
          return checked;
        }
        return checkedSports[item.id];
      })
      .map((item) => item.id);
    setSportIds(selectedSportIds);
    const sportNames = sports?.filter(s => selectedSportIds.includes(s.id)).map(s => s.name);
    if (!sportNames?.includes('Tennis')) {
      setCheckedTournamentLevels({})
      setFilteredTournamentLevels([]);
    }
  }
  // Listing all the selected sport values
  const getSelectedSports = () => {
    return sports.filter((item) => checkedSports[item.id]);
  };

  /** End Sports Team */


  /** eSports Titles */

  // Get list of all the eSports Titles
  const geteSportsTitlesHandler = useCallback(() => {
    if (filterOptions.eSportTitleOptions?.length === 0) {
      geteSportsTitles(headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const eSportsTitles = responseData;
          const result = eSportsTitles?.length > 0 ? eSportsTitles?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          dispatch(filterOptionsActions.storeFilterOptions({ key: 'eSportTitleOptions', options: result }))
          seteSportsTitleList(result);
          setFilteredeSportsTitles(result);
        } else {
          seteSportsTitleList([]);
          setFilteredeSportsTitles([]);
        }
      });
    } else {
      seteSportsTitleList(filterOptions.eSportTitleOptions);
      setFilteredeSportsTitles(filterOptions.eSportTitleOptions);
    }
  }, [dispatch, filterOptions.eSportTitleOptions, headers]);

  // Listing all the selected eSports Titles values
  const getSelectedeSportsTitles = () => {
    return eSportsTitleList?.filter((item) => checkedeSportsTitles[item.id]);
  };

  /** End eSports Titles */

    /** Tournment Levels */
    const [tournamentLevelsList, setTournamentLevelsList] = useState([]);
    const [filteredTournamentLevels, setFilteredTournamentLevels] = useState([]);
    const [tournmentSearch, setTournmentSearch] = useState("");
    const [checkedTournamentLevels, setCheckedTournamentLevels] = useState({});
  
    // Get list of all the tournaments
    const getTournamentLevelsHandler = useCallback(() => {
      if (filterOptions.tournmentLevelOptions?.length === 0 || (filterOptions.alertsActiveIndex !== alertsActiveIndex)) {
        getTournamentLevels(alertsActiveIndex, headers, dispatch, (response) => {
          if (response.result === "SUCCESS") {
            const responseData = response.data;
            const tournamentLevels = responseData;
            const result = tournamentLevels?.length > 0 ? tournamentLevels?.sort((a, b) => a.name.localeCompare(b.name)) : [];
            dispatch(filterOptionsActions.storeFilterOptions({ key: 'tournmentLevelOptions', options: result }));
            dispatch(filterOptionsActions.storeFilterOptions({ key: 'alertsActiveIndex', options: alertsActiveIndex }));
            setTournamentLevelsList(result);
            setFilteredTournamentLevels(result);
          } else {
            setTournamentLevelsList([]);
            setTournamentLevelsList([]);
          }
        });
      } else {
        setTournamentLevelsList(filterOptions.tournmentLevelOptions);
        setFilteredTournamentLevels(filterOptions.tournmentLevelOptions);
      }
    }, [alertsActiveIndex, dispatch, filterOptions.alertsActiveIndex, filterOptions.tournmentLevelOptions, headers]);
  

    // Listing all the selected Tournament values
    const getSelectedTournamentLevels = () => {
      return tournamentLevelsList?.filter((item) => checkedTournamentLevels[item.id]);
    };
    /** End Tournment Levels */

  /** Alert Type */
  const [alertTypes] = useState(staticOptionData?.alertTypeFilter);
  const [checkedAlerts, setCheckedAlerts] = useState({});

  // Listing all the selected alert values
  const getSelectedAlerts = () => {
    return alertTypes?.filter((item) => checkedAlerts[item.id]);
  };
  /** End Alert Type */

  /** Response Type */
  const [responseTypes] = useState(staticOptionData?.responseTypeFilter?.filter(type => type.id !== 'all'));
  const [checkedResponses, setCheckedResponses] = useState({});

  // Listing all the selected response values
  const getSelectedResponses = () => {
    return responseTypes?.filter((item) => checkedResponses[item.id]);
  };
  /** End Response Type */


  /** Company Tab */
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [companySearch, setCompanySearch] = useState("");
  const [checkedCompanies, setCheckedCompanies] = useState({});
  // Get list of all the members
  const getCompany = useCallback(() => {
    if(filterOptions.companyFilterOptions?.length === 0){
      setLoading(true);
      getGroups(getRoleIds(sessionData.role), headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const members = responseData;
          const result = members?.length > 0 ? members?.sort((a, b) => a.name.localeCompare(b.name))  : [];
          dispatch(filterOptionsActions.storeFilterOptions({key: 'companyFilterOptions' , options: result}))
          setCompanies(result);
          setFilteredCompanies(result);
        } else {
          setCompanies([]);
        }
        setLoading(false);
      });
    } else {
      setCompanies(filterOptions.companyFilterOptions);
      setFilteredCompanies(filterOptions.companyFilterOptions);
    }
  }, [filterOptions.companyFilterOptions, sessionData.role, headers, dispatch]);

  // Listing all the selected response values
  const getSelectedCompanies = () => {
    return companies?.filter((item) => checkedCompanies[item.id]);
  };

  /** End Company */


  const handleDateRange = (e) => {
    setDate(e.value);
    if (e.value && e.value[0] !== null && e.value[1] === null) {
      let [startDate, endDate] = [e.value[0], e.value[0]]
      setDateRange(`${formatShortDate(startDate)} - ${formatShortDate(endDate)}`)
    } else if (e.value && e.value[0] !== null && e.value[1] !== null) {
      let [startDate, endDate] = e.value;
      setDateRange(
        `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`
      );
    }
  };
  const handleEventDateRange = (e) => {
    setEventDate(e.value);
    if (e.value && e.value[0] !== null && e.value[1] === null) {
      let [startDate, endDate] = [e.value[0], e.value[0]]
      setEventDateRange(`${formatShortDate(startDate)} - ${formatShortDate(endDate)}`)
    } else if (e.value && e.value[0] !== null && e.value[1] !== null) {
      let [startDate, endDate] = e.value;
      setEventDateRange(
        `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`
      );
    }

  };
  /** End Date */

  /** Tournament */
  const [tournamentsList, setTournamentList] = useState([]);
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [leagueSearch, setLeagueSearch] = useState("");
  const [checkedTournaments, setCheckedTournaments] = useState({});

  // Get list of all the tournaments
  const getTournamenthandler = useCallback(() => {
    const obj = {
      sportid: sportIds.join(","),
    };
    getTournament(obj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const tournaments = responseData;
        const result = tournaments?.length > 0 ? tournaments?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setTournamentList(result);
        setFilteredTournaments(result);
      } else {
        setTournamentList([]);
      }
    });
  }, [dispatch, headers, sportIds]);

  // Listing all the selected Tournament values
  const getSelectedTournaments = () => {
    return tournamentsList?.filter((item) => checkedTournaments[item.id]);
  };
  /** End Tournment */

  /** Gender */
  const [gender] = useState(staticOptionData?.gender);
  const [checkedGenders, setCheckedGenders] = useState({});


  // Listing all the selected gender values
  const getSelectedGenders = () => {
    return gender?.filter((item) => checkedGenders[item.id]);
  };
  /** End Gender */

 

  // Get list of all the locations
  const getLocationHandler = useCallback(() => {
    if(filterOptions.locationFilterOptions?.length === 0){
      setLoading(true);
      getLocations(headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const locations = responseData;
          const result = locations?.length > 0 ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          dispatch(filterOptionsActions.storeFilterOptions({key:'locationFilterOptions', options: result}))
          setLocations(result);
          setFilteredLocations(result);
          setFilteredCustomerLocations(result);
        } else {
          setLocations([]);
        }
        setLoading(false);
      });
    } else {
      setLocations(filterOptions.locationFilterOptions);
      setFilteredLocations(filterOptions.locationFilterOptions);
      setFilteredCustomerLocations(filterOptions.locationFilterOptions);
    }
  }, [dispatch, filterOptions.locationFilterOptions, headers]);

  // Listing all the selected response values
  const getSelectedLocations = () => {
    return locations?.filter((item) => checkedLocations[item.id]);
  };
  const getSelectedCustomerLocations = () => {
    return locations?.filter((item) => checkedCustomerLocations[item.id]);
  };

  /** End Location */

  /** Channel */
  const [channel] = useState(staticOptionData?.channels);
  const [checkedChannel, setCheckedChannel] = useState({});

  // Listing all the selected channel values
  const getSelectedChannels = () => {
    return channel?.filter((item) => checkedChannel[item.id]);
  };

  /** End Channel */

  /** Time of Bet */
  const [timeOfBet] = useState(staticOptionData?.timeOfBet);
  const [checkedTimeOfBet, setCheckedTimeOfBet] = useState({});

  // Listing all the selected timeOfBet values
  const getSelectedTimeOfBet = () => {
    return timeOfBet?.filter((item) => checkedTimeOfBet[item.id]);
  };


  /** End Time of Bet */

  /** State of Bet */
  const [stateOfBet] = useState(staticOptionData?.stateOfBetsFilter);
  const [checkedStateOfBet, setCheckedStateOfBet] = useState({});


  // Listing all the selected State of Bet values
  const getSelectedStateOfBets = () => {
    return stateOfBet?.filter((item) => checkedStateOfBet[item.id]);
  };

  /** End State of Bet */

  /** Max Bet */
  const [maxBet] = useState(staticOptionData?.maxBet);
  const [checkedMaxBet, setCheckedMaxBet] = useState("");

  // Checkbox handler to list all the selected values
  const maxBetRadioGroupHandler = (value) => {
    setCheckedMaxBet(value);
  };

  // Listing all the selected Max Bet values
  const getSelectedMaxBets = () => {
    return maxBet?.filter((item) => item.id === checkedMaxBet);
  };


  /** End Max Bet */

  /** Alert raised */
  const [alertsRaised] = useState(staticOptionData?.alertsRaised);
  const [checkedAlertsRaised, setCheckedAlertsRaised] = useState({});

  // Listing all the selected Alert Raised values
  const getSelectedAlertsRaised = () => {
    return alertsRaised?.filter((item) => checkedAlertsRaised[item.id]);
  };


  /** End Alert raised */

  // CombinedGroupbyroleId
  const GOVERNING_BODIES_ROLE = 2; // governing bodies
  const REGULATORS_ROLE = 3; // regulators

  const getGroup = useCallback(() => {
    if (filterOptions.receiverFilterOptions?.length === 0) {
      setLoading(true);
      getGroupsbyRole(GOVERNING_BODIES_ROLE, REGULATORS_ROLE, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const members = responseData;
          const result = members?.length > 0 ? members?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          dispatch(filterOptionsActions.storeFilterOptions({ key: 'receiverFilterOptions', options: result }));
          setReceivers(result);
          setFilteredReceivers(result);
        } else {
          setFilteredReceivers([]);
        }
        setLoading(false);
      });
    } else {
      setReceivers(filterOptions.receiverFilterOptions);
      setFilteredReceivers(filterOptions.receiverFilterOptions);
    }
  }, [dispatch, filterOptions.receiverFilterOptions, headers]);

  const formattedDate = (dateVal) => {
    if (dateVal === undefined || dateVal.length === 0) {
      return []
    } else if (dateVal[1] === null && dateVal[0] !== null) {
      return [dateVal[0], dateVal[0]]
    } else if (dateVal[1] !== null && dateVal[0] !== null) {
      return dateVal
    }
    return [];
  }

  const getAlertStatus = () => {
    const alertStatuses = getSelectedStatus().map(item => {
      return item.id.split('-')[0]
    })
    const statusSet = [...new Set(alertStatuses)]
    const statusFiltered = statusSet.filter(item => item !== "")

    return statusFiltered.map(item => {
      return { id: item }
    })
  }

  const getCaseStatus = () => {

    const caseStatuses = getSelectedStatus().map(item => {
      return item.id.split('-')[1]
    })

    const statusSet = [...new Set(caseStatuses)]
    const statusFiltered = statusSet.filter(element => element !== undefined);

    return statusFiltered.map(item => {
      return { id: item }
    })
  }
  const [locationError,setLocationError] = useState('');
  const applyFilters = () => {
    if (getSelectedSports()?.length && getSelectedLocations()?.length) {
      const sports =  getSelectedSports().map(s => s.name);
      const locations =  getSelectedLocations().map(s => s.name);
      if(!sports.includes('eSports') && locations.includes('No Country')){
        setLocationError('Invalid Search')
        setTimeout(() => {
          setLocationError('')
        }, 2000)
        return;
      }
    }
    if(doesReportScreen && getSelectedReceivers().length > 10){
      setLocationError('You can select a maximum of 10 receivers.')
      setTimeout(() => {
        setLocationError('')
      }, 2000)
      return;
    }
    const global = {
      combinedStatus: getSelectedStatus(),
      alertStatus: getAlertStatus(),
      caseStatus: getCaseStatus(),
      tags: getSelectedPlayers(),
      sports: getSelectedSports(),
      eSportsTitles: getSelectedeSportsTitles(),
      tournamentLevels: getSelectedTournamentLevels(),
      alertType: getSelectedAlerts(),
      responseType: getSelectedResponses(),
      company: getSelectedCompanies(),
      date: { date: dateRange ? formattedDate(date) : '', dateRange },
      eventDate: { date: eventDateRange ? formattedDate(eventDate) : '', dateRange: eventDateRange},
      tournament: getSelectedTournaments(),
      gender: getSelectedGenders(),
      location: getSelectedLocations(),
      customerLocations: getSelectedCustomerLocations(),
      reportType: getSelectedReportTypes(),
      receiver: getSelectedReceivers(),
      channel: getSelectedChannels(),
      timeOfBet: getSelectedTimeOfBet(),
      stateOfBet: getSelectedStateOfBets(),
      maxBets: getSelectedMaxBets(),
      timeOfAlert: getSelectedAlertsRaised(),
      keyword: keywords
    };
    setFilters(global);
    setFilterDialogVisible(false);
  };

  const othersTabFiltersSelected = () => {
    const filters = [
      getSelectedChannels(),
      getSelectedTimeOfBet(),
      getSelectedStateOfBets(),
      getSelectedMaxBets(),
      getSelectedAlertsRaised(),
    ];
    return filters?.some((filter) => filter?.length > 0);
  };
  const isPlayerVisible = useCallback(() => {
    if (role === "analyst") {
      if (doesReportScreen || (location.pathname === "/alerts")) {
        return false
      }
    }
    return true
  }, [doesReportScreen, location.pathname, role])

  const tabChangeHandler = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    getSportsHandler();
  }, [getSportsHandler])
  useEffect(() => {
    if (doesReportScreen) {
      getGroup();
    }
  }, [doesReportScreen, getGroup])
  useEffect(() => {
    if (!doesReportScreen && !isCompanyTabDisabled) {
      getCompany();
    }
  }, [doesReportScreen, getCompany, isCompanyTabDisabled])
  useEffect(() => {
    if (!doesReportScreen) {
      getLocationHandler();
    }
  }, [doesReportScreen, getLocationHandler])

  useEffect(() => {
    if (isPlayerVisible()) {
      getPlayerOrTeam();
    }
  }, [getPlayerOrTeam, isPlayerVisible]);

  useEffect(() => {
    const sportData = sportIds?.length ? sports.filter(s => sportIds?.includes(s.id)) : [];
    const sportNames = sportData.map(s => s.name);
    if (!doesReportScreen) {
      if (sportNames.includes('Tennis')) {
        setDisableTournamentLevel(false);
        getTournamentLevelsHandler();
      } else if(sports.length){
        setDisableTournamentLevel(true);
        setTournamentLevelsList([]);
        setCheckedTournamentLevels({})
      }

      if(sportNames.includes('eSports')) {
        setDisableeSportsTitles(false);
        geteSportsTitlesHandler();
      } else if(sports.length){
        setDisableeSportsTitles(true);
        seteSportsTitleList([]);
        setCheckedeSportsTitles({})
      }
    }
  }, [doesReportScreen, getTournamentLevelsHandler, geteSportsTitlesHandler, sportIds, sports]);

  useEffect(() => {
    if (!doesReportScreen) {
      if (sportIds?.length > 0) {
        getTournamenthandler();
      } else {
        setTournamentList([])
      }
    }
  }, [doesReportScreen, getTournamenthandler, sportIds]);


  const isStatusTabDisabled = (
    role === "analyst" &&
    (pathname === "/open-alerts" || pathname === "/evaluating-alerts")
  )
  //company search disabled for my-alerts

  const resetFilters = () => {
    setCheckedStatus({})
    setCheckedPlayers({})
    setCheckedSports({})
    setCheckedeSportsTitles({})
    setCheckedTournamentLevels({})
    setCheckedAlerts({})
    setCheckedResponses({})
    setCheckedCompanies({})
    setDate([new Date(), new Date()]);  
    setDateRange("");
    setEventDate([new Date(), new Date()]);
    setEventDateRange("");
    setCheckedTournaments({})
    setCheckedGenders({})
    setCheckedLocations({})
    setCheckedCustomerLocations({})
    setCheckedReportTypes({})
    setCheckedReceivers({})
    setCheckedChannel({})
    setCheckedTimeOfBet({})
    setCheckedStateOfBet({})
    setCheckedMaxBet('')
    setCheckedAlertsRaised({})
    setKeywords('')
    setDisableeSportsTitles(true);
    setDisableTournamentLevel(true);
  }
  const handleRemoveListFilter = (item, setter, type) => {
    setter((prevState) => {
      const updatedCheckedItems = { ...prevState };
      delete updatedCheckedItems[item.id]; // Assumes `item` has an `id` property
      return updatedCheckedItems;
    });
    if(type === 'sport'){
      sportFilterHandler({ id: item.id, checked: false })
    }
  };
  const handleRemoveItemFilter = (type) => {
    if(type === 'event'){
      setEventDateRange("");
      setEventDate([new Date(), new Date()]);
    } else if(type === 'date'){
      setDateRange("");
      setDate([new Date(), new Date()]);
    } else if (type === 'keyword'){
      setKeywords("")
    }
   
  };
  const handleCheckboxChange = (setter, type = '') => (e) => {
    const { value, checked } = e.target;
    setter((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
    if(type){
      sportFilterHandler({ id: value, checked })
    }
  };
  const handleSearch = (event, setSearch, dataList, setFilteredData) => {
    const searchedValue = event.target.value;
    setSearch(searchedValue);
    const filteredData =
      dataList &&
      dataList.filter((item) =>
        item.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
    setFilteredData(filteredData);
  };
  const buttonSection = () => {
    return (
      <div className="flex flex-wrap justify-content-end gap-3 border-top">

        <PR.Button
          label="Reset Filters"
          outlined
          autoFocus
          className="logout-btns reset-btn"
          onClick={resetFilters}
        />
        <PR.Button
          label="Cancel"
          onClick={() => setFilterDialogVisible(false)}
          outlined
          className="logout-btns"
        />
        <PR.Button
          label="Apply Filters"
          outlined
          autoFocus
          className={role === 'analyst' ? 'logout-btns click-btn color-update' : 'logout-btns click-btn'}
          onClick={applyFilters}
        />
      </div>
    )
  }


  const OtherFilterList = ({ items, label, setter, type }) => {
    if (!items?.length) return null;
    return (
      <ul>
        <li className="mr-2">{label}: </li>
        {items.map((item, index) => (
          <li key={index}>
            <span>
              {item.name}{" "}
              <i
                className="pi pi-times"
                onClick={() => !type ? handleRemoveListFilter(item, setter, type) : setter("")}
              ></i>
            </span>
          </li>
        ))}
      </ul>
    );
  }
  const FiltersList = ({ items, label, setter, type }) => {
    if (!items?.length) return null;
    return (
      <div className="filters-list mb-3">
        <ul>
          <li className="block">{label}: </li>
          {items.map((item, index) => (
            <li key={index}>
              <span>
                {item.name}{" "}
                <i
                  className="pi pi-times"
                  onClick={() => handleRemoveListFilter(item, setter, type)}
                ></i>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  const FilterItem = ({ label, value, handleRemove }) => {
    if (!value) return null;
  
    return (
      <div className="filters-list mb-3">
        <ul>
          <li className="block">{label}: </li>
          <li>
            <span>
              {value}
              <i
                className="pi pi-times"
                onClick={handleRemove}
              ></i>
            </span>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <div className="filter-dialog-body-section">
        <div className="grid grid-nogutter">
          <div className="col-9">
            <PR.TabView
              activeIndex={activeIndex}
              onTabChange={(e) => tabChangeHandler(e.index)}
            >
              {(<PR.TabPanel header="Keyword">
                <div className="flex filter-options-section">
                  <div className="filters-list">
                    <div className="mb-3">
                      <div className="flex align-items-center w-12 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="keywordSearch"
                            placeholder="Enter Keyword"
                            className="search-box"
                            value={keywords}
                            onChange={({ target: { value } }) => {
                              if (value.length <= queryLimit) {
                                setKeywords(value)
                              } else {
                                setError(`Keyword can be at most ${queryLimit} characters long`);
                                setTimeout(() => {
                                  setError(null)
                                }, 2500)
                              }
                            }}
                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!keywords && <i style={{ cursor: 'pointer', color: "#6c757d" }} onClick={() => setKeywords("")} className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      {error && <p className="error-message mt-2">{error}</p>}
                    </div>
                    <span> {!doesReportScreen ? 'This will search in Event Name, Description and Advice.' : 'This will search in Description.'}</span>
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>)}
              {(!doesReportScreen && !(role === "member" && pathname === "/alerts" && alertsActiveIndex === 2)) && (
                <PR.TabPanel header="Status" disabled={isStatusTabDisabled}>
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {status?.map((row, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              value={row.id}
                              inputId={row.id}
                              checked={checkedStatus[row.id]}
                              onChange={handleCheckboxChange(setCheckedStatus)}
                            ></PR.Checkbox>
                            <label
                              htmlFor={row.id}
                              className="ml-2 font-medium"
                            >
                              {row.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {isPlayerVisible() && (
                <PR.TabPanel header="Player / Team">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="playerSearch"
                            placeholder="Search"
                            className="search-box"
                            value={playerSearch}
                            autoComplete="off"
                            onChange={(event) => handleSearch(event, setPlayerSearch, playerNames, setFilteredPlayers)}
                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!playerSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setPlayerSearch, playerNames, setFilteredPlayers)}
                              className="pi pi-times text-sm mr-2" />
                          }
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredPlayers &&
                          filteredPlayers?.map((player, index) => {
                            return (
                              <div className="flex align-items-center status-box" key={index}>
                                <PR.Checkbox
                                  inputId={player.id}
                                  value={player.id}
                                  checked={checkedPlayers[player.id]}
                                  onChange={handleCheckboxChange(setCheckedPlayers)}
                                ></PR.Checkbox>
                                <label htmlFor={player.id} className="ml-2 font-medium">
                                  {player.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              <PR.TabPanel header="Sports">
                <div className="flex filter-options-section">
                  <div className="filters-list">
                    <div className="flex flex-wrap gap-3">
                      {loading ? (
                        <div>
                          <i className="pi pi-spin pi-spinner"></i>
                        </div>
                      ) : (<>
                        <div className="flex align-items-center w-12 mb-1 sticky-search">
                          <span className="p-input-icon-left w-12">
                            <i className="pi pi-search" />
                            <PR.InputText
                              id="sportSearch"
                              placeholder="Search"
                              className="search-box"
                              value={searchSports}
                              autoComplete="off"
                              onChange={(event) => handleSearch(event, setSearchSports, sports, setFilteredSports)}
                            />
                          </span>
                          <span className="search-clear-icon">
                            {!!searchSports && <i style={{ cursor: 'pointer', color: "#6c757d" }} 
                            onClick={() => handleSearch({ target: { value: "" } }, setSearchSports, sports, setFilteredSports)} 
                            className="pi pi-times text-sm mr-2" />}
                          </span>
                        </div>
                        {
                          filteredSports && filteredSports.map((sport, index) => (
                            <div className="flex align-items-center status-box" key={index} >
                              <PR.Checkbox
                                inputId={sport.id}
                                value={sport.id}
                                onChange={handleCheckboxChange(setCheckedSports, 'sport')}
                                checked={checkedSports[sport.id]}
                              ></PR.Checkbox>
                              <label htmlFor={sport.id} className="ml-2 font-medium">{sport.name}</label>
                            </div>
                          ))
                        }
                      </>
                      )}
                    </div>
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>

              {(!doesReportScreen) && (
                <PR.TabPanel
                  header="Esports Titles"
                  disabled={disableeSportsTitles}
                >
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="eSportsTitlesSearch"
                            placeholder="Search"
                            className="search-box"
                            value={eSportTitleSearch}
                            onChange={(event) => handleSearch(event, setESportTitleSearch, eSportsTitleList, setFilteredeSportsTitles)}
                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!eSportTitleSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setESportTitleSearch, eSportsTitleList, setFilteredeSportsTitles)}
                              className="pi pi-times text-sm mr-2" />
                          }
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredeSportsTitles &&
                          filteredeSportsTitles?.map((eSportsTitles, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={eSportsTitles.id}
                                value={eSportsTitles.id}
                                onChange={handleCheckboxChange(setCheckedeSportsTitles)}
                                checked={checkedeSportsTitles[eSportsTitles.id]}
                              ></PR.Checkbox>
                              <label
                                htmlFor={eSportsTitles.id}
                                className="ml-2 font-medium"
                              >
                                {eSportsTitles.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}

              {!doesReportScreen && (
                <PR.TabPanel
                  header="Tournament Levels"
                  disabled={disableTournamentLevel}
                >
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="tournamentSearch"
                            placeholder="Search"
                            className="search-box"
                            value={tournmentSearch}
                            onChange={(event) => handleSearch(event, setTournmentSearch, tournamentLevelsList, setFilteredTournamentLevels)}

                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!tournmentSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setTournmentSearch, tournamentLevelsList, setFilteredTournamentLevels)}
                              className="pi pi-times text-sm mr-2" />
                          }
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredTournamentLevels &&
                          filteredTournamentLevels?.map((tournamentLevel, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={tournamentLevel.id}
                                value={tournamentLevel.id}
                                onChange={handleCheckboxChange(setCheckedTournamentLevels)}
                                checked={checkedTournamentLevels[tournamentLevel.id]}
                              ></PR.Checkbox>
                              <label
                                htmlFor={tournamentLevel.id}
                                className="ml-2 font-medium"
                              >
                                {tournamentLevel.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}

              {!doesReportScreen && (
                <PR.TabPanel header="Alert Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {alertTypes?.map((alert, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={alert.id}
                              value={alert.id}
                              onChange={handleCheckboxChange(setCheckedAlerts)}
                              checked={checkedAlerts[alert.id]}
                            ></PR.Checkbox>
                            <label htmlFor={alert.id} className="ml-2 font-medium">
                              {alert.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel header="Response Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {responseTypes?.map((response, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={response.id}
                              value={response.id}
                              onChange={handleCheckboxChange(setCheckedResponses)}
                              checked={checkedResponses[response.id]}
                            ></PR.Checkbox>
                            <label htmlFor={response.id} className="ml-2 font-medium">
                              {response.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel header="Company" disabled={isCompanyTabDisabled}>
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="companySearch"
                            placeholder="Search"
                            className="search-box"
                            value={companySearch}
                            onChange={(event) => handleSearch(event, setCompanySearch, companies, setFilteredCompanies)}

                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!companySearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setCompanySearch, companies, setFilteredCompanies)}
                              className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredCompanies &&
                          filteredCompanies?.map((member, index) => {
                            return (
                              <div
                                className="flex align-items-center status-box"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={member.id}
                                  value={member.id}
                                  onChange={handleCheckboxChange(setCheckedCompanies)}
                                  checked={checkedCompanies[member.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={member.id}
                                  className="ml-2 font-medium"
                                >
                                  {member.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              <PR.TabPanel header="Created Date">
                <div className="filter-options-section">
                  <div className="card date-calendar flex justify-content-center">
                    <PR.Calendar
                      name="date"
                      value={date}
                      inputId="searchDate"
                      onChange={handleDateRange}
                      inline
                      selectionMode="range"
                      maxDate={new Date()}
                    />
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>
              {!doesReportScreen && (
                <PR.TabPanel header="Event Date">
                  <div className="filter-options-section">
                    <div className="card date-calendar flex justify-content-center">
                      <PR.Calendar
                        name="eventDate"
                        value={eventDate}
                        inputId="eventDate"
                        onChange={handleEventDateRange}
                        inline
                        selectionMode="range"
                        maxDate={new Date()}
                      />
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel
                  header="Tournament / League"
                  disabled={sportIds?.length === 0 || sportIds === undefined}
                >
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="leagueSearch"
                            placeholder="Search"
                            className="search-box"
                            value={leagueSearch}
                            onChange={(event) => handleSearch(event, setLeagueSearch, tournamentsList, setFilteredTournaments)}

                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!leagueSearch && <i style={{ cursor: 'pointer', color: "#6c757d" }}
                            onClick={() => handleSearch({ target: { value: "" } }, setLeagueSearch, tournamentsList, setFilteredTournaments)}
                            className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredTournaments &&
                          filteredTournaments?.map((tournament, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={tournament.id}
                                value={tournament.id}
                                onChange={handleCheckboxChange(setCheckedTournaments)}
                                checked={checkedTournaments[tournament.id]}
                              ></PR.Checkbox>
                              <label
                                htmlFor={tournament.id}
                                className="ml-2 font-medium"
                              >
                                {tournament.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel header="Gender">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {gender?.map((row, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={row.id}
                              value={row.id}
                              onChange={handleCheckboxChange(setCheckedGenders)}
                              checked={checkedGenders[row.id]}
                            ></PR.Checkbox>
                            <label htmlFor={row.id} className="ml-2 font-medium">
                              {row.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel header="Location">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="locationSearch"
                            placeholder="Search"
                            className="search-box"
                            value={locatinSearch}
                            onChange={(event) => handleSearch(event, setLocationSearch, locations, setFilteredLocations)}

                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!locatinSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setLocationSearch, locations, setFilteredLocations)}
                              className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredLocations &&
                          filteredLocations?.map((location, index) => {
                            return (
                              <div
                                className="flex align-items-center status-box"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={location.id}
                                  value={location.id}
                                  onChange={handleCheckboxChange(setCheckedLocations)}
                                  checked={checkedLocations[location.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={location.id}
                                  className="ml-2 font-medium"
                                >
                                  {location.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {!doesReportScreen && (
                <PR.TabPanel header="Customer Location">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="customerLocationSearch"
                            placeholder="Search"
                            className="search-box"
                            value={customerLocationSearch}
                            onChange={(event) => handleSearch(event, setCustomerLocationSearch, locations, setFilteredCustomerLocations)}

                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!customerLocationSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setCustomerLocationSearch, locations, setFilteredCustomerLocations)}
                              className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredCustomerLocations &&
                          filteredCustomerLocations?.map((location, index) => {
                            return (
                              <div
                                className="flex align-items-center status-box"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={`${location.id}customer`}
                                  value={location.id}
                                  onChange={handleCheckboxChange(setCheckedCustomerLocations)}
                                  checked={checkedCustomerLocations[location.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={`${location.id}customer`}
                                  className="ml-2 font-medium"
                                >
                                  {location.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {role === "analyst" && doesReportScreen ? (
                <PR.TabPanel header="Report Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {staticOptionData?.reportTypeFilter?.map(
                          (reportType, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={reportType.id}
                                value={reportType.id}
                                onChange={handleCheckboxChange(setCheckedReportTypes)}
                                checked={checkedReportTypes[reportType.id]}
                              />
                              <label
                                htmlFor={reportType.id}
                                className="ml-2 font-medium"
                              >
                                {reportType.name}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              ) : null}
              {role === "analyst" && doesReportScreen && (
                <PR.TabPanel header="Receiver">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-3 sticky-search">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="receiverSearch"
                            placeholder="Search"
                            className="search-box"
                            value={receiverSearch}
                            onChange={(event) => handleSearch(event, setReceiverSearch, receivers, setFilteredReceivers)}
                          />
                        </span>
                        <span className="search-clear-icon">
                          {!!receiverSearch &&
                            <i style={{ cursor: 'pointer', color: "#6c757d" }}
                              onClick={() => handleSearch({ target: { value: "" } }, setReceiverSearch, receivers, setFilteredReceivers)}
                              className="pi pi-times text-sm mr-2" />}
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {filteredReceivers && filteredReceivers?.map((receiver, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={receiver.id}
                              value={receiver.id}
                              onChange={handleCheckboxChange(setCheckedReceivers)}
                              checked={checkedReceivers[receiver.id]}
                            />
                            <label htmlFor={receiver.id} className="ml-2 font-medium">
                              {receiver.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}

              {!doesReportScreen && (
                <PR.TabPanel header="Others">
                  <div className="filter-options-section">
                    <div className="other-tab">
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Channel
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {channel?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={row.id}
                                  value={row.id}
                                  onChange={handleCheckboxChange(setCheckedChannel)}
                                  checked={checkedChannel[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={row.id}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Time of bet
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {timeOfBet?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={`time-of-bet-${row.id}`}
                                  value={row.id}
                                  onChange={handleCheckboxChange(setCheckedTimeOfBet)}
                                  checked={checkedTimeOfBet[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={`time-of-bet-${row.id}`}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            State of the bets
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {stateOfBet?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={row.id}
                                  value={row.id}
                                  onChange={handleCheckboxChange(setCheckedStateOfBet)}
                                  checked={checkedStateOfBet[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={row.id}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Max bets
                          </span>
                          <div className="card flex mt-1 gap-3">
                            {maxBet?.map((row, index) => (
                              <div className="flex flex-wrap gap-2" key={index}>
                                <div className="flex align-items-center">
                                  <PR.RadioButton
                                    inputId={row.id}
                                    name="bets"
                                    value={row.id}
                                    onChange={(e) => {
                                      maxBetRadioGroupHandler(e.value);
                                    }}
                                    checked={checkedMaxBet === row.id}
                                  />
                                  <label
                                    htmlFor={row.id}
                                    className="ml-2 font-medium"
                                  >
                                    {row.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            When is the alert being raised?
                          </span>
                          <div className="card flex mt-1 gap-3">
                            {alertsRaised?.map((row, index) => (
                              <div className="flex flex-wrap gap-2" key={index}>
                                <div className="flex align-items-center">
                                  <PR.Checkbox
                                    inputId={row.id}
                                    value={row.id}
                                    onChange={handleCheckboxChange(setCheckedAlertsRaised)}
                                    checked={checkedAlertsRaised[row.id]}
                                  ></PR.Checkbox>
                                  <label
                                    htmlFor={row.id}
                                    className="ml-2 font-medium"
                                  >
                                    {row.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
            </PR.TabView>
          </div>
          <div className="col-3 right-section">
            <p className="filters_p">Selected Filters:</p>
            {locationError && <p className="error-message mt-2" style={{color:'red'}}>{locationError}</p>}
            <FilterItem
              label="Keyword"
              value={keywords}
              handleRemove={() => handleRemoveItemFilter('keyword')}
            />
            <FiltersList
              items={getSelectedStatus()}
              label="Status"
              setter={setCheckedStatus}
            />
            <FiltersList
              items={getSelectedPlayers()}
              label="Player / Team"
              setter={setCheckedPlayers}

            />
            <FiltersList
              items={getSelectedSports()}
              label="Sports"
              setter={setCheckedSports}
              type='sport'
            />
            <FiltersList
              items={getSelectedeSportsTitles()}
              label="Esports Titles"
              setter={setCheckedeSportsTitles}
            />
            <FiltersList
              items={getSelectedTournamentLevels()}
              label="Tournament Levels"
              setter={setCheckedTournamentLevels}

            />
            <FiltersList
              items={getSelectedAlerts()}
              label="Alert Type"
              setter={setCheckedAlerts}
            />
            <FiltersList
              items={getSelectedResponses()}
              label="Response Type"
              setter={setCheckedResponses}
            />
            <FiltersList
              items={getSelectedCompanies()}
              label="Company"
              setter={setCheckedCompanies}
            />
            <FilterItem
              label="Created Date"
              value={dateRange}
              handleRemove={() => handleRemoveItemFilter('date')}
            />
            <FilterItem
              label="Event Date"
              value={eventDateRange}
              handleRemove={() => handleRemoveItemFilter('event')}
            />
            <FiltersList
              items={getSelectedTournaments()}
              label="Tournament / League"
              setter={setCheckedTournaments}
            />
            <FiltersList
              items={getSelectedGenders()}
              label="Gender"
              setter={setCheckedGenders}
            />
            <FiltersList
              items={getSelectedLocations()}
              label="Location"
              setter={setCheckedLocations}
            />
            <FiltersList
              items={getSelectedCustomerLocations()}
              label="Customer Location"
              setter={setCheckedCustomerLocations}
            />
            <FiltersList
              items={getSelectedReportTypes()}
              label="Report Type"
              setter={setCheckedReportTypes}
            />
            <FiltersList
              items={getSelectedReceivers()}
              label="Receiver"
              setter={setCheckedReceivers}
            />
            {othersTabFiltersSelected() && (
              <div className="filters-list mb-3">

                <p>Others:</p>
                <OtherFilterList
                  label="Channel"
                  items={getSelectedChannels()}
                  setter={setCheckedChannel}
                />
                <OtherFilterList
                  label="Time of bet"
                  items={getSelectedTimeOfBet()}
                  setter={setCheckedTimeOfBet}
                />
                <OtherFilterList
                  label="State of bets"
                  items={getSelectedStateOfBets()}
                  setter={setCheckedStateOfBet}
                />
                <OtherFilterList
                  label="Max bets"
                  items={getSelectedMaxBets()}
                  setter={setCheckedMaxBet}
                  type='max'
                />
                <OtherFilterList
                  label="Alert raised at"
                  items={getSelectedAlertsRaised()}
                  setter={setCheckedAlertsRaised}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
