import { useFormik } from "formik";
import * as PR from "../../../../prime-react/index";
import { createPayloadObject, FooterContent, formContent, validationSchema } from "./helper";
import { roles } from "../../../../shared/common";
import { useEffect, useRef, useState } from "react";
import { createAndUpateGroupnewAPI } from "../../../../services/api";
import { decodeHtml, fileUploadLimit, } from "../../../../utils/reuse";
import { useToast } from "../../../../context/ToastContext";

export const userMformError = 'user_management_form_error'

export const FormPopUp = ({ optionLoading, data, onClose, headers, onSubmitted, refetch, options }) => {
    const {showToast, clearError} = useToast()
    const isEditForm = data.id === 'edit';
    const { title, description, initialValues, successMsg } = formContent[data.id] ?? {};
    const [initialFormValues, setInitialFormValues] = useState(initialValues);
    const isLoading = useRef(isEditForm);
    const imageUploadRef = useRef(null);
    const [submitting, setSubmitting] = useState();
    const [initialSports, setInitialSports] = useState([]); // Track initial sports
    const [previousSports, setPreviousSports] = useState([]);

    useEffect(() => {
        if (isEditForm&&options) {
            const  {name, userId:id, role, description, sports:sportsIDs, managingGroup, locationID, status, tournamentLevels:tournamentLevelsIDs } = data;
            const { sports, locations, memberIds, tournamentLevels } = options;
            let sportValues
            if(sportsIDs?.includes(999)){
                sportValues = sports
            }else{
                sportValues = sports?.filter(e => sportsIDs?.includes(e.id));
            }
            setInitialSports(sportValues);
            const location = locations?.find(loc => loc.id === locationID) || null;
            const Managedby = memberIds?.find(member => member.id === managingGroup?.id) || null;
            const tournamentLevelValues = tournamentLevels?.filter(e => tournamentLevelsIDs?.includes(e.id));
            const statusValue = status?.charAt(0)?.toUpperCase() + status?.slice(1)?.toLowerCase();
            isLoading.current = false;
            setInitialFormValues(p => ({ ...p, name,id,role, description:decodeHtml(description), sports: sportValues, Managedby, location, status: statusValue, tournamentLevels:tournamentLevelValues }))
        }
    }, [data, isEditForm, options]);

    const onSubmit = async v =>{
        clearError(userMformError)
        setSubmitting(true);
        const formData = v;

        const payload = createPayloadObject(formData, options?.sports);
        const {result, error} = await createAndUpateGroupnewAPI(payload,headers);
        if(result === "SUCCESS"){
            await refetch()
            
            onSubmitted({severity: "success", summary: "Success", detail:successMsg } )

        }else
        showToast({ severity: error.severity, summary: error.summary||error.errorMsg, userMformError })

        setSubmitting(false);
    }


    const { handleSubmit, values, errors, touched, handleChange, setFieldValue, dirty, handleBlur } = useFormik({ initialValues: initialFormValues, enableReinitialize: true, validationSchema, onSubmit });



    const fileUploadHandler = event => {
        if (imageUploadRef.current) imageUploadRef.current.clear();
        setFieldValue('profilePhoto',event.files[0]);
    }


    useEffect(()=>{
        if(isEditForm){
             if((values.role==='member'|| values.role==='managedmember' || values.role === 'governingbody') && (!values.sports?.some(sport => sport.name?.toLowerCase() === 'tennis'))&&!!values.tournamentLevels.length){
                setFieldValue('tournamentLevels',[]);
            }
            if(values.role!=='managedmember'&& !!values.Managedby) {setFieldValue('Managedby',null);}
        }
    },[values, setFieldValue, isEditForm]);

    const handleStatusChange = (e) => {
        const newStatus = e.value;
        if (isEditForm) {
            if (newStatus === 'Inactive') {
                // Revert to initial sports when status becomes "Inactive"
                setPreviousSports(values.sports); // Save current sports before reverting
                setFieldValue('name', initialFormValues.name);
                setFieldValue('description', initialFormValues.description);
                setFieldValue('sports', initialSports); // Revert to initial sports
                setFieldValue('tournamentLevels', initialFormValues.tournamentLevels);
                setFieldValue('location', initialFormValues.location);
                setFieldValue('Managedby', initialFormValues.Managedby);
                setFieldValue('profilePhoto', initialFormValues.profilePhoto);
            } else if (newStatus === 'Active') {
                // When status is "Active", keep current sports
                setFieldValue('sports', previousSports.length > 0 ? previousSports : values.sports);
                setPreviousSports([]); // Clear saved previous sports after restoring (if needed)
            }
        }
        // Set the new status value
        setFieldValue('status', newStatus);
    };
    const displayTournmentLevels = (values)  => {
        if(values.role==='member' ||values.role==='managedmember' || values.role === 'governingbody'){
           return values.sports?.some(sport => sport.name?.toLowerCase() === 'tennis');
        }
        return false

    }

    return <PR.Dialog blockScroll="true" visible={data.visible} onHide={onClose} footer={<FooterContent userMformError={userMformError} submitDisable={dirty} submitting={submitting} onClose={onClose} id={data.id} />} className="logout-dialog response-dialog tags-dialog">

        {(isLoading.current||optionLoading) && <div className="spine-loader-bk"> <i className="pi pi-spin pi-spinner"></i> </div>}
        <form id="userForm" autoComplete="off" onSubmit={handleSubmit} >
            <div className="dialog-header">
                <div className="mb-5">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
            <div className="dialog-body-section">
                {roles?.length > 0 && <>
                    <label htmlFor="name">
                        Type of group <span className="important">*</span>
                    </label>
                    <div className="grid form-input mt-1 mb-3">
                    {roles?.map(role => (
                            isEditForm ? 
                            (values.role === role.id && 
                            <div className={`${isEditForm ? 'role-section pb-0' : 'col-12 md:col-6 pb-0'}`} key={role.id}>
                                <div className="box">
                                    <div className="flex align-items-center">
                                        <PR.RadioButton onChange={handleChange} inputId={role.id} name="role" value={role.id} checked={values.role === role.id}/>
                                        <label htmlFor={role.id} className="ml-2"> {role.name}</label>
                                    </div>
                                </div>
                            </div>) : 
                            (role.id !== "analyst" && <div className={`${isEditForm ? 'role-section' : 'col-12 md:col-6'}`} key={role.id}>
                                <div className="box">
                                    <div className="flex align-items-center">
                                        <PR.RadioButton onChange={(e) => {handleChange(e);}} inputId={role.id} name="role" value={role.id} checked={values.role === role.id}/>
                                        <label htmlFor={role.id} className="ml-2"> {role.name}</label>
                                    </div>
                                </div>
                            </div>)
                        ))}

                    </div>
                    {(errors.role && touched.role) && <div className="error-message">{errors.role}</div>}
                </>}

                <div className="flex flex-column form-input gap-2 mb-3">
                    <span className="label">Profile photo</span>
                    <div className="card profile-photo-upload flex align-items-center">
                        <PR.FileUpload auto mode="advanced" name="profilePhoto" accept="image/*" ref={imageUploadRef} chooseLabel="Choose Image" customUpload onSelect={fileUploadHandler} disabled={isEditForm && values.status === 'Inactive'}/>
                        <span className="ml-2">(Add file less than {fileUploadLimit.label})</span>
                    </div>
                    {values.profilePhoto && <div className="uploaded-file-container flex flex-column gap-2">
                        <div className="uploaded-files">
                            {values.profilePhoto.name} <i className="pi pi-trash" onClick={()=>setFieldValue("profilePhoto", null)} />
                        </div>
                    </div>}
                    {(errors.profilePhoto) && (
                        <div className="error-message">
                            {errors.profilePhoto}
                        </div>
                    )}
                </div>

                <div className="flex flex-column form-input gap-2 mb-3">
                    <label htmlFor="name">Group name <span className="important">*</span></label>
                    <PR.InputText id="name" type="text" name="name" placeholder="Enter group name" value={values.name} onChange={handleChange} 
                    onBlur={handleBlur} className="input-field" disabled={isEditForm && values.status === 'Inactive'}  autoComplete="off"/>
                    {(errors.name && touched.name) && <div className="error-message">{errors.name}</div>}
                </div>

                <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="description">Description <span className="important">*</span></label>
                    <div className="card">
                        <PR.InputTextarea id="description" name="description" placeholder="Enter group description.."  value={values.description} onChange={handleChange}  onBlur={handleBlur} className="description-textarea" disabled={isEditForm && values.status === 'Inactive'}/>
                    </div>
                    {(errors.description && touched.description) && <div className="error-message">{errors.description}</div>}
                </div>

                <div className="grid">
                    {(values.role==='member'|| values.role==='managedmember' || values.role === 'governingbody') &&
                        <div className="col-12 md:col-6">
                            <div className="flex flex-column form-input gap-2 mb-1">
                                    <label htmlFor="sports">Sports assigned <span className="important">*</span></label>
                                    <PR.MultiSelect inputId="sports" 
                                        name="sports" options={options?.sports ?? []} 
                                        value={values.sports} onChange={handleChange}  onBlur={handleBlur}
                                        optionLabel="name" placeholder="Select sports" 
                                        maxSelectedLabels={2} filter resetFilterOnHide className="multi-select-dropdown" disabled={isEditForm && values.status === 'Inactive'} showSelectAll
                                        pt={{
                                            checkboxContainer: {
                                                onClick: (e) => {
                                                    e.stopPropagation();
                                                    const parent = e.target?.parentNode;
                                                    if (parent && typeof parent.click === 'function') {
                                                        parent.click();
                                                    }
                                                },
                                            },
                                        }}
                                    />
                                    {(errors.sports && touched.sports) && <div className="error-message">{errors.sports}</div>}
                            </div>
                        </div>
                    }
                    {(values.role==='regulator')&&
                        <div className="col-12 md:col-6">
                            <div className="flex flex-column form-input gap-2">
                                <label htmlFor="location">Location <span className="important">*</span></label>
                                <PR.Dropdown inputId="location" filter resetFilterOnHide name="location" value={values.location} options={options?.locations ?? []} onChange={handleChange}  onBlur={handleBlur} optionLabel="name" placeholder="Select location" className="multi-select-dropdown" disabled={isEditForm && values.status === 'Inactive'}/>
                                {(errors.location && touched.location) && <div className="error-message">{errors.location}</div>}
                            </div>
                        </div>    
                    }
                    {displayTournmentLevels(values) && (
                        <div className="col-12 md:col-6">
                            <div className="flex flex-column form-input gap-2 mb-1">
                                <label htmlFor="tournamentLevels">Tournament Level <span className="important">*</span></label>
                                <PR.MultiSelect 
                                    filter 
                                    resetFilterOnHide
                                    inputId="tournamentLevels" 
                                    name="tournamentLevels" 
                                    value={values.tournamentLevels} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    options={options?.tournamentLevels??[]} 
                                    optionLabel="name" placeholder="Select tournament level" 
                                    className="multi-select-dropdown"
                                    disabled={isEditForm && values.status === 'Inactive'}
                                    showSelectAll
                                    pt={{
                                        checkboxContainer: {
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                const parent = e.target?.parentNode;
                                                if (parent && typeof parent.click === 'function') {
                                                    parent.click();
                                                }
                                            },
                                        },
                                    }}
                                />
                                {(errors.tournamentLevels && touched.tournamentLevels) && <div className="error-message">{errors.tournamentLevels}</div>}
                            </div>
                        </div>
                        
                    )}
                    {(values.role !== "analyst") &&
                    <div className="col-12 md:col-6">
                        <div className="flex flex-column form-input gap-2 mb-1">
                            <label htmlFor="status">Status <span className="important">*</span></label>
                            <PR.Dropdown inputId="status" name="status" value={values.status} options={[{ value: "Active" }, { value: "Inactive" }]} onChange={handleStatusChange} optionLabel="value" placeholder="Select status" className="multi-select-dropdown" />
                            {(errors.status && touched.status) && <div className="error-message">{errors.status}</div>}
                        </div>
                    </div>}
                        
                    {(values.role==='managedmember')&&
                        <div className="col-12 md:col-6">
                            <div className="flex flex-column form-input gap-2">
                                <label htmlFor="Managedby">Managed By <span className="important">*</span></label>
                                <PR.Dropdown filter inputId="Managedby" name="Managedby" value={values.Managedby} options={options?.memberIds} onChange={handleChange}  onBlur={handleBlur} optionLabel="name" placeholder="Select Managedby" className="multi-select-dropdown" disabled={isEditForm && values.status === 'Inactive'}/>
                                {(errors.Managedby && touched.Managedby) && <div className="error-message">{errors.Managedby}</div>}
                            </div>
                        </div>
                    }
                </div>

                
            </div>
        </form>
    </PR.Dialog>
}