import React, { useEffect, useMemo, useState } from "react";
import "./News.scss";
import Layout from "../../layouts/Layout";
import * as PR from "../../prime-react/index";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getFileDetails, getNewsDetails } from "../../services/api";
import { DateFormat, dompurifyText, formatTime, getNewsTypeLabel } from "../../utils/reuse";
import {
  news1,
  news2,
  news3,
  news4,
  news5,
  news6,
  news7,
  newView1,
  newView2,
  newView3,
  newView4,
  newView5,
  newView6,
} from "../../assets/images/index";
import { useDispatch, useSelector } from "react-redux";
import TopCategories from "../../shared/TopCategories/TopCategories";
import dbData from "../../assets/data/db.json";
import { useToast } from "../../context/ToastContext";

const NewsDetails = () => {
  const { showToast } = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const dispatch = useDispatch();
  const [newsDetail, setNewsDetail] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const navigate = useNavigate();
  const sessionData = useSelector(state => state.auth.sessionData);
  const headers = useMemo(() => {
    return { sessionid: sessionData.sessionId };
  }, [sessionData.sessionId]);
  const imageMap = {
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
  };

  useEffect(() => {
    setMostViewedData(dbData.mostViewed);
  }, [id]);

  useEffect(() => {
    if (id) {
      getNewsDetails(id, headers, dispatch, (response) => {
        setLoading(false);
        if (response.result === 'SUCCESS') {
          if (response.data?.length) {
            const newsData = response.data?.map(news => ({
              ...news,
              fileAttachments: news?.fileAttachments ? JSON.parse(news?.fileAttachments) : []
            }));
            setNewsDetail(newsData);
            const imageAttachment = newsData[0].fileAttachments?.find(file => {
              return file?.attachmentType === "coverImage"
            });
            if (imageAttachment) {
              getFileDetails(imageAttachment.key, headers, dispatch, (response) => {
                if (response.result === 'SUCCESS') {
                  const fileData = response.data;
                  const imageUrl = URL.createObjectURL(fileData);
                  setImageSrc(imageUrl);
                } else {
                  const error = response?.error;
                  showToast(error.severity, error?.errorMsg || error?.summary);
                }
              });
            }
          } else {
            navigate('/news');
          }
        } else {
          navigate('/news');
        }
      });
    }
  }, [id, headers, dispatch, showToast, navigate]);

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
    navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
  };

  const handleFileClick = (key, fileName) => {
    getFileDetails(key, headers, dispatch, response => {
      if (response.result === 'SUCCESS') {
        const fileData = response.data;
        const url = URL.createObjectURL(fileData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        const error = response?.error;
        showToast(error.severity, error?.errorMsg || error?.summary);
      }
    });
  };

  if (loading) {
    return (
      <div className="loading">
        <i className="pi pi-spin pi-spinner"></i>
      </div>
    );
  }

  const renderLeftHeader = (news,newsType) => {
    if(newsType === "sportingsanctions"){
      return (
        <ul className='chips-ul gap-3'>
          {news?.keywords && news?.keywords?.split(',')?.map(keyword => (
              <li key={keyword}>{keyword}</li>
          ))}
      </ul>
      )
    }else{
      return (
        <>
          <li>
            <i className="pi pi-user"></i>
            <span className="author">{news?.createdByUser}</span>
          </li>
          <li>
            <i className="pi pi-calendar"></i>
            <span>{DateFormat(news?.createdAt)} {formatTime(news?.createdAt)}</span>
          </li>
        </>
      )
    }
  }

  const renderRightHeader = (news,newsType) => {
    if(newsType === "sportingsanctions"){
      return (
        <li>
          <i className="pi pi-calendar"></i>{" "}
          <span>{DateFormat(news?.createdAt) } {formatTime(news?.createdAt)}</span>
      </li>
        )
    }else{
      return (
        <li>
          <i className="pi pi-book"></i>
          <span>{news?.categoryName ?? getNewsTypeLabel(newsType)}</span>
        </li>
      )
    }
  }

  return (
    <Layout>
      <div className="backbtn-outer">
        <Link to={`/news`} className="flex align-items-center back-btn">
          <i className="pi pi-arrow-left" />Back to News
        </Link>
      </div>
      <section className="news-tab news-details-section general-news-section">
        {newsDetail?.map((detail) => {
          const { title, description, fileAttachments, newsType } = detail;
          return (
            <div key={detail.id} className="grid align-items-center justify-content-start">
              <div className="col-9">
                <h1>{title}</h1>
              </div>
              <div className="grid w-full">
                <div className="col-12">
                  <div className="new-list-section">
                    <div className="list-wrapper">
                      <div className="news-list">
                        <div className="card">
                          <div className="col-12 p-0">
                            <div className="flex align-items-center justify-content-between">
                              <ul className="flex list-none align-items-center gap-4">
                                {renderLeftHeader(detail,newsType)}
                              </ul>
                              <ul className="flex list-none align-items-center gap-4">
                                {renderRightHeader(detail,newsType)}
                              </ul>
                            </div>
                            {imageSrc && (
                              <PR.Image src={imageSrc} alt={title} className="news-img mb-4" />
                            )}
                          </div>
                          <div className="col-12 p-0">
                            <div className="content">
                              <div dangerouslySetInnerHTML={{ __html: dompurifyText(description) }} />
                            </div>
                            {fileAttachments?.length > 0 && (
                              <div className="attachments">
                               
                                {fileAttachments?.map(file => {
                                  if(file?.attachmentType !== "coverImage"){
                                    <span className="heading-span">Attached Files:</span>
                                    return (
                                      <PR.Button
                                        key={file.key}
                                        icon="pi pi-download"
                                        label={file.name}
                                        className="download-button"
                                        onClick={() => handleFileClick(file.key, file.name)}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3" style={{display:'none'}}>
                  <div className="new-view-section">
                    <div className="header-section">
                      <h3>
                        <i className="pi pi-star"></i> Related news
                      </h3>
                    </div>
                    <div className="viewed-list">
                      {mostViewedData?.map((item) => (
                        <div key={item.id} className="flex align-items-center gap-3 mb-3">
                          <div className="news-img">
                            <PR.Image src={imageMap[item.image]} alt={item.title} />
                          </div>
                          <div className="news-heading">
                            <h3>{item.title}</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <TopCategories heading={"Other Categories"} handleCategoryClick={handleCategoryClick} />
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </Layout>
  );
};

export default NewsDetails;
