import { createSlice } from "@reduxjs/toolkit";

const filterOptionsSlice = createSlice({
    name: "filterData",
    initialState: {
        companyFilterOptions: [],
        sportFilterOptions: [],
        locationFilterOptions: [],
        receiverFilterOptions: [],
        playerOrTermNameOptions: [],
        eSportTitleOptions: [],
        tournmentLevelOptions: [],
        alertsActiveIndex: null,
        sportActiveIndex: null,
    },
    reducers: {
        storeFilterOptions(state, action) {
            const { key, options } = action.payload;
            state[key] = options;
        },
        clearFilterOptions(state, action) {
            state.companyFilterOptions = [];
            state.sportFilterOptions = [];
            state.locationFilterOptions = [];
            state.receiverFilterOptions = [];
            state.playerOrTermNameOptions = [];
            state.eSportTitleOptions = [];
            state.tournmentLevelOptions = [];
            state.alertsActiveIndex = null;
            state.sportActiveIndex = null;
        },
    },
});

export const filterOptionsActions = filterOptionsSlice.actions;
export default filterOptionsSlice.reducer;
