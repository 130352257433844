const customHeaders = [
    { id: 'legacyID', title: 'Alert ID' },
    { id: 'alertName', title: 'Alert Name' },
    { id: 'alertType', title: 'Alert Type' },
    { id: 'alertStatus', title: 'Alert Status' },
    { id: 'timeOfBet', title: 'Time of Bet' },
    { id: 'stateOfBet', title: 'State of Bet' },
    { id: 'maxBets', title: 'Max Bets' },
    { id: 'channel', title: 'Channel' },
    { id: 'timeOfAlert', title: 'Time of Alert' },
    { id: 'description', title: 'Description' },
    { id: 'caseStatus', title: 'Case Status' },
    { id: 'createdAt', title: 'Created Date' },
    { id: 'createdByGroupName', title: 'Created By Group Name' },
    { id: 'customerInfo', title: 'Customer Info' },
    { id: 'tags', title: 'Tags' },
    { id: 'sportName', title: 'Sport Name' },
    { id: 'eventsCount', title: 'No.of Events' },
    { id: 'events', title: 'Events' }
];

const customEventHeaders = {
    eventName: 'Event Name',
    sportName: 'Sport Name',
    esportsTitleName: 'Esports Title',
    tournamentName: 'Tournament Name',
    tournamentLevelName: 'Tournament Level',
    dateTime: 'Event Date & Time',
    locationName: 'Location Name',
    gender: 'Gender',
    turnover: 'Turnover'
};

const customCustomerInfoHeaders = {
    customerType: 'Customer Type',
    customerDetails: 'Customer Details',
};

const escapeCSVValue = (value) => {
    if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        if (value.includes(',') || value.includes('\n') || value.includes('"')) {
            return `"${value}"`;
        }
    }
    return value;
};

const formatEventDetails = (events) => {
    return events.map(event => {
        return Object.entries(customEventHeaders).map(([key, customKeyName]) => {
            const value = event[key] || '';
            return value ? `${customKeyName}: ${escapeCSVValue(value)}` : '';
        }).filter(val => val !== '').join('\n');
    }).join('\n\n');
};

const formatCustomerInfo = (customerInfo) => {
    let formattedDetails = '';
    if (customerInfo) {
        try {
            const parsedInfo = typeof customerInfo === 'string' ? JSON.parse(customerInfo) : customerInfo;

            formattedDetails = Object.entries(parsedInfo).map(([key, value]) => {
                const formattedKey = customCustomerInfoHeaders[key] || key;
                if (Array.isArray(value)) {
                    if (key === 'customerDetails' && (!value || value.length === 0)) {
                        return '';
                    }
                    return key === 'customerDetails'
                        ? `${formattedKey}:\n${value.map(item => Object.entries(item).map(([subKey, subValue]) => `${subKey}: ${subValue}`).join(', ')).join('\n')}`
                        : `${formattedKey}: ${value.join(', ')}`;
                } else if (value !== null) {
                    return `${formattedKey}: ${escapeCSVValue(value)}`;
                }
                return '';
            }).filter(Boolean).join('\n');
        } catch (error) {
            console.error('Error parsing customerInfo:', error);
            formattedDetails = customerInfo; // Keep original if parsing fails
        }
    }
    return formattedDetails;
};

const convertToCSV = (data, headers) => {
    const headerRow = headers.map(header => header.title).join(',') + '\n';

    const csvRows = data.map(row => {
        const { events, customerInfo, ...otherFields } = row; // Destructure row for clarity
        const eventDetails = formatEventDetails(events);
        const customerInfoDetails = formatCustomerInfo(customerInfo);
        const eventsCount = events ? events.length : 0;

        return headers.map(header => {
            const value = header.id === 'events' ? eventDetails :
                          header.id === 'customerInfo' ? customerInfoDetails :
                          header.id === 'eventsCount' ? eventsCount :
                          otherFields[header.id] || '';
            return escapeCSVValue(value);
        }).join(',');
    });

    return headerRow + csvRows.join('\n');
};

export const handleExportCSV = (alertData) => {
    const csvData = convertToCSV(alertData, customHeaders);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Alerts.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
