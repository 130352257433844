import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { setSearchOptions, getAlertsCount, exportAlerts } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { RAW_FILTER_DATA } from '../../../shared/common';
import { formatDate, formatDateRange, pagecount } from '../../../utils/reuse';
import AlertsGrid from '../../../shared/Alerts/AlertsGrid';
import Layout from '../../../layouts/Layout';
import { authActions } from '../../../store/auth';
import { useLocation } from 'react-router-dom';
import { setFilter } from '../../../store/filter.slice';
import { useToast } from '../../../context/ToastContext';
import { handleExportCSV } from '../../../utils/useExportCSV';
import { useCSVExport } from '../../../utils/exportAlerts';

const AlertsTemplate = ({alertStatus, title, openAlertCount, evaluatingAlertCount, suspiciousAlertCount}) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { showToast } = useToast();
    const queryParams = new URLSearchParams(search);
    const tagId = queryParams.get("tagId");
    const tagName = queryParams.get("tagName");
    const alertsCount = queryParams.get("alertCount");
    const pathname = window.location.pathname;
    const sortOrder = useRef('');
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const totalCount = useRef();
    const pageCount = pagecount
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);

    const [type, setType] = useState(null)
    const [processedOptions, setProcessedOptions] = useState({})
    const [loading, setLoading] = useState(true);
    const [filteredAlerts, setFilteredAlerts] = useState([])
    const [count, setCount] = useState(0)

    const searchFilterHandler = useCallback((type, data) => {
        setLoading(true);
        const obj = {
            offset: offset,
            limit: limit,
            type: type,
            sort: sortOrder.current??''
        };
        setSearchOptions(obj, data, headers, dispatch, response => {
            if(response.result === 'SUCCESS'){
                const responseData = response.data;
                totalCount.current = responseData?.totalCount
                const alertsList = (responseData?.alerts?.length > 0) ? responseData?.alerts.slice(0, limit) : [];
                const result = alertsList;

                if (result.length > 0) {
                    setLast(responseData?.alerts?.length <= pageCount);
                    setPaging(true)
                    setFilteredAlerts(result);
                }else if (Object.values(data).some(value => value !== undefined)){ 
                    setFilteredAlerts([]);
                    setLast(true);
                    setPaging(false);
                    showToast("warn", "No records found for selected filters");
                }else {
                    setFilteredAlerts([]);
                    setLast(true);
                    setPaging(false);
                    showToast("warn", "No records found");
                }
            }else {
                setLast(true);
                setPaging(false);
                setFilteredAlerts([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        })
        // eslint-disable-next-line
    },[dispatch, headers, limit, offset, pageCount])

    const handlePageChange = o =>{
        setPaging(false);
        setPage(o.page+1);
        setOffset(o.first);
        setLimit(o.rows);
    }

    useEffect(() =>{
        if(!(type === null)){
            searchFilterHandler(type,processedOptions)
        }
    // eslint-disable-next-line
    },[offset,limit, type, processedOptions])
    const sortOrderHanlder =  () =>{
        setPage(1);
        setOffset(0)
        sortOrder.current = sortOrder.current==='asc'?'':'asc';
        searchFilterHandler(type,processedOptions)
    }
    const processedAlerts = filteredAlerts?.map(row => ({
        ...row,
        createdOnFormatted: formatDate(row.createdAt),
        createdOnRaw: new Date(row.createdAt)
    }));

    useEffect(() => {
        dispatch(authActions.updateAlertsList(processedAlerts))
    // eslint-disable-next-line
    },[processedAlerts])

    const formatMaxBets = (data) => {
        return RAW_FILTER_DATA.confirmOptions.filter(item => item.name ===data).map(item => item.id)[0]
    }
    const alertFilterState = useSelector(state => state.alertFilter);
    const [selectedFilters, setSelectedFilters] = useState(pathname === '/home' ? alertFilterState : {});

    const getSelectedFilters = (data) => {
        setSelectedFilters(data)
        dispatch(setFilter(data))
    }

    const getAlertStatuses = (data) => {

        const alertStatus = (data?.alertStatus?.length !== 0 ) ? data?.alertStatus?.map(item => item.id) : undefined;

        return alertStatus
    }

    const setAlertsCount = () => {
        const obj ={
            //oa:open alerts , ea:evaluating alerts, sa:suspicious alerts
            type:"oa,ea,sa"
        }
        getAlertsCount(obj, headers, dispatch, (response) => {
            if (response.result === "SUCCESS"){
                const result = response?.data
                if (pathname === "/home") {
                    openAlertCount(result?.openAlerts || 0)
                    evaluatingAlertCount(result?.evaluatingAlerts || 0)
                    suspiciousAlertCount(result?.suspiciousAlerts || 0)
                }else {
                    if(pathname === "/open-alerts"){
                        setCount(result?.openAlerts)
                    }else if (pathname === "/evaluating-alerts") {
                        setCount(result?.evaluatingAlerts)
                    }else if (pathname === "/suspicious-alerts") {
                        setCount(result?.suspiciousAlerts)
                    }
                }

            }
            //  else {
            //     const error = response.error;
            //     showToast(error.severity, error?.errorMsg || error?.summary);
            // }
        })
    }
    const tabChangeHandler = (index=null, data=selectedFilters) => {

        const caseStatus = (data?.caseStatus?.length !== 0 ) ? data?.caseStatus?.map(item => item.id) : undefined;
        const tags = (data?.tags?.length !== 0 ) ? data?.tags?.map(item => item.id): undefined;
        const sports = (data?.sports?.length !== 0 ) ? data?.sports?.map(item => item.id) : undefined;
        const eSportsTitles = (data?.eSportsTitles?.length !== 0 ) ? data?.eSportsTitles?.map(item => item.id) : undefined;
        const tournamentLevels = (data?.tournamentLevels?.length !== 0 ) ? data?.tournamentLevels?.map(item => item.id) : undefined;
        const alertType = (data?.alertType?.length !== 0 ) ? data?.alertType?.map(item => item.id) : undefined;
        const responseType = (data?.responseType?.length !== 0 ) ? data?.responseType?.map(item => item.id) : undefined;
        const groups = (data?.company?.length !== 0 ) ? data?.company?.map(item => item.id) : undefined;

        const startDate = data?.date?.date ? formatDateRange(data?.date?.date[0]): undefined;
        const endDate = data?.date?.date ? formatDateRange(data?.date?.date[1]) : undefined;

        const eventStartDate = data?.eventDate?.date ? formatDateRange(data?.eventDate?.date[0]): undefined;
        const eventEndDate = data?.eventDate?.date ? formatDateRange(data?.eventDate?.date[1]) : undefined;

        const tournaments = (data?.tournament?.length !== 0 ) ? data?.tournament?.map(item => item.id) : undefined;
        const locations = (data?.location?.length !== 0 ) ? data?.location?.map(item => item.id) : undefined;
        const customerLocations = (data?.customerLocations?.length !== 0 ) ? data?.customerLocations?.map(item => item.name) : undefined;
        const gender = (data?.gender?.length !== 0 ) ? data?.gender?.map(item => item.id) : undefined;

        const channel = (data?.channel?.length !== 0 ) ? data?.channel?.map(item => item.id) : undefined;
        const timeOfBet = (data?.timeOfBet?.length !== 0 ) ? data?.timeOfBet?.map(item => item.id) : undefined;
        const stateOfBet = (data?.stateOfBet?.length !== 0 ) ? data?.stateOfBet?.map(item => item.id) : undefined;
        const timeOfAlert = (data?.timeOfAlert?.length !== 0 ) ? data?.timeOfAlert?.map(item => item.id) : undefined;

        const maxBets = (data?.maxBets?.length !== 0 ) ? data?.maxBets?.map(item => item.name) : undefined;
        const maxBetsBoolean = maxBets?.length>0 ? formatMaxBets(maxBets[0]): undefined;

        const keyword = data.keyword

        const processedOptions = {
            alertStatus:(alertStatus==="")?getAlertStatuses(data):[alertStatus],
            caseStatus,
            tags:(pathname === "/alerts")?[Number(tagId)]:tags,
            sports,
            eSportsTitles,
            tournamentLevels,
            alertType,
            responseType,
            groups,
            startDate,
            endDate,
            eventStartDate,
            eventEndDate,
            tournaments,
            locations,
            customerLocations,
            gender,
            channel,
            timeOfBet,
            stateOfBet,
            timeOfAlert,
            maxBets:maxBetsBoolean,
            keyword
        }
        setProcessedOptions(processedOptions)
        setOffset(0)
        setPage(1)
    }

    const { exportLoading, exportCSV } = useCSVExport(
        processedOptions, 
        sortOrder, 
        headers, 
        dispatch, 
        exportAlerts, 
        handleExportCSV
    );

    const Alerts = () => {
        return (
            <>
                <AlertsGrid
                    offset={offset}
                    limit={limit}
                    sortValue={sortOrder.current}
                    totalCount={totalCount.current}
                    sortOrder={sortOrderHanlder}
                    tabChangeHandler={tabChangeHandler}
                    activeIndex={null}
                    getSelectedFilters={getSelectedFilters}
                    selectedFilters={selectedFilters}
                    paging={paging}
                    page={page}
                    last ={last}
                    handlePageChange={handlePageChange}
                    exportCSV={exportCSV}
                    exportLoading={exportLoading}
                    loading={loading}
                />
                {(loading || exportLoading) &&
                    <div className="loading">
                        <i className="pi pi-spin pi-spinner"></i>
                    </div>
                }
            </>
        )
    }

    useEffect(() => {
        if(pathname === "/my-alerts"){
            setType('myalerts') 
            tabChangeHandler()
            return           
        }

        if(pathname === "/suspicious-alerts"){
            setType('suspicious')
            tabChangeHandler()
            return
        }
        if(pathname === "/home"){
            setAlertsCount()
        }
        tabChangeHandler()
        setType('')
        // eslint-disable-next-line
    },[])

    const getBackBtn = () =>{
        if(pathname === "/alerts"){
            return "tags"
        }else{
            return "home"
        }
    }
    return (
        <ConditionalLayout isHomeScreen={pathname === "/home"} backBtn={getBackBtn()} backBtnLabel={pathname === "/alerts" ? "Tags" : "Home"}>
            <section className="all-alerts-section mt-5">
                <div className="m-0">
                    {(pathname === "/alerts") && <h1>{tagName} {alertsCount ? `: ${alertsCount} ${alertsCount > 1 ? 'alerts' : 'alert'}`:''}</h1>}
                    {title && <h1>{title} {count ? `(${count})`:''}</h1>}
                </div>
                <div className="alerts-tab">
                    <Alerts/>
                </div>
            </section>
        </ConditionalLayout>
    );
};

export default AlertsTemplate;

const ConditionalLayout = ({children, isHomeScreen, backBtn, backBtnLabel}) => {
    return (
        <>
            {isHomeScreen
                ?(<>{children}</>)
                : (<Layout backBtn={backBtn} backBtnLabel={backBtnLabel}>{children}</Layout>)
            }
        </>
    )
}
