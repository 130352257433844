import { getDisplayData, renderItems } from "../common";
import * as PR from "../../prime-react/index";
import { dateTimeFormatComma, formatNumber } from "../../utils/reuse";
import { useToast } from "../../context/ToastContext";
import { getFileDetails } from "../../services/api";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AlertDetails.scss";

const EventDetails = ({ data, isResponse, nonIbiaAttachments, alertType }) => {
    const { showToast } = useToast();
    const sessionData = useSelector((state) => state.auth.sessionData);
    const dispatch = useDispatch();

    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const fetchFileDetails = (key, fileName) => {
        const convertBlobToPDFAndDownload = async (pdfData, fileName) => {
            try {
                const url = URL.createObjectURL(pdfData);

                const link = document.createElement("a"); //download link
                link.href = url;
                link.setAttribute("download", fileName || "file.pdf");

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        };
        getFileDetails(key, headers, dispatch, (response) => {
            if (response.result === "SUCCESS") {
                const fileData = response.data;
                convertBlobToPDFAndDownload(fileData, fileName);
            } else {
                const error = response?.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        });
    };
    const renderAlertDetailsLeft = (data, isResponse) => {
        return (
            <>
                {data?.responseID && isResponse && (
                    <ul className="details-ul">
                        <li>Response ID</li>
                        <li>{data?.responseID}</li>
                    </ul>
                )}
                {data?.legacyID && (
                    <ul className="details-ul">
                        <li>Alert ID</li>
                        <li>{data?.legacyID}</li>
                    </ul>
                )}
                <ul className="details-ul">
                    <li>Type</li>
                    <li>{getDisplayData(alertType, "alertTypeFilter")}</li>
                </ul>
                {data?.tags && (
                    <ul className="details-ul">
                        <li>Tags</li>
                        <li>{renderItems(data?.tags, "tags")}</li>
                    </ul>
                )}
                {data?.timeOfAlert && (
                    <ul className="details-ul">
                        <li>Alert raised</li>
                        <li>{getDisplayData(data?.timeOfAlert, "alertsRaised")}</li>
                    </ul>
                )}
                <ul className="details-ul">
                    <li>Channel</li>
                    <li>{renderItems(data?.channel, "channels")}</li>
                </ul>
            </>
        );
    };

    const renderAlertDetailsRight = (data, isResponse) => {
        const files = isResponse ? data.fileAttachment : nonIbiaAttachments;
        const getFileName = (fullFileName) => {
            if(fullFileName){
                const parts = fullFileName.split('.');
                const extension = parts.pop();
                const fileName = parts.join('.');
                const name = fileName.length > 10 ? `${fileName.substring(0, 10)}` :fileName;
                return `${name}.${extension}`
            }
            return '';
        }
        return (
            <>
                <ul className="details-ul">
                    <li>Time of bet</li>
                    <li>{renderItems(data?.timeOfBet, "timeOfBet")}</li>
                </ul>
                <ul className="details-ul">
                    <li>State of bet</li>
                    <li>{renderItems(data?.stateOfBet, "stateOfBetsFilter")}</li>
                </ul>

                <ul className="details-ul">
                    <li>Max bets</li>
                    <li>{data?.maxBets ? "Yes" : "No"}</li>
                </ul>
                <ul className="details-ul">
                    <li>Files</li>
                    <ul className="p-0 flex gap-2 files-download-ul">
                        {files?.map((file) => (
                            <li key={file.key}>
                                <PR.Button
                                    label={getFileName(file?.name)}
                                    title={file?.name}
                                    icon="pi pi-download"
                                    className="file-download"
                                    onClick={() => fetchFileDetails(file.key, file?.name)}
                                />
                            </li>
                        ))}
                    </ul>
                </ul>
            </>
        );
    };
    const getTotalCustomers = (customerInfo) => {
        const customerCount = customerInfo?.customerDetails?.length ? customerInfo?.customerDetails?.reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
        ) : "-";
        return customerCount !== "-" ? formatNumber(customerCount) : customerCount;
    };

    const formatEvent = (events) => {
        const dataList = events?.map((event, index) => {
            let data = { ...event };
            data.event = event.eventName;
            data.esportsTitleName = event?.esportsTitleName ?  event.esportsTitleName : '-';
            data.tournamentLevelName = event?.tournamentLevelName ?  event.tournamentLevelName : '-';
            data.turnover = event.turnover ? `${event.turnover}%` : `-`;
            data.country = event.locationName;
            data.date = dateTimeFormatComma(event.dateTime);
            return data;
        });
        return dataList;
    };
    const eventGridDisplay = (data, isResponse) => {
        return (
            <>
                <ul className="details-ul">
                    <li>No. of events</li>
                    <li>{formatNumber(data?.events?.length)}</li>
                </ul>
                <div className="card">
                    <PR.DataTable
                        value={formatEvent(data?.events)}
                        stripedRows
                        className={`${isResponse ? 'alert-response-grid' : 'alert-main-grid'} data-table` }
                    >
                        <PR.Column
                            field="event"
                            header="Event Name"
                            style={{ width: isResponse ? '250px' : '300px' }}
                        ></PR.Column>
                        <PR.Column
                            field="sportName"
                            header="Sport"
                            style={{ width: isResponse ? '120px' : '120px' }}
                        ></PR.Column>
                        {data?.events?.some(event => event.esportsTitleName) &&
                            <PR.Column
                                field="esportsTitleName"
                                header="Esports Title"
                                style={{ width: isResponse ? '140px' : '150px' }}
                            ></PR.Column>
                        }
                        <PR.Column
                            field="tournamentName"
                            header="League/Tournament"
                            style={{ width: isResponse ? '200px' : '250px' }}
                        ></PR.Column>
                        <PR.Column
                            field="tournamentLevelName"
                            header="Tournament Level"
                            style={{ width: isResponse ? '180px' : '190px' }}
                        ></PR.Column>
                        <PR.Column
                            field="locationName"
                            header="Location"
                            style={{ width: isResponse ? '100px' : '150px' }}
                        ></PR.Column>
                        <PR.Column
                            field="date"
                            header="Date & Time"
                            style={{ width: isResponse ? '190px' : '162px' }}
                        ></PR.Column>
                        <PR.Column
                            body={(data) => getDisplayData(data?.gender, "gender")}
                            header="Gender"
                            style={{ width: isResponse ? '50px' : '40px' }}
                        ></PR.Column>
                        <PR.Column
                            field="turnover"
                            header="Turnover"
                            style={{ width: isResponse ? '50px' : '40px' }}
                        ></PR.Column>
                    </PR.DataTable>
                </div>
                <div className="customerGrid">
                    <ul className="details-ul">
                        <li>Total customers</li>
                        <li>{getTotalCustomers(data.customerInfo)}</li>
                    </ul>
                    <span className="border-right"></span>
                    <ul className="details-ul">
                        <li>Type of customers</li>
                        <li className="capitalize">
                            {data.customerInfo
                                ? renderItems(
                                    data?.customerInfo?.customerType,
                                    "customerTypes"
                                )
                                : "-"}
                        </li>
                    </ul>
                </div>
                <div className="grid">
                    {data?.customerInfo?.customerDetails?.map(
                        (item, index) => (
                            <div className={`${isResponse ? 'col-3': 'col-2'}`} key={index}>
                                <div className="box">
                                    <p>
                                        Country <br /> <span>{item?.country}</span>
                                    </p>
                                    {item?.state && (
                                        <p>
                                            State <br /> <span>{item?.state}</span>
                                        </p>
                                    )}
                                    <p>
                                        No. of customers <br />{" "}
                                        <span>{formatNumber(item?.count)}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </>
        )

    }

    return (
        <>
            <div className="col-12 grid justify-content-between mt-4">
                <div className="col-5 border-right">
                    {renderAlertDetailsLeft(data, isResponse)}
                </div>
                <div className="col-6">
                    {renderAlertDetailsRight(data, isResponse)}
                </div>
            </div>
            <div className="col-12">
                {eventGridDisplay(data, isResponse)}
            </div>
        </>
    );
};
export default EventDetails;
